import { useFormik } from "formik";
import React, { useState, useEffect, Fragment } from "react";
import {
    Button,
    Card,
    Col,
    Row,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import TableIcons from "../../../components/TableIcons";
import Swal from "sweetalert2";
import { AiFillPlusCircle } from "react-icons/ai";
import { useStateContext } from "../../../context/ContextProvider";
import DataTable from "react-data-table-component";
import { HiMiniViewfinderCircle } from "react-icons/hi2";
import itemChallanDetailService from "../../../services/item-challan-detail.service";
import AddItemChallanDetailOut from "./add-item-challan-out-detail";
import itemChallanService from "../../../services/item-challan.service";
import { usePermissionContext } from "../../../context/PremissionContext";
import { TiTick } from "react-icons/ti";
import { ImCross } from "react-icons/im";

const ItemChallanDetailOut = ({ id, shift }) => {
    const navigate = useNavigate();

    const { permissions } = usePermissionContext();

    const createPermission = permissions.some(permission => permission.name === 'Create Item Challan Detail');
    const updatePermission = permissions.some(permission => permission.name === 'Update Item Challan Detail');
    const viewPermission = permissions.some(permission => permission.name === 'View Item Challan Detail');
    const deletePermission = permissions.some(permission => permission.name === 'Delete Item Challan Detail');
    const restorePermission = permissions.some(permission => permission.name === 'Restore Item Challan Detail');
    const purgePermission = permissions.some(permission => permission.name === 'Purge Item Challan Detail');

    const { type } = useParams();
    const {
        dtColorMode,
        setIsLoading,
        setPageTitle,
        showToast,
        setRefereshData,
        refereshData,
    } = useStateContext();

    /**
     * The function `displayError` takes an error object as input and displays each error message as a
     * toast notification.
     * @param error - The `error` parameter is an object that contains error messages for different
     * columns. Each key in the `error` object represents a column, and the corresponding value is an
     * array of error messages for that column.
     */
    const displayError = (error) => {
        let errArr = [];
        errArr = Object.keys(error).map((key) => {
            return error[key].join(",");
        });
        errArr.map((column) => showToast(column, "error"));
    };
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [totalRows, setTotalRows] = useState(0);
    // Datatable columns

    const getDataList = (page, rowsPerPage) => {
        setIsLoading(true);
        let url = `in=challan_id,${id}`;
        itemChallanDetailService
            .search(url)
            .then((response) => {
                if (response.data && response.data.data) {
                    setData(response.data.data);
                    setTotalRows(response.data.meta.total);
                } else {
                    showToast("Invalid data format in the API response", "error");
                }
                setIsLoading(false);
            })
            .catch((error) => {
                showToast(
                    error.response?.data?.message || "Error fetching data",
                    "error"
                );
                setIsLoading(false);
            });
    };

    // handle delete function
    const handleDelete = (uuid) => {
        Swal.fire({
            title: "Are you sure you want to delete the item out detail?",
            text: "You might not be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            backdrop: `rgba(60,60,60,0.8)`,
            confirmButtonText: "Yes, Delete it!",
        }).then((result) => {
            if (result.value) {
                setIsLoading(true);
                itemChallanDetailService
                    .delete(uuid)
                    .then((response) => {
                        setIsLoading(false);
                        getDataList(currentPage, perPage);
                        Swal.fire(
                            "Item Out Detail Deleted",
                            "The selected item out detail has been marked deleted.",
                            "success"
                        );
                    })
                    .catch((error) => {
                        setIsLoading(false);
                        displayError(error.response.data.errors);
                    });
                setIsLoading(false);
            }
        });
    };
    const showReferesh = () => {
        if (refereshData) {
            getDataList(currentPage, perPage);
            setRefereshData(false);
        }
    };

    useEffect(() => {
        getDataList(currentPage, perPage);
        showReferesh();
    }, [currentPage, perPage, refereshData, type]);

    const tableColumns = React.useMemo(
        () => [
            {
                id: "batch_id",
                name: "Batch Number",
                selector: (row) => row.batch_id,
                sortable: true,
            },
            {
                id: "item_name",
                name: "Item Name",
                selector: (row) => row.item.raw_material_name,
                sortable: true,
                wrap: true
            },
            {
                id: "quantity",
                name: "Quantity",
                selector: (row) => row.quantity,
                sortable: true,
            },
            {
                id: "status",
                name: "Item Status",
                selector: (row) => row.item.status,
                sortable: true,
                cell: (row) => (
                    <span
                        className={`badge rounded-pill bg-${row.item.status === "Active" && !row.deleted_at ? "success" : "danger"
                            } p-2 text-data.statusColor`}
                    >
                        {!row.deleted_at ? row.item.status : "Deleted"}
                    </span>
                ),
            },
            {
                id: "approval_status",
                name: "Approval Status",
                selector: (row) => row.approval_status,
                sortable: true,
                cell: (row) => (
                    <span
                        className={`badge rounded-pill bg-${row.approval_status === "Approved" && !row.deleted_at ? "success" : row.approval_status === "Pending" && !row.deleted_at ? "warning" : "danger"
                            } p-2 text-data.statusColor`}
                    >
                        {!row.deleted_at ? row.approval_status : "Deleted"}
                    </span>
                ),
            },
            {
                id: "actions",
                name: "Actions",
                cell: (row) => (
                    <div className="flex align-items-center list-user-action">
                        {deletePermission && !row.deleted_at && (
                            <a
                                className="btn btn-sm btn-icon btn-danger mr-2"
                                style={{ marginRight: "5px" }}
                                data-toggle="tooltip"
                                data-placement="top"
                                title=""
                                data-original-title="Add"
                                to="#"
                                onClick={() => handleDelete(row.detail_id)}
                            >
                                <span className="btn-inner">{TableIcons.remove()}</span>
                            </a>
                        )}
                    </div>
                ),
            },
        ],
        [],
    );

    const conditionalRowStyles = [
        {
            when: (row) => row.deleted_at,
            style: {
                backgroundColor: "#FFCCCB",
            },
        },
        //we can add more conditions with style
    ];

    const [showView, setShowView] = useState(false);
    const [showAdd, setShowAdd] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [editId, setEditId] = useState();

    const handleAdd = (value) => {
        setShowAdd(!showAdd);
    };

    const [viewId, setViewId] = useState();
    const handleView = (value) => {
        setShowView(!showView);
        setViewId(value);
    };

    return (
        <Fragment>
            {createPermission && showAdd ? (
                <AddItemChallanDetailOut
                    setShowAdd={setShowAdd}
                    showAdd={showAdd}
                    transaction_id={id}
                    challan_id={id}
                    challan_type={type}
                    shift={shift}
                />
            ) : null}
            <Card>
                <Card.Body>
                    <Row>
                        <Col md="12">
                            <div className="d-flex align-items-center justify-content-between gap-2 flex-wrap">
                                <div className="d-flex gap-2 w-50 flex-wrap col-md-3 mb-3">
                                    <h4>{type == 'out' ? "Item Out " : type == 'in' ? "Item In" : "Item"} Detail</h4>
                                </div>

                                <span>
                                    {createPermission && (
                                        <Button
                                            variant="outline-primary"
                                            onClick={() => {
                                                handleAdd("");
                                            }}
                                        >
                                            <AiFillPlusCircle style={{ fontSize: "1.3rem" }} />{" "}
                                            Add New
                                        </Button>
                                    )}
                                </span>
                            </div>
                            <DataTable
                                columns={tableColumns}
                                data={data}
                                theme={dtColorMode == "dark" ? "dark" : ""}
                                selectableRowsHighlight
                                highlightOnHover
                                fixedHeader //Apply conditional style
                                conditionalRowStyles={conditionalRowStyles}
                            />
                        </Col>

                    </Row>
                </Card.Body>
            </Card>
        </Fragment>
    );
};

export default ItemChallanDetailOut;
