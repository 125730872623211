import * as Yup from 'yup';
const mistakeTicketStoreSchema = Yup.object({
    mistake_type: Yup.string().required('Mistake Type is required'),
    pervious_data: Yup.string().required('Pervious Data is required'),
    current_data: Yup.string().required('Current Data is required'),
    remarks: Yup.string().required('Remarks is required'),
    data_date: Yup.date().required('Data Date is required'),
    status: Yup.string().required('Mistake Ticket Status is required.'),
});

export default mistakeTicketStoreSchema;