import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useStateContext } from "../../../context/ContextProvider";
import employeeDocsService from "../../../services/employee-docs.service";
import employeeDocsStoreSchema from "./employee-docs-store-schema";
import axios from "axios";
import axiosClient from "../../../axios";
import axiosFile from "../../../axios-file";

// const AddEmployeeDocs = ({ onClose, employee_id }) => {
//new code
const AddEmployeeDocs = ({ onSubmit, onBack }) => {
  const { showToast, setPageTitle, setIsLoading } = useStateContext();
  const navigate = useNavigate();
  const { id } = useParams();

  const [formData, setFormData] = useState({
    highest_education: "",
    completed_year: "",
    bank_name: "",
    account_number: "",
    lincense_number: "",
    expiry_date: "",
    vehicle_number: "",
    vehicle_type: "",
    citizenship_number: "",
    citizenship_issue_date: "",
    pan_number: "",
    passport_number: "",
    passport_issue_date: "",
    term_and_conditions: false,
    created_by: "",
    updated_by: "",
  });

  const setInitialData = () => {
    formik.setValues(formData);
  };

  const [error, setError] = useState("");
  const [educationList, setEducationList] = useState([]);
  const [bankList, setBankList] = useState([]);
  const [vehicleList, setVehicleList] = useState([]);

  const dropLists = () => {
    employeeDocsService.employeeDocInitalData()
      .then((response) => {
        const data = response.data;
        setEducationList(data.education);
        setBankList(data.bank_name);
        setVehicleList(data.vehicle_type);
      })
      .catch((error) => {
        displayError(error.response.data.errors);
      });
  };

  const [educationImage, setEducationImage] = useState(null);
  const [lincenseImage, setLincenseImage] = useState(null);
  const [citizenImage, setCitizenImage] = useState(null);
  const [panImage, setPanImage] = useState(null);
  const [passportImage, setPassportImage] = useState(null);
  const [passportPhotoImage, setPassportPhotoImage] = useState(null);
  const [policeClearanceImage, setPoliceClearanceImage] = useState(null);

  useEffect(() => {
    setPageTitle("Employee Registration");
    dropLists();
  }, [setPageTitle]);

  const formik = useFormik({
    initialValues: formData,
    validationSchema: employeeDocsStoreSchema,
    onSubmit: (values) => {
      const data = {
        ...values,
        education_file_path: educationImage,
        driving_lincense_file_path: lincenseImage,
        citizenship_file_path: citizenImage,
        pan_file_path: panImage,
        passport_file_path: passportImage,
        passport_photo: passportPhotoImage,
        police_clearance_file_path: policeClearanceImage
      };
      onSubmit(data);
    },
    // onSubmit: async (values) => {
    //   const data = new FormData();

    //   // Append normal values
    //   for (const key in values) {
    //     if (values.hasOwnProperty(key)) {
    //       data.append(key, values[key]);
    //     }
    //   }

    //   // Append files
    //   if (educationImage) data.append("education_file_path", educationImage);
    //   if (lincenseImage) data.append("driving_lincense_file_path", lincenseImage);
    //   if (citizenImage) data.append("citizenship_file_path", citizenImage);
    //   if (panImage) data.append("pan_file_path", panImage);
    //   if (passportImage) data.append("passport_file_path", passportImage);
    //   if (passportPhotoImage) data.append("passport_photo", passportPhotoImage);
    //   if (policeClearanceImage) data.append("police_clearance_file_path", policeClearanceImage);

    //   data.append('term_and_conditions', '1');
    //   data.append('employee_id', employee_id);

    //   setIsLoading(true);

    //   try {
    //     const response = await axios.post('http://localhost:8000/api/new_employees/docs', data, {
    //       headers: {
    //         'Content-Type': 'multipart/form-data',
    //       },
    //     });
    //     showToast("Documents uploaded successfully", "success");
    //     onClose();
    //   } catch (error) {
    //     if (error.response && error.response.data && error.response.data.errors) {
    //       displayError(error.response.data.errors);
    //     } else {
    //       displayError("An error occurred during the upload process.");
    //     }
    //   } finally {
    //     setIsLoading(false);
    //   }
    // }
  });

  const displayError = (error) => {
    let errArr = [];
    errArr = Object.keys(error).map((key) => {
      return error[key].join(",");
    });
    errArr.forEach((column) => showToast(column, "error"));
  };

  return (
    <>
      <Form onSubmit={formik.handleSubmit} className="form-card text-start">
        <Row className="mb-3">
          <h5>Highest Education</h5>
          <hr className="my-3" />
          <Col md="6" className="mb-3">
            <Form.Label htmlFor="highest_education">Highest Education</Form.Label>
            <Form.Select
              name="highest_education"
              value={formik.values.highest_education}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={formik.touched.highest_education && !!formik.errors.highest_education}
            >
              <option value="">Select Education</option>
              {educationList.map((list) => (
                <option key={list.id} value={list.text}>
                  {list.text}
                </option>
              ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              {formik.errors.highest_education}
            </Form.Control.Feedback>
          </Col>
          <Col md="6" className="mb-3">
            <Form.Label htmlFor="completed_year">Completed Year</Form.Label>
            <Form.Control
              type="date"
              name="completed_year"
              value={formik.values.completed_year}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={formik.touched.completed_year && !!formik.errors.completed_year}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.completed_year}
            </Form.Control.Feedback>
          </Col>
          <Col md="12" className="mb-3">
            <Form.Label htmlFor="education_file_path">Upload Highest Education Certificate</Form.Label>
            <Form.Control
              type="file"
              name="education_file_path"
              onChange={(e) => {
                setEducationImage(e.target.files[0]);
                formik.setFieldValue("education_file_path", e.target.files[0]);
              }}
              onBlur={formik.handleBlur}
              isInvalid={formik.touched.education_file_path && !!formik.errors.education_file_path}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.education_file_path}
            </Form.Control.Feedback>
          </Col>
          <h5>Bank Details</h5>
          <hr className="my-3" />
          <Col md="6" className="mb-3">
            <Form.Label htmlFor="bank_name">Bank Name</Form.Label>
            <Form.Select
              name="bank_name"
              value={formik.values.bank_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={formik.touched.bank_name && !!formik.errors.bank_name}
            >
              <option value="">Select Bank Name</option>
              {bankList.map((list) => (
                <option key={list.id} value={list.text}>
                  {list.text}
                </option>
              ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              {formik.errors.bank_name}
            </Form.Control.Feedback>
          </Col>
          <Col md="6" className="mb-3">
            <Form.Label htmlFor="account_number">Account Number</Form.Label>
            <Form.Control
              type="text"
              name="account_number"
              value={formik.values.account_number}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={formik.touched.account_number && !!formik.errors.account_number}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.account_number}
            </Form.Control.Feedback>
          </Col>
          <h5>Driving License</h5>
          <hr className="my-3" />
          <Col md="6" className="mb-3">
            <Form.Label htmlFor="lincense_number">Driving License Number</Form.Label>
            <Form.Control
              type="text"
              name="lincense_number"
              value={formik.values.lincense_number}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={formik.touched.lincense_number && !!formik.errors.lincense_number}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.lincense_number}
            </Form.Control.Feedback>
          </Col>
          <Col md="6" className="mb-3">
            <Form.Label htmlFor="expiry_date">Expiry Date</Form.Label>
            <Form.Control
              type="date"
              name="expiry_date"
              value={formik.values.expiry_date}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={formik.touched.expiry_date && !!formik.errors.expiry_date}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.expiry_date}
            </Form.Control.Feedback>
          </Col>
          <Col md="12" className="mb-3">
            <Form.Label htmlFor="driving_lincense_file_path">Upload Driving License</Form.Label>
            <Form.Control
              type="file"
              name="driving_lincense_file_path"
              onChange={(e) => {
                setLincenseImage(e.target.files[0]);
                formik.setFieldValue("driving_lincense_file_path", e.target.files[0]);
              }}
              onBlur={formik.handleBlur}
              isInvalid={formik.touched.driving_lincense_file_path && !!formik.errors.driving_lincense_file_path}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.driving_lincense_file_path}
            </Form.Control.Feedback>
          </Col>
          <h5>Vehicle Details</h5>
          <hr className="my-3" />
          <Col md="6" className="mb-3">
            <Form.Label htmlFor="vehicle_type">Vehicle Type</Form.Label>
            <Form.Select
              name="vehicle_type"
              value={formik.values.vehicle_type}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={formik.touched.vehicle_type && !!formik.errors.vehicle_type}
            >
              <option value="">Select Vehicle Type</option>
              {vehicleList.map((list) => (
                <option key={list.id} value={list.text}>
                  {list.text}
                </option>
              ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              {formik.errors.vehicle_type}
            </Form.Control.Feedback>
          </Col>
          <Col md="6" className="mb-3">
            <Form.Label htmlFor="vehicle_number">Vehicle Number</Form.Label>
            <Form.Control
              type="text"
              name="vehicle_number"
              value={formik.values.vehicle_number}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={formik.touched.vehicle_number && !!formik.errors.vehicle_number}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.vehicle_number}
            </Form.Control.Feedback>
          </Col>
          <h5>Citizenship</h5>
          <hr className="my-3" />
          <Col md="6" className="mb-3">
            <Form.Label htmlFor="citizenship_number">Citizenship Number</Form.Label>
            <Form.Control
              type="text"
              name="citizenship_number"
              value={formik.values.citizenship_number}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={formik.touched.citizenship_number && !!formik.errors.citizenship_number}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.citizenship_number}
            </Form.Control.Feedback>
          </Col>
          <Col md="6" className="mb-3">
            <Form.Label htmlFor="citizenship_issue_date">Issue Date</Form.Label>
            <Form.Control
              type="date"
              name="citizenship_issue_date"
              value={formik.values.citizenship_issue_date}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={formik.touched.citizenship_issue_date && !!formik.errors.citizenship_issue_date}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.citizenship_issue_date}
            </Form.Control.Feedback>
          </Col>
          <Col md="12" className="mb-3">
            <Form.Label htmlFor="citizenship_file_path">Upload Citizenship</Form.Label>
            <Form.Control
              type="file"
              name="citizenship_file_path"
              onChange={(e) => {
                setCitizenImage(e.target.files[0]);
                formik.setFieldValue("citizenship_file_path", e.target.files[0]);
              }}
              onBlur={formik.handleBlur}
              isInvalid={formik.touched.citizenship_file_path && !!formik.errors.citizenship_file_path}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.citizenship_file_path}
            </Form.Control.Feedback>
          </Col>
          <h5>Pan Details</h5>
          <hr className="my-3" />
          <Col md="12" className="mb-3">
            <Form.Label htmlFor="pan_number">PAN Number</Form.Label>
            <Form.Control
              type="text"
              name="pan_number"
              value={formik.values.pan_number}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={formik.touched.pan_number && !!formik.errors.pan_number}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.pan_number}
            </Form.Control.Feedback>
          </Col>
          <Col md="12" className="mb-3">
            <Form.Label htmlFor="pan_file_path">Upload PAN Card</Form.Label>
            <Form.Control
              type="file"
              name="pan_file_path"
              onChange={(e) => {
                setPanImage(e.target.files[0]);
                formik.setFieldValue("pan_file_path", e.target.files[0]);
              }}
              onBlur={formik.handleBlur}
              isInvalid={formik.touched.pan_file_path && !!formik.errors.pan_file_path}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.pan_file_path}
            </Form.Control.Feedback>
          </Col>
          <h5>Passport</h5>
          <hr className="my-3" />
          <Col md="6" className="mb-3">
            <Form.Label htmlFor="passport_number">Passport Number</Form.Label>
            <Form.Control
              type="text"
              name="passport_number"
              value={formik.values.passport_number}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={formik.touched.passport_number && !!formik.errors.passport_number}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.passport_number}
            </Form.Control.Feedback>
          </Col>
          <Col md="6" className="mb-3">
            <Form.Label htmlFor="passport_issue_date">Issue Date</Form.Label>
            <Form.Control
              type="date"
              name="passport_issue_date"
              value={formik.values.passport_issue_date}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={formik.touched.passport_issue_date && !!formik.errors.passport_issue_date}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.passport_issue_date}
            </Form.Control.Feedback>
          </Col>
          <Col md="6" className="mb-3">
            <Form.Label htmlFor="passport_file_path">Upload Passport</Form.Label>
            <Form.Control
              type="file"
              name="passport_file_path"
              onChange={(e) => {
                setPassportImage(e.target.files[0]);
                formik.setFieldValue("passport_file_path", e.target.files[0]);
              }}
              onBlur={formik.handleBlur}
              isInvalid={formik.touched.passport_file_path && !!formik.errors.passport_file_path}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.passport_file_path}
            </Form.Control.Feedback>
          </Col>
          <Col md="6" className="mb-3">
            <Form.Label htmlFor="passport_photo">Upload Passport Photo</Form.Label>
            <Form.Control
              type="file"
              name="passport_photo"
              onChange={(e) => {
                setPassportPhotoImage(e.target.files[0]);
                formik.setFieldValue("passport_photo", e.target.files[0]);
              }}
              onBlur={formik.handleBlur}
              isInvalid={formik.touched.passport_photo && !!formik.errors.passport_photo}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.passport_photo}
            </Form.Control.Feedback>
          </Col>
          <h5>Police Clearance</h5>
          <hr className="my-3" />
          <Col md="12" className="mb-3">
            <Form.Label htmlFor="police_clearance_file_path">Upload Police Clearance Certificate</Form.Label>
            <Form.Control
              type="file"
              name="police_clearance_file_path"
              onChange={(e) => {
                setPoliceClearanceImage(e.target.files[0]);
                formik.setFieldValue("police_clearance_file_path", e.target.files[0]);
              }}
              onBlur={formik.handleBlur}
              isInvalid={formik.touched.police_clearance_file_path && !!formik.errors.police_clearance_file_path}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.police_clearance_file_path}
            </Form.Control.Feedback>
          </Col>
          <h5>Terms and Conditions</h5>
          <hr className="my-3" />
          <Col md="12" className="mb-3">
            <Form.Check
              type="checkbox"
              label="I hereby declare that the information provided is true & correct. I also understand that any willful dishonesty may render for refusal of this application or immediate termination of employment."
              name="term_and_conditions"
              value={formik.values.term_and_conditions}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={formik.touched.term_and_conditions && !!formik.errors.term_and_conditions}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.term_and_conditions}
            </Form.Control.Feedback>
          </Col>
        </Row>
        <div md="12" className="d-flex justify-content-around">
          <Button variant="outline-danger" onClick={onBack}>Back</Button>
          <div className="col-10 d-flex flex-wrap gap-2 justify-content-end">
            <Button variant="outline-primary" type="submit">
              Save
            </Button>
            <Button variant="outline-warning" onClick={setInitialData}>
              Reset Data
            </Button>
          </div>
        </div>
      </Form>
    </>
  );
};

export default AddEmployeeDocs;

