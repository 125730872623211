import { useFormik } from "formik";
import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  FormCheck,
  Offcanvas,
  Row,
} from "react-bootstrap";
import { useNavigate, } from "react-router-dom";
import { useStateContext } from "../../../context/ContextProvider";
import rawMaterialService from "../../../services/raw-material.service";
import coffeeCloseDetailService from "../../../services/coffee-close-detail.service";
import coffeeCloseDetailUpdateSchema from "./coffee-close-detail-update-schema";
import coffeeCloseDetailStoreSchema from "./coffee-close-detail-store-schema";

const AddCoffeeCloseDetail = ({ setShowAdd, showAdd, id, close_id, type }) => {
  const navigate = useNavigate();

  const {
    setIsLoading,
    setPageTitle,
    showToast,
    setRefereshData,
    refereshData,
  } = useStateContext();

  const data = {
    close_id: "",
    item_id: "",
    open_amount: "",
    close_amount: "",
    status: "Active",
    created_by: "",
    updated_by: "",
  }

  /**
   * The function `displayError` takes an error object as input and displays each error message as a
   * toast notification.
   * @param error - The `error` parameter is an object that contains error messages for different
   * columns. Each key in the `error` object represents a column, and the corresponding value is an
   * array of error messages for that column.
   */
  const displayError = (error) => {
    let errArr = [];
    errArr = Object.keys(error).map((key) => {
      return error[key].join(",");
    });
    errArr.map((column) => showToast(column, "error"));
  };

  const store = (values) => {
    setIsLoading(true);
    let updatedValues = {
      ...values,
      close_id: close_id
    }
    coffeeCloseDetailService
      .store(updatedValues, type)
      .then((response) => {
        setIsLoading(false);
        setShowAdd(!showAdd);
        navigate(`/app/coffee_close/${type}/edit/${close_id}`);
        setRefereshData(true)
        showToast(`New Coffee Close Detail added.`, "success");
      })
      .catch((error) => {
        setIsLoading(false);
        displayError(error.response.data.errors);
      });
  };

  const update = (values) => {
    setIsLoading(true);
    coffeeCloseDetailService
      .update(id, values, type)
      .then((response) => {
        setIsLoading(false);
        setShowAdd(false);
        navigate(`/app/coffee_close/${type}/edit/${close_id}`);
        setRefereshData(true)
        showToast(`Coffee Close Detail updated.`, "success");
      })
      .catch((error) => {
        setIsLoading(false);
        displayError(error.response.data.errors);
      });
  };

  const submitForm = (values) => {
    id === undefined || id === "0" ? store(values) : update(values);
  };

  const [itemList, setItemList] = useState();
  const getItemList = () => {
    rawMaterialService.lists()
      .then((response) => {
        setItemList(response.data.data);
      })
      .catch((error) => {
        displayError(error.response.data.errors);
      })
  }
  const setInitialData = () => {
    if (id !== undefined) {
      setIsLoading(true);
      coffeeCloseDetailService
        .singleView(id, type)
        .then((response) => {
          if (response.status === 200) {
            formik.setValues(response.data.data);
          }
        })
        .catch((error) => {
          showToast(error.response.data.message, "error");
          navigate("/app/coffee_close");
        });
      setIsLoading(false);
    } else {
      formik.setValues(data);
    }
  };

  useEffect(() => {
    getItemList()
    setPageTitle("Coffee Close");
    setInitialData();
  }, [setPageTitle, id]);

  const formik = useFormik({
    initialValues: data,
    validationSchema:
      id !== undefined ? coffeeCloseDetailUpdateSchema : coffeeCloseDetailStoreSchema,
    onSubmit: submitForm,
  });

  return (
    <Container>
      <Offcanvas
        show={showAdd} // Control Offcanvas visibility
        onHide={() => setShowAdd(false)} // Close Offcanvas when clicking the backdrop
        placement="end"
        tabindex="-1"
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
      >
        <Offcanvas.Header class="offcanvas-header">
          <Offcanvas.Title id="offcanvasRightLabel">
            <h4 className="card-title">
              {id
                ? "Edit CoffeeClose Detail"
                : "Add CoffeeClose Detail"}
            </h4>
          </Offcanvas.Title>
          <button
            type="button"
            class="btn-close"
            onClick={() => {
              setShowAdd(false);
            }}
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </Offcanvas.Header>

        <Form onSubmit={formik.handleSubmit}>
          <Offcanvas.Body>
            <Row className="mb-3">
              <h5>Basic Information</h5>
              <hr className="my-3" />
              <Col md="12">
                <Form.Label htmlFor="validationCustom02" className="h6">
                  {" "}
                  Item Name
                </Form.Label>
                <Form.Select
                  className="form-control"
                  id="item_id"
                  name="item_id"
                  value={formik.values.item_id}
                  onChange={(e) => {
                    formik.setFieldValue('item_id', e.target.value)
                    const selectedItem = itemList.find(
                      (item) => item.id === e.target.value
                    );
                    if (selectedItem) {
                      formik.setFieldValue('unit', selectedItem.unit);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  isInvalid={
                    formik.touched.item_id && !!formik.errors.item_id
                  }
                >
                  <option value="">Select Specific Item</option>
                  {itemList ? (
                    <>
                      {itemList.map((itemType) => (
                        <option key={itemType.id} value={itemType.id}>
                          {itemType.text}
                        </option>
                      ))}
                    </>
                  ) : (
                    <></>
                  )}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {formik.errors.item_id}
                </Form.Control.Feedback>
              </Col>
              <Col md="12">
                <Form.Label htmlFor="validationCustom01" className="h6">
                  Open Amount
                </Form.Label>
                <Form.Control
                  type="number"
                  name="open_amount"
                  value={formik.values.open_amount}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={
                    formik.touched.open_amount &&
                    !!formik.errors.open_amount
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.open_amount}
                </Form.Control.Feedback>
              </Col>
              <Col md="12">
                <Form.Label htmlFor="validationCustom01" className="h6">
                  Close Amount
                </Form.Label>
                <Form.Control
                  type="number"
                  name="close_amount"
                  value={formik.values.close_amount}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={
                    formik.touched.close_amount &&
                    !!formik.errors.close_amount
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.close_amount}
                </Form.Control.Feedback>
              </Col>
            </Row>
          </Offcanvas.Body>
          <div className="col-12 d-flex flex-column  position-absolute gap-1 bottom-0 end-20">
            <Button className='m-3 mb-0' variant="outline-secondary" type="submit">
              {id !== undefined ? "Update" : "Save"}
            </Button>
            <Button className='m-3 mb-0' variant="outline-warning" onClick={setInitialData}>
              Reset Data
            </Button>
            <Button
              className='m-3 mb-0' variant="outline-secondary"
              onClick={() => {
                setShowAdd(false);
              }}
            >
              Cancel
            </Button>
          </div>

        </Form>
      </Offcanvas>
    </Container>
  );
};

export default AddCoffeeCloseDetail;
