import React, { useState, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Accordion,
  useAccordionButton,
  AccordionContext,
} from "react-bootstrap";
import { usePermissionContext } from "../../../../context/PremissionContext";

function CustomToggle({ children, eventKey, onClick }) {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(eventKey, (active) =>
    onClick({ state: !active, eventKey: eventKey })
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <Link
      to="#"
      aria-expanded={isCurrentEventKey ? "true" : "false"}
      className="nav-link"
      role="button"
      onClick={(e) => {
        decoratedOnClick(isCurrentEventKey);
      }}
    >
      {children}
    </Link>
  );
}

const VerticalNav = () => {
  const [activeMenu, setActiveMenu] = useState(false);

  const { permissions } = usePermissionContext();

  const companyPermission = permissions.some(permission => permission.name === 'View Company');
  const branchPermission = permissions.some(permission => permission.name === 'View Branch');
  const departmentPermission = permissions.some(permission => permission.name === 'View Department');
  const attributePermission = permissions.some(permission => permission.name === 'View Attribute');
  const fiscalYearPermission = permissions.some(permission => permission.name === 'View Fiscal Year');
  const warehousePermission = permissions.some(permission => permission.name === 'View Warehouse');
  const approvalPermission = permissions.some(permission => permission.name === 'View Approval');
  const rolePermission = permissions.some(permission => permission.name === 'View Role');
  const userPermission = permissions.some(permission => permission.name === 'View User');
  const hrDashboardPermission = permissions.some(permission => permission.name === 'HR Dashboard');
  const employeePermission = permissions.some(permission => permission.name === 'View Employee');
  const hrVendorPermission = permissions.some(permission => permission.name === 'View Employee');
  const hrPurchasePermission = permissions.some(permission => permission.name === 'View Employee');

  const vendorPermission = permissions.some(permission => permission.name === 'View Vendor');
  const rawMaterialPermission = permissions.some(permission => permission.name === 'View Raw Material');
  const menuPermission = permissions.some(permission => permission.name === 'View Menu');
  const packagePermission = permissions.some(permission => permission.name === 'View Package');
  const purchasePermission = permissions.some(permission => permission.name === 'View Purchase Entry');
  const transferPermission = permissions.some(permission => permission.name === 'Create Item Challan');
  const viewtransferPermission = permissions.some(permission => permission.name === 'View Item Challan');
  const warehouseClosePermission = permissions.some(permission => permission.name === 'View Warehouse Close');

  const operationDashboardPermission = permissions.some(permission => permission.name === 'Operation Dashboard');
  const operationReportPermission = permissions.some(permission => permission.name === 'Operation Report');
  const morStaffPermission = permissions.some(permission => permission.name === 'View Mor Stsale');
  const evenStaffPermission = permissions.some(permission => permission.name === 'View Even Stsale');
  const morStockPermission = permissions.some(permission => permission.name === 'View Mor Close');
  const evenStockPermission = permissions.some(permission => permission.name === 'View Even Close');
  const morCoffeePermission = permissions.some(permission => permission.name === 'View Mor Coffee Close');
  const evenCoffeePermission = permissions.some(permission => permission.name === 'View Even Coffee Close');
  const popcornPermission = permissions.some(permission => permission.name === 'View Popcorn Yield');
  const shortPunchPermission = permissions.some(permission => permission.name === 'View Short Punch');
  const stockMistakePermission = permissions.some(permission => permission.name === 'View Stock Mistake');

  const usernamePermission = permissions.some(permission => permission.name === 'View Employee Username');
  //location
  let location = useLocation();
  return (
    <>
      <Accordion as="ul" className="navbar-nav iq-main-menu">
        <li className="nav-item static-item">
          <Link className="nav-link static-item disabled" to="#" tabIndex="-1">
            <span className="default-icon">Home</span>
            <span className="mini-icon">-</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className={`${location.pathname === "/app/dashboard" ? "active" : ""
              } nav-link `}
            aria-current="page"
            to="/app/dashboard"
            onClick={() => { }}
          >
            <i className="icon">
              <svg
                width="20"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.4"
                  d="M16.0756 2H19.4616C20.8639 2 22.0001 3.14585 22.0001 4.55996V7.97452C22.0001 9.38864 20.8639 10.5345 19.4616 10.5345H16.0756C14.6734 10.5345 13.5371 9.38864 13.5371 7.97452V4.55996C13.5371 3.14585 14.6734 2 16.0756 2Z"
                  fill="currentColor"
                ></path>
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4.53852 2H7.92449C9.32676 2 10.463 3.14585 10.463 4.55996V7.97452C10.463 9.38864 9.32676 10.5345 7.92449 10.5345H4.53852C3.13626 10.5345 2 9.38864 2 7.97452V4.55996C2 3.14585 3.13626 2 4.53852 2ZM4.53852 13.4655H7.92449C9.32676 13.4655 10.463 14.6114 10.463 16.0255V19.44C10.463 20.8532 9.32676 22 7.92449 22H4.53852C3.13626 22 2 20.8532 2 19.44V16.0255C2 14.6114 3.13626 13.4655 4.53852 13.4655ZM19.4615 13.4655H16.0755C14.6732 13.4655 13.537 14.6114 13.537 16.0255V19.44C13.537 20.8532 14.6732 22 16.0755 22H19.4615C20.8637 22 22 20.8532 22 19.44V16.0255C22 14.6114 20.8637 13.4655 19.4615 13.4655Z"
                  fill="currentColor"
                ></path>
              </svg>
            </i>
            <span className="item-name">Dashboard</span>
          </Link>
        </li>
        <li>
          <hr className="hr-horizontal" />
        </li>
        <li className="nav-item static-item">
          <Link className="nav-link static-item disabled" to="#" tabIndex="-1">
            <span className="default-icon">Settings</span>
            <span className="mini-icon">-</span>
          </Link>
        </li>
        {(
          companyPermission ||
          branchPermission ||
          departmentPermission ||
          attributePermission ||
          fiscalYearPermission ||
          warehousePermission ||
          approvalPermission
        ) && (
            <Accordion.Item
              as="li"
              eventKey="sidebar-settings-system"
              bsPrefix="nav-item"
            >
              <CustomToggle
                eventKey="sidebar-settings-system"
                onClick={(activeKey) => setActiveMenu(activeKey)}
              >
                <i className="icon">
                  <svg
                    width="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.4"
                      d="M13.3051 5.88243V6.06547C12.8144 6.05584 12.3237 6.05584 11.8331 6.05584V5.89206C11.8331 5.22733 11.2737 4.68784 10.6064 4.68784H9.63482C8.52589 4.68784 7.62305 3.80152 7.62305 2.72254C7.62305 2.32755 7.95671 2 8.35906 2C8.77123 2 9.09508 2.32755 9.09508 2.72254C9.09508 3.01155 9.34042 3.24276 9.63482 3.24276H10.6064C12.0882 3.2524 13.2953 4.43736 13.3051 5.88243Z"
                      fill="currentColor"
                    ></path>
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M15.164 6.08279C15.4791 6.08712 15.7949 6.09145 16.1119 6.09469C19.5172 6.09469 22 8.52241 22 11.875V16.1813C22 19.5339 19.5172 21.9616 16.1119 21.9616C14.7478 21.9905 13.3837 22.0001 12.0098 22.0001C10.6359 22.0001 9.25221 21.9905 7.88813 21.9616C4.48283 21.9616 2 19.5339 2 16.1813V11.875C2 8.52241 4.48283 6.09469 7.89794 6.09469C9.18351 6.07542 10.4985 6.05615 11.8332 6.05615C12.3238 6.05615 12.8145 6.05615 13.3052 6.06579C13.9238 6.06579 14.5425 6.07427 15.164 6.08279ZM10.8518 14.7459H9.82139V15.767C9.82139 16.162 9.48773 16.4896 9.08538 16.4896C8.67321 16.4896 8.34936 16.162 8.34936 15.767V14.7459H7.30913C6.90677 14.7459 6.57311 14.4279 6.57311 14.0233C6.57311 13.6283 6.90677 13.3008 7.30913 13.3008H8.34936V12.2892C8.34936 11.8942 8.67321 11.5667 9.08538 11.5667C9.48773 11.5667 9.82139 11.8942 9.82139 12.2892V13.3008H10.8518C11.2542 13.3008 11.5878 13.6283 11.5878 14.0233C11.5878 14.4279 11.2542 14.7459 10.8518 14.7459ZM15.0226 13.1177H15.1207C15.5231 13.1177 15.8567 12.7998 15.8567 12.3952C15.8567 12.0002 15.5231 11.6727 15.1207 11.6727H15.0226C14.6104 11.6727 14.2866 12.0002 14.2866 12.3952C14.2866 12.7998 14.6104 13.1177 15.0226 13.1177ZM16.7007 16.4318H16.7988C17.2012 16.4318 17.5348 16.1139 17.5348 15.7092C17.5348 15.3143 17.2012 14.9867 16.7988 14.9867H16.7007C16.2885 14.9867 15.9647 15.3143 15.9647 15.7092C15.9647 16.1139 16.2885 16.4318 16.7007 16.4318Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </i>
                <span className="item-name">System</span>
                <i className="right-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </i>
              </CustomToggle>
              <Accordion.Collapse eventKey="sidebar-settings-system">
                <ul className="sub-nav">
                  {companyPermission && (
                    <li className="nav-item">
                      <Link
                        className={`${location.pathname === "/app/company" ? "active" : ""
                          } nav-link`}
                        to="/app/company"
                      >
                        <i className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <g>
                              <circle
                                cx="12"
                                cy="12"
                                r="8"
                                fill="currentColor"
                              ></circle>
                            </g>
                          </svg>
                        </i>
                        <i className="sidenav-mini-icon"> C </i>
                        <span className="item-name">Company</span>
                      </Link>
                    </li>
                  )}
                  {branchPermission && (
                    <li className="nav-item">
                      <Link
                        className={`${location.pathname === "/app/branch" ? "active" : ""
                          } nav-link`}
                        to="/app/branch"
                      >
                        <i className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <g>
                              <circle
                                cx="12"
                                cy="12"
                                r="8"
                                fill="currentColor"
                              ></circle>
                            </g>
                          </svg>
                        </i>
                        <i className="sidenav-mini-icon"> B </i>
                        <span className="item-name">Branches</span>
                      </Link>
                    </li>
                  )}
                  {departmentPermission && (
                    <li className="nav-item">
                      <Link
                        className={`${location.pathname === "/app/department" ? "active" : ""
                          } nav-link`}
                        to="/app/department"
                      >
                        <i className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <g>
                              <circle
                                cx="12"
                                cy="12"
                                r="8"
                                fill="currentColor"
                              ></circle>
                            </g>
                          </svg>
                        </i>
                        <i className="sidenav-mini-icon"> D </i>
                        <span className="item-name">Departments</span>
                      </Link>
                    </li>
                  )}
                  {attributePermission && (
                    <li className="nav-item">
                      <Link
                        className={`${location.pathname === "/app/attribute" ? "active" : ""
                          } nav-link`}
                        to="/app/attribute"
                      >
                        <i className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <g>
                              <circle
                                cx="12"
                                cy="12"
                                r="8"
                                fill="currentColor"
                              ></circle>
                            </g>
                          </svg>
                        </i>
                        <i className="sidenav-mini-icon"> A</i>
                        <span className="item-name"> Attributes</span>
                      </Link>
                    </li>
                  )}
                  {fiscalYearPermission && (
                    <li className="nav-item">
                      <Link
                        className={`${location.pathname === "/app/fiscal_year" ? "active" : ""
                          } nav-link`}
                        to="/app/fiscal_year"
                      >
                        <i className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <g>
                              <circle
                                cx="12"
                                cy="12"
                                r="8"
                                fill="currentColor"
                              ></circle>
                            </g>
                          </svg>
                        </i>
                        <i className="sidenav-mini-icon"> F </i>
                        <span className="item-name">Fiscal Year</span>
                      </Link>
                    </li>
                  )}
                  {warehousePermission && (
                    <li className="nav-item">
                      <Link
                        className={`${location.pathname === "/app/warehouse" ? "active" : ""
                          } nav-link`}
                        to="/app/warehouse"
                      >
                        <i className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <g>
                              <circle
                                cx="12"
                                cy="12"
                                r="8"
                                fill="currentColor"
                              ></circle>
                            </g>
                          </svg>
                        </i>
                        <i className="sidenav-mini-icon"> W </i>
                        <span className="item-name">Warehouse</span>
                      </Link>
                    </li>
                  )}
                  {approvalPermission && (
                    <li className="nav-item">
                      <Link
                        className={`${location.pathname === "/app/approval" ? "active" : ""
                          } nav-link`}
                        to="/app/approval"
                      >
                        <i className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <g>
                              <circle
                                cx="12"
                                cy="12"
                                r="8"
                                fill="currentColor"
                              ></circle>
                            </g>
                          </svg>
                        </i>
                        <i className="sidenav-mini-icon"> A </i>
                        <span className="item-name">Approval</span>
                      </Link>
                    </li>
                  )}
                </ul>
              </Accordion.Collapse>
            </Accordion.Item>
          )}
        {(
          rolePermission ||
          userPermission
        ) && (
            <Accordion.Item
              as="li"
              eventKey="sidebar-settings-admin"
              bsPrefix="nav-item"
            >
              <CustomToggle
                eventKey="sidebar-settings-admin"
                onClick={(activeKey) => setActiveMenu(activeKey)}
              >
                <i className="icon">
                  <svg
                    width="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.4"
                      d="M13.3051 5.88243V6.06547C12.8144 6.05584 12.3237 6.05584 11.8331 6.05584V5.89206C11.8331 5.22733 11.2737 4.68784 10.6064 4.68784H9.63482C8.52589 4.68784 7.62305 3.80152 7.62305 2.72254C7.62305 2.32755 7.95671 2 8.35906 2C8.77123 2 9.09508 2.32755 9.09508 2.72254C9.09508 3.01155 9.34042 3.24276 9.63482 3.24276H10.6064C12.0882 3.2524 13.2953 4.43736 13.3051 5.88243Z"
                      fill="currentColor"
                    ></path>
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M15.164 6.08279C15.4791 6.08712 15.7949 6.09145 16.1119 6.09469C19.5172 6.09469 22 8.52241 22 11.875V16.1813C22 19.5339 19.5172 21.9616 16.1119 21.9616C14.7478 21.9905 13.3837 22.0001 12.0098 22.0001C10.6359 22.0001 9.25221 21.9905 7.88813 21.9616C4.48283 21.9616 2 19.5339 2 16.1813V11.875C2 8.52241 4.48283 6.09469 7.89794 6.09469C9.18351 6.07542 10.4985 6.05615 11.8332 6.05615C12.3238 6.05615 12.8145 6.05615 13.3052 6.06579C13.9238 6.06579 14.5425 6.07427 15.164 6.08279ZM10.8518 14.7459H9.82139V15.767C9.82139 16.162 9.48773 16.4896 9.08538 16.4896C8.67321 16.4896 8.34936 16.162 8.34936 15.767V14.7459H7.30913C6.90677 14.7459 6.57311 14.4279 6.57311 14.0233C6.57311 13.6283 6.90677 13.3008 7.30913 13.3008H8.34936V12.2892C8.34936 11.8942 8.67321 11.5667 9.08538 11.5667C9.48773 11.5667 9.82139 11.8942 9.82139 12.2892V13.3008H10.8518C11.2542 13.3008 11.5878 13.6283 11.5878 14.0233C11.5878 14.4279 11.2542 14.7459 10.8518 14.7459ZM15.0226 13.1177H15.1207C15.5231 13.1177 15.8567 12.7998 15.8567 12.3952C15.8567 12.0002 15.5231 11.6727 15.1207 11.6727H15.0226C14.6104 11.6727 14.2866 12.0002 14.2866 12.3952C14.2866 12.7998 14.6104 13.1177 15.0226 13.1177ZM16.7007 16.4318H16.7988C17.2012 16.4318 17.5348 16.1139 17.5348 15.7092C17.5348 15.3143 17.2012 14.9867 16.7988 14.9867H16.7007C16.2885 14.9867 15.9647 15.3143 15.9647 15.7092C15.9647 16.1139 16.2885 16.4318 16.7007 16.4318Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </i>
                <span className="item-name">Admin</span>
                <i className="right-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </i>
              </CustomToggle>
              <Accordion.Collapse eventKey="sidebar-settings-admin">
                <ul className="sub-nav">
                  {rolePermission && (
                    <li className="nav-item">
                      <Link
                        className={`${location.pathname === "/app/role" ? "active" : ""
                          } nav-link`}
                        to="/app/role"
                      >
                        <i className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <g>
                              <circle
                                cx="12"
                                cy="12"
                                r="8"
                                fill="currentColor"
                              ></circle>
                            </g>
                          </svg>
                        </i>
                        <i className="sidenav-mini-icon"> R </i>
                        <span className="item-name">Role</span>
                      </Link>
                    </li>
                  )}
                  {userPermission && (
                    <li className="nav-item">
                      <Link
                        className={`${location.pathname === "/app/user" ? "active" : ""
                          } nav-link`}
                        to="/app/user"
                      >
                        <i className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <g>
                              <circle
                                cx="12"
                                cy="12"
                                r="8"
                                fill="currentColor"
                              ></circle>
                            </g>
                          </svg>
                        </i>
                        <i className="sidenav-mini-icon"> U </i>
                        <span className="item-name">User</span>
                      </Link>
                    </li>
                  )}
                </ul>
              </Accordion.Collapse>
            </Accordion.Item>
          )}
        <Accordion.Item
          as="li"
          eventKey="sidebar-settings-public"
          bsPrefix="nav-item"
        >
          <CustomToggle
            eventKey="sidebar-settings-public"
            onClick={(activeKey) => setActiveMenu(activeKey)}
          >
            <i className="icon">
              <svg
                width="20"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.4"
                  d="M13.3051 5.88243V6.06547C12.8144 6.05584 12.3237 6.05584 11.8331 6.05584V5.89206C11.8331 5.22733 11.2737 4.68784 10.6064 4.68784H9.63482C8.52589 4.68784 7.62305 3.80152 7.62305 2.72254C7.62305 2.32755 7.95671 2 8.35906 2C8.77123 2 9.09508 2.32755 9.09508 2.72254C9.09508 3.01155 9.34042 3.24276 9.63482 3.24276H10.6064C12.0882 3.2524 13.2953 4.43736 13.3051 5.88243Z"
                  fill="currentColor"
                ></path>
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M15.164 6.08279C15.4791 6.08712 15.7949 6.09145 16.1119 6.09469C19.5172 6.09469 22 8.52241 22 11.875V16.1813C22 19.5339 19.5172 21.9616 16.1119 21.9616C14.7478 21.9905 13.3837 22.0001 12.0098 22.0001C10.6359 22.0001 9.25221 21.9905 7.88813 21.9616C4.48283 21.9616 2 19.5339 2 16.1813V11.875C2 8.52241 4.48283 6.09469 7.89794 6.09469C9.18351 6.07542 10.4985 6.05615 11.8332 6.05615C12.3238 6.05615 12.8145 6.05615 13.3052 6.06579C13.9238 6.06579 14.5425 6.07427 15.164 6.08279ZM10.8518 14.7459H9.82139V15.767C9.82139 16.162 9.48773 16.4896 9.08538 16.4896C8.67321 16.4896 8.34936 16.162 8.34936 15.767V14.7459H7.30913C6.90677 14.7459 6.57311 14.4279 6.57311 14.0233C6.57311 13.6283 6.90677 13.3008 7.30913 13.3008H8.34936V12.2892C8.34936 11.8942 8.67321 11.5667 9.08538 11.5667C9.48773 11.5667 9.82139 11.8942 9.82139 12.2892V13.3008H10.8518C11.2542 13.3008 11.5878 13.6283 11.5878 14.0233C11.5878 14.4279 11.2542 14.7459 10.8518 14.7459ZM15.0226 13.1177H15.1207C15.5231 13.1177 15.8567 12.7998 15.8567 12.3952C15.8567 12.0002 15.5231 11.6727 15.1207 11.6727H15.0226C14.6104 11.6727 14.2866 12.0002 14.2866 12.3952C14.2866 12.7998 14.6104 13.1177 15.0226 13.1177ZM16.7007 16.4318H16.7988C17.2012 16.4318 17.5348 16.1139 17.5348 15.7092C17.5348 15.3143 17.2012 14.9867 16.7988 14.9867H16.7007C16.2885 14.9867 15.9647 15.3143 15.9647 15.7092C15.9647 16.1139 16.2885 16.4318 16.7007 16.4318Z"
                  fill="currentColor"
                ></path>
              </svg>
            </i>
            <span className="item-name">Public Access</span>
            <i className="right-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </i>
          </CustomToggle>
          <Accordion.Collapse eventKey="sidebar-settings-public">
            <ul className="sub-nav">
              <li className="nav-item">
                <Link
                  className={`${location.pathname === "/app/employee/add" ? "active" : ""
                    } nav-link`}
                  to="/app/employee/add"
                >
                  <i className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                    >
                      <g>
                        <circle
                          cx="12"
                          cy="12"
                          r="8"
                          fill="currentColor"
                        ></circle>
                      </g>
                    </svg>
                  </i>
                  <i className="sidenav-mini-icon"> E </i>
                  <span className="item-name">Employee</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={`${location.pathname === "/app/employee/ticket" ? "active" : ""
                    } nav-link`}
                  to="/app/employee/ticket"
                >
                  <i className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                    >
                      <g>
                        <circle
                          cx="12"
                          cy="12"
                          r="8"
                          fill="currentColor"
                        ></circle>
                      </g>
                    </svg>
                  </i>
                  <i className="sidenav-mini-icon"> T </i>
                  <span className="item-name">Ticket Status</span>
                </Link>
              </li>
            </ul>
          </Accordion.Collapse>
        </Accordion.Item>
        {(
          employeePermission
        ) && (
            <Accordion.Item
              as="li"
              eventKey="sidebar-settings-hr"
              bsPrefix="nav-item"
            >
              <CustomToggle
                eventKey="sidebar-settings-hr"
                onClick={(activeKey) => setActiveMenu(activeKey)}
              >
                <i className="icon">
                  <svg
                    width="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.4"
                      d="M13.3051 5.88243V6.06547C12.8144 6.05584 12.3237 6.05584 11.8331 6.05584V5.89206C11.8331 5.22733 11.2737 4.68784 10.6064 4.68784H9.63482C8.52589 4.68784 7.62305 3.80152 7.62305 2.72254C7.62305 2.32755 7.95671 2 8.35906 2C8.77123 2 9.09508 2.32755 9.09508 2.72254C9.09508 3.01155 9.34042 3.24276 9.63482 3.24276H10.6064C12.0882 3.2524 13.2953 4.43736 13.3051 5.88243Z"
                      fill="currentColor"
                    ></path>
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M15.164 6.08279C15.4791 6.08712 15.7949 6.09145 16.1119 6.09469C19.5172 6.09469 22 8.52241 22 11.875V16.1813C22 19.5339 19.5172 21.9616 16.1119 21.9616C14.7478 21.9905 13.3837 22.0001 12.0098 22.0001C10.6359 22.0001 9.25221 21.9905 7.88813 21.9616C4.48283 21.9616 2 19.5339 2 16.1813V11.875C2 8.52241 4.48283 6.09469 7.89794 6.09469C9.18351 6.07542 10.4985 6.05615 11.8332 6.05615C12.3238 6.05615 12.8145 6.05615 13.3052 6.06579C13.9238 6.06579 14.5425 6.07427 15.164 6.08279ZM10.8518 14.7459H9.82139V15.767C9.82139 16.162 9.48773 16.4896 9.08538 16.4896C8.67321 16.4896 8.34936 16.162 8.34936 15.767V14.7459H7.30913C6.90677 14.7459 6.57311 14.4279 6.57311 14.0233C6.57311 13.6283 6.90677 13.3008 7.30913 13.3008H8.34936V12.2892C8.34936 11.8942 8.67321 11.5667 9.08538 11.5667C9.48773 11.5667 9.82139 11.8942 9.82139 12.2892V13.3008H10.8518C11.2542 13.3008 11.5878 13.6283 11.5878 14.0233C11.5878 14.4279 11.2542 14.7459 10.8518 14.7459ZM15.0226 13.1177H15.1207C15.5231 13.1177 15.8567 12.7998 15.8567 12.3952C15.8567 12.0002 15.5231 11.6727 15.1207 11.6727H15.0226C14.6104 11.6727 14.2866 12.0002 14.2866 12.3952C14.2866 12.7998 14.6104 13.1177 15.0226 13.1177ZM16.7007 16.4318H16.7988C17.2012 16.4318 17.5348 16.1139 17.5348 15.7092C17.5348 15.3143 17.2012 14.9867 16.7988 14.9867H16.7007C16.2885 14.9867 15.9647 15.3143 15.9647 15.7092C15.9647 16.1139 16.2885 16.4318 16.7007 16.4318Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </i>
                <span className="item-name">Human Resource</span>
                <i className="right-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </i>
              </CustomToggle>
              <Accordion.Collapse eventKey="sidebar-settings-hr">
                <ul className="sub-nav">
                  <li className="nav-item">
                    <Link
                      className={`${location.pathname === "/app/hr" ? "active" : ""
                        } nav-link`}
                      to="/app/hr"
                    >
                      <i className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                        >
                          <g>
                            <circle
                              cx="12"
                              cy="12"
                              r="8"
                              fill="currentColor"
                            ></circle>
                          </g>
                        </svg>
                      </i>
                      <i className="sidenav-mini-icon"> E </i>
                      <span className="item-name">HR Dashboard</span>
                    </Link>
                  </li>
                  {employeePermission && (
                    <li className="nav-item">
                      <Link
                        className={`${location.pathname === "/app/employee" ? "active" : ""
                          } nav-link`}
                        to="/app/employee"
                      >
                        <i className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <g>
                              <circle
                                cx="12"
                                cy="12"
                                r="8"
                                fill="currentColor"
                              ></circle>
                            </g>
                          </svg>
                        </i>
                        <i className="sidenav-mini-icon"> E </i>
                        <span className="item-name">Employee</span>
                      </Link>
                    </li>
                  )}
                  <li className="nav-item">
                    <Link
                      className={`${location.pathname === "/app/employee/ticket" ? "active" : ""
                        } nav-link`}
                      to="/app/employee/ticket"
                    >
                      <i className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                        >
                          <g>
                            <circle
                              cx="12"
                              cy="12"
                              r="8"
                              fill="currentColor"
                            ></circle>
                          </g>
                        </svg>
                      </i>
                      <i className="sidenav-mini-icon"> T </i>
                      <span className="item-name">Ticket Status</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={`${location.pathname === "/app/hr/vendor" ? "active" : ""
                        } nav-link`}
                      to="/app/hr/vendor"
                    >
                      <i className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                        >
                          <g>
                            <circle
                              cx="12"
                              cy="12"
                              r="8"
                              fill="currentColor"
                            ></circle>
                          </g>
                        </svg>
                      </i>
                      <i className="sidenav-mini-icon"> T </i>
                      <span className="item-name">Vendor</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={`${location.pathname === "/app/purchase_entry" ? "active" : ""
                        } nav-link`}
                      to="/app/purchase_entry"
                    >
                      <i className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                        >
                          <g>
                            <circle
                              cx="12"
                              cy="12"
                              r="8"
                              fill="currentColor"
                            ></circle>
                          </g>
                        </svg>
                      </i>
                      <i className="sidenav-mini-icon"> P </i>
                      <span className="item-name">Purchase Entry</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={`${location.pathname === "/app/warehouse_close" ? "active" : ""
                        } nav-link`}
                      to="/app/warehouse_close"
                    >
                      <i className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                        >
                          <g>
                            <circle
                              cx="12"
                              cy="12"
                              r="8"
                              fill="currentColor"
                            ></circle>
                          </g>
                        </svg>
                      </i>
                      <i className="sidenav-mini-icon"> W </i>
                      <span className="item-name">Warehouse Close</span>
                    </Link>
                  </li>
                </ul>
              </Accordion.Collapse>
            </Accordion.Item>
          )}
        {(
          vendorPermission ||
          rawMaterialPermission ||
          menuPermission ||
          packagePermission ||
          purchasePermission ||
          transferPermission ||
          viewtransferPermission ||
          warehouseClosePermission
        ) && (
            <Accordion.Item
              as="li"
              eventKey="sidebar-settings-store"
              bsPrefix="nav-item"
            >
              <CustomToggle
                eventKey="sidebar-settings-store"
                onClick={(activeKey) => setActiveMenu(activeKey)}
              >
                <i className="icon">
                  <svg
                    width="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.4"
                      d="M13.3051 5.88243V6.06547C12.8144 6.05584 12.3237 6.05584 11.8331 6.05584V5.89206C11.8331 5.22733 11.2737 4.68784 10.6064 4.68784H9.63482C8.52589 4.68784 7.62305 3.80152 7.62305 2.72254C7.62305 2.32755 7.95671 2 8.35906 2C8.77123 2 9.09508 2.32755 9.09508 2.72254C9.09508 3.01155 9.34042 3.24276 9.63482 3.24276H10.6064C12.0882 3.2524 13.2953 4.43736 13.3051 5.88243Z"
                      fill="currentColor"
                    ></path>
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M15.164 6.08279C15.4791 6.08712 15.7949 6.09145 16.1119 6.09469C19.5172 6.09469 22 8.52241 22 11.875V16.1813C22 19.5339 19.5172 21.9616 16.1119 21.9616C14.7478 21.9905 13.3837 22.0001 12.0098 22.0001C10.6359 22.0001 9.25221 21.9905 7.88813 21.9616C4.48283 21.9616 2 19.5339 2 16.1813V11.875C2 8.52241 4.48283 6.09469 7.89794 6.09469C9.18351 6.07542 10.4985 6.05615 11.8332 6.05615C12.3238 6.05615 12.8145 6.05615 13.3052 6.06579C13.9238 6.06579 14.5425 6.07427 15.164 6.08279ZM10.8518 14.7459H9.82139V15.767C9.82139 16.162 9.48773 16.4896 9.08538 16.4896C8.67321 16.4896 8.34936 16.162 8.34936 15.767V14.7459H7.30913C6.90677 14.7459 6.57311 14.4279 6.57311 14.0233C6.57311 13.6283 6.90677 13.3008 7.30913 13.3008H8.34936V12.2892C8.34936 11.8942 8.67321 11.5667 9.08538 11.5667C9.48773 11.5667 9.82139 11.8942 9.82139 12.2892V13.3008H10.8518C11.2542 13.3008 11.5878 13.6283 11.5878 14.0233C11.5878 14.4279 11.2542 14.7459 10.8518 14.7459ZM15.0226 13.1177H15.1207C15.5231 13.1177 15.8567 12.7998 15.8567 12.3952C15.8567 12.0002 15.5231 11.6727 15.1207 11.6727H15.0226C14.6104 11.6727 14.2866 12.0002 14.2866 12.3952C14.2866 12.7998 14.6104 13.1177 15.0226 13.1177ZM16.7007 16.4318H16.7988C17.2012 16.4318 17.5348 16.1139 17.5348 15.7092C17.5348 15.3143 17.2012 14.9867 16.7988 14.9867H16.7007C16.2885 14.9867 15.9647 15.3143 15.9647 15.7092C15.9647 16.1139 16.2885 16.4318 16.7007 16.4318Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </i>
                <span className="item-name">Store</span>
                <i className="right-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </i>
              </CustomToggle>
              <Accordion.Collapse eventKey="sidebar-settings-store">
                <ul className="sub-nav">
                  <li className="nav-item">
                    <Link
                      className={`${location.pathname === "/app/store/report" ? "active" : ""
                        } nav-link`}
                      to="/app/store/report"
                    >
                      <i className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                        >
                          <g>
                            <circle
                              cx="12"
                              cy="12"
                              r="8"
                              fill="currentColor"
                            ></circle>
                          </g>
                        </svg>
                      </i>
                      <i className="sidenav-mini-icon"> R </i>
                      <span className="item-name">Report</span>
                    </Link>
                  </li>
                  {vendorPermission && (
                    <li className="nav-item">
                      <Link
                        className={`${location.pathname === "/app/vendor" ? "active" : ""
                          } nav-link`}
                        to="/app/vendor"
                      >
                        <i className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <g>
                              <circle
                                cx="12"
                                cy="12"
                                r="8"
                                fill="currentColor"
                              ></circle>
                            </g>
                          </svg>
                        </i>
                        <i className="sidenav-mini-icon"> V </i>
                        <span className="item-name">Vendor</span>
                      </Link>
                    </li>
                  )}
                  {rawMaterialPermission && (
                    <li className="nav-item">
                      <Link
                        className={`${location.pathname === "/app/raw_material" ? "active" : ""
                          } nav-link`}
                        to="/app/raw_material"
                      >
                        <i className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <g>
                              <circle
                                cx="12"
                                cy="12"
                                r="8"
                                fill="currentColor"
                              ></circle>
                            </g>
                          </svg>
                        </i>
                        <i className="sidenav-mini-icon"> R </i>
                        <span className="item-name">Raw Material</span>
                      </Link>
                    </li>
                  )}
                  {menuPermission && (
                    <li className="nav-item">
                      <Link
                        className={`${location.pathname === "/app/menu" ? "active" : ""
                          } nav-link`}
                        to="/app/menu"
                      >
                        <i className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <g>
                              <circle
                                cx="12"
                                cy="12"
                                r="8"
                                fill="currentColor"
                              ></circle>
                            </g>
                          </svg>
                        </i>
                        <i className="sidenav-mini-icon"> R </i>
                        <span className="item-name">Menu</span>
                      </Link>
                    </li>
                  )}
                  {packagePermission && (
                    <li className="nav-item">
                      <Link
                        className={`${location.pathname === "/app/package" ? "active" : ""
                          } nav-link`}
                        to="/app/package"
                      >
                        <i className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <g>
                              <circle
                                cx="12"
                                cy="12"
                                r="8"
                                fill="currentColor"
                              ></circle>
                            </g>
                          </svg>
                        </i>
                        <i className="sidenav-mini-icon"> P </i>
                        <span className="item-name">Package</span>
                      </Link>
                    </li>
                  )}
                  {purchasePermission && (
                    <li className="nav-item">
                      <Link
                        className={`${location.pathname === "/app/purchase_entry" ? "active" : ""
                          } nav-link`}
                        to="/app/purchase_entry"
                      >
                        <i className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <g>
                              <circle
                                cx="12"
                                cy="12"
                                r="8"
                                fill="currentColor"
                              ></circle>
                            </g>
                          </svg>
                        </i>
                        <i className="sidenav-mini-icon"> P </i>
                        <span className="item-name">Purchase Entry</span>
                      </Link>
                    </li>
                  )}
                  <li className="nav-item">
                    <Link
                      className={`${location.pathname === "/app/purchase_return" ? "active" : ""
                        } nav-link`}
                      to="/app/purchase_return"
                    >
                      <i className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                        >
                          <g>
                            <circle
                              cx="12"
                              cy="12"
                              r="8"
                              fill="currentColor"
                            ></circle>
                          </g>
                        </svg>
                      </i>
                      <i className="sidenav-mini-icon"> P </i>
                      <span className="item-name">Purchase Return</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={`${location.pathname === "/app/bill_mapping" ? "active" : ""
                        } nav-link`}
                      to="/app/bill_mapping"
                    >
                      <i className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                        >
                          <g>
                            <circle
                              cx="12"
                              cy="12"
                              r="8"
                              fill="currentColor"
                            ></circle>
                          </g>
                        </svg>
                      </i>
                      <i className="sidenav-mini-icon"> B </i>
                      <span className="item-name">Bill Mapping</span>
                    </Link>
                  </li>
                  {transferPermission && (
                    <>
                      <li className="nav-item">
                        <Link
                          className={`${location.pathname === "/app/transfer/out/add" ? "active" : ""
                            } nav-link`}
                          to="/app/transfer/out/add"
                        >
                          <i className="icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10"
                              viewBox="0 0 24 24"
                              fill="currentColor"
                            >
                              <g>
                                <circle
                                  cx="12"
                                  cy="12"
                                  r="8"
                                  fill="currentColor"
                                ></circle>
                              </g>
                            </svg>
                          </i>
                          <i className="sidenav-mini-icon"> T </i>
                          <span className="item-name">Stock Transfer</span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className={`${location.pathname === "/app/transfer/in/add" ? "active" : ""
                            } nav-link`}
                          to="/app/transfer/in/add"
                        >
                          <i className="icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10"
                              viewBox="0 0 24 24"
                              fill="currentColor"
                            >
                              <g>
                                <circle
                                  cx="12"
                                  cy="12"
                                  r="8"
                                  fill="currentColor"
                                ></circle>
                              </g>
                            </svg>
                          </i>
                          <i className="sidenav-mini-icon"> D </i>
                          <span className="item-name">Damage Entry</span>
                        </Link>
                      </li>
                    </>
                  )}
                  {viewtransferPermission && (
                    <li className="nav-item">
                      <Link
                        className={`${location.pathname === "/app/transfer" ? "active" : ""
                          } nav-link`}
                        to="/app/transfer"
                      >
                        <i className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <g>
                              <circle
                                cx="12"
                                cy="12"
                                r="8"
                                fill="currentColor"
                              ></circle>
                            </g>
                          </svg>
                        </i>
                        <i className="sidenav-mini-icon"> T </i>
                        <span className="item-name">Transfer</span>
                      </Link>
                    </li>
                  )}
                  {warehouseClosePermission && (
                    <li className="nav-item">
                      <Link
                        className={`${location.pathname === "/app/warehouse_close" ? "active" : ""
                          } nav-link`}
                        to="/app/warehouse_close"
                      >
                        <i className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <g>
                              <circle
                                cx="12"
                                cy="12"
                                r="8"
                                fill="currentColor"
                              ></circle>
                            </g>
                          </svg>
                        </i>
                        <i className="sidenav-mini-icon"> W </i>
                        <span className="item-name">Warehouse Close</span>
                      </Link>
                    </li>
                  )}
                  <li className="nav-item">
                    <Link
                      className={`${location.pathname === "/app/mistake_ticket" ? "active" : ""
                        } nav-link`}
                      to="/app/mistake_ticket"
                    >
                      <i className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                        >
                          <g>
                            <circle
                              cx="12"
                              cy="12"
                              r="8"
                              fill="currentColor"
                            ></circle>
                          </g>
                        </svg>
                      </i>
                      <i className="sidenav-mini-icon"> M </i>
                      <span className="item-name">Mistake Ticket</span>
                    </Link>
                  </li>
                </ul>
              </Accordion.Collapse>
            </Accordion.Item>
          )}
        <Accordion.Item
          as="li"
          eventKey="sidebar-settings-approvals"
          bsPrefix="nav-item"
        >
          <CustomToggle
            eventKey="sidebar-settings-approvals"
            onClick={(activeKey) => setActiveMenu(activeKey)}
          >
            <i className="icon">
              <svg
                width="20"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.4"
                  d="M13.3051 5.88243V6.06547C12.8144 6.05584 12.3237 6.05584 11.8331 6.05584V5.89206C11.8331 5.22733 11.2737 4.68784 10.6064 4.68784H9.63482C8.52589 4.68784 7.62305 3.80152 7.62305 2.72254C7.62305 2.32755 7.95671 2 8.35906 2C8.77123 2 9.09508 2.32755 9.09508 2.72254C9.09508 3.01155 9.34042 3.24276 9.63482 3.24276H10.6064C12.0882 3.2524 13.2953 4.43736 13.3051 5.88243Z"
                  fill="currentColor"
                ></path>
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M15.164 6.08279C15.4791 6.08712 15.7949 6.09145 16.1119 6.09469C19.5172 6.09469 22 8.52241 22 11.875V16.1813C22 19.5339 19.5172 21.9616 16.1119 21.9616C14.7478 21.9905 13.3837 22.0001 12.0098 22.0001C10.6359 22.0001 9.25221 21.9905 7.88813 21.9616C4.48283 21.9616 2 19.5339 2 16.1813V11.875C2 8.52241 4.48283 6.09469 7.89794 6.09469C9.18351 6.07542 10.4985 6.05615 11.8332 6.05615C12.3238 6.05615 12.8145 6.05615 13.3052 6.06579C13.9238 6.06579 14.5425 6.07427 15.164 6.08279ZM10.8518 14.7459H9.82139V15.767C9.82139 16.162 9.48773 16.4896 9.08538 16.4896C8.67321 16.4896 8.34936 16.162 8.34936 15.767V14.7459H7.30913C6.90677 14.7459 6.57311 14.4279 6.57311 14.0233C6.57311 13.6283 6.90677 13.3008 7.30913 13.3008H8.34936V12.2892C8.34936 11.8942 8.67321 11.5667 9.08538 11.5667C9.48773 11.5667 9.82139 11.8942 9.82139 12.2892V13.3008H10.8518C11.2542 13.3008 11.5878 13.6283 11.5878 14.0233C11.5878 14.4279 11.2542 14.7459 10.8518 14.7459ZM15.0226 13.1177H15.1207C15.5231 13.1177 15.8567 12.7998 15.8567 12.3952C15.8567 12.0002 15.5231 11.6727 15.1207 11.6727H15.0226C14.6104 11.6727 14.2866 12.0002 14.2866 12.3952C14.2866 12.7998 14.6104 13.1177 15.0226 13.1177ZM16.7007 16.4318H16.7988C17.2012 16.4318 17.5348 16.1139 17.5348 15.7092C17.5348 15.3143 17.2012 14.9867 16.7988 14.9867H16.7007C16.2885 14.9867 15.9647 15.3143 15.9647 15.7092C15.9647 16.1139 16.2885 16.4318 16.7007 16.4318Z"
                  fill="currentColor"
                ></path>
              </svg>
            </i>
            <span className="item-name">Approvals</span>
            <i className="right-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </i>
          </CustomToggle>
          <Accordion.Collapse eventKey="sidebar-settings-approvals">
            <ul className="sub-nav">
              <li className="nav-item">
                <Link
                  className={`${location.pathname === "/app/purchase_entry_approval" ? "active" : ""
                    } nav-link`}
                  to="/app/purchase_entry_approval"
                >
                  <i className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                    >
                      <g>
                        <circle
                          cx="12"
                          cy="12"
                          r="8"
                          fill="currentColor"
                        ></circle>
                      </g>
                    </svg>
                  </i>
                  <i className="sidenav-mini-icon"> E </i>
                  <span className="item-name">Purchase Entry</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={`${location.pathname === "/app/purchase_return_approval" ? "active" : ""
                    } nav-link`}
                  to="/app/purchase_return_approval"
                >
                  <i className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                    >
                      <g>
                        <circle
                          cx="12"
                          cy="12"
                          r="8"
                          fill="currentColor"
                        ></circle>
                      </g>
                    </svg>
                  </i>
                  <i className="sidenav-mini-icon"> R </i>
                  <span className="item-name">Purchase Return</span>
                </Link>
              </li>
            </ul>
          </Accordion.Collapse>
        </Accordion.Item>
        {(operationDashboardPermission ||
          operationReportPermission ||
          morStaffPermission ||
          evenStaffPermission ||
          morStockPermission ||
          evenStockPermission ||
          morCoffeePermission ||
          evenCoffeePermission ||
          popcornPermission ||
          shortPunchPermission ||
          stockMistakePermission) && (
            <Accordion.Item
              as="li"
              eventKey="sidebar-settings-operations"
              bsPrefix="nav-item"
            >
              <CustomToggle
                eventKey="sidebar-settings-operations"
                onClick={(activeKey) => setActiveMenu(activeKey)}
              >
                <i className="icon">
                  <svg
                    width="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.4"
                      d="M13.3051 5.88243V6.06547C12.8144 6.05584 12.3237 6.05584 11.8331 6.05584V5.89206C11.8331 5.22733 11.2737 4.68784 10.6064 4.68784H9.63482C8.52589 4.68784 7.62305 3.80152 7.62305 2.72254C7.62305 2.32755 7.95671 2 8.35906 2C8.77123 2 9.09508 2.32755 9.09508 2.72254C9.09508 3.01155 9.34042 3.24276 9.63482 3.24276H10.6064C12.0882 3.2524 13.2953 4.43736 13.3051 5.88243Z"
                      fill="currentColor"
                    ></path>
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M15.164 6.08279C15.4791 6.08712 15.7949 6.09145 16.1119 6.09469C19.5172 6.09469 22 8.52241 22 11.875V16.1813C22 19.5339 19.5172 21.9616 16.1119 21.9616C14.7478 21.9905 13.3837 22.0001 12.0098 22.0001C10.6359 22.0001 9.25221 21.9905 7.88813 21.9616C4.48283 21.9616 2 19.5339 2 16.1813V11.875C2 8.52241 4.48283 6.09469 7.89794 6.09469C9.18351 6.07542 10.4985 6.05615 11.8332 6.05615C12.3238 6.05615 12.8145 6.05615 13.3052 6.06579C13.9238 6.06579 14.5425 6.07427 15.164 6.08279ZM10.8518 14.7459H9.82139V15.767C9.82139 16.162 9.48773 16.4896 9.08538 16.4896C8.67321 16.4896 8.34936 16.162 8.34936 15.767V14.7459H7.30913C6.90677 14.7459 6.57311 14.4279 6.57311 14.0233C6.57311 13.6283 6.90677 13.3008 7.30913 13.3008H8.34936V12.2892C8.34936 11.8942 8.67321 11.5667 9.08538 11.5667C9.48773 11.5667 9.82139 11.8942 9.82139 12.2892V13.3008H10.8518C11.2542 13.3008 11.5878 13.6283 11.5878 14.0233C11.5878 14.4279 11.2542 14.7459 10.8518 14.7459ZM15.0226 13.1177H15.1207C15.5231 13.1177 15.8567 12.7998 15.8567 12.3952C15.8567 12.0002 15.5231 11.6727 15.1207 11.6727H15.0226C14.6104 11.6727 14.2866 12.0002 14.2866 12.3952C14.2866 12.7998 14.6104 13.1177 15.0226 13.1177ZM16.7007 16.4318H16.7988C17.2012 16.4318 17.5348 16.1139 17.5348 15.7092C17.5348 15.3143 17.2012 14.9867 16.7988 14.9867H16.7007C16.2885 14.9867 15.9647 15.3143 15.9647 15.7092C15.9647 16.1139 16.2885 16.4318 16.7007 16.4318Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </i>
                <span className="item-name">Operations</span>
                <i className="right-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </i>
              </CustomToggle>
              <Accordion.Collapse eventKey="sidebar-settings-operations">
                <ul className="sub-nav">
                  {operationDashboardPermission && (
                    <li className="nav-item">
                      <Link
                        className={`${location.pathname === "/app/operation/dashboard" ? "active" : ""
                          } nav-link`}
                        to="/app/operation/dashboard"
                      >
                        <i className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <g>
                              <circle
                                cx="12"
                                cy="12"
                                r="8"
                                fill="currentColor"
                              ></circle>
                            </g>
                          </svg>
                        </i>
                        <i className="sidenav-mini-icon"> D </i>
                        <span className="item-name">Dashboard</span>
                      </Link>
                    </li>
                  )}
                  {operationReportPermission && (
                    <li className="nav-item">
                      <Link
                        className={`${location.pathname === "/app/operation/report" ? "active" : ""
                          } nav-link`}
                        to="/app/operation/report"
                      >
                        <i className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <g>
                              <circle
                                cx="12"
                                cy="12"
                                r="8"
                                fill="currentColor"
                              ></circle>
                            </g>
                          </svg>
                        </i>
                        <i className="sidenav-mini-icon"> R </i>
                        <span className="item-name">Reports</span>
                      </Link>
                    </li>
                  )}
                  {morStaffPermission && (
                    <li className="nav-item">
                      <Link
                        className={`${location.pathname === "/app/stsale_close/mor" ? "active" : ""
                          } nav-link`}
                        to="/app/stsale_close/mor"
                      >
                        <i className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <g>
                              <circle
                                cx="12"
                                cy="12"
                                r="8"
                                fill="currentColor"
                              ></circle>
                            </g>
                          </svg>
                        </i>
                        <i className="sidenav-mini-icon"> S </i>
                        <span className="item-name">Morning Staff Sales</span>
                      </Link>
                    </li>
                  )}
                  {evenStaffPermission && (
                    <li className="nav-item">
                      <Link
                        className={`${location.pathname === "/app/stsale_close/even" ? "active" : ""
                          } nav-link`}
                        to="/app/stsale_close/even"
                      >
                        <i className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <g>
                              <circle
                                cx="12"
                                cy="12"
                                r="8"
                                fill="currentColor"
                              ></circle>
                            </g>
                          </svg>
                        </i>
                        <i className="sidenav-mini-icon"> S </i>
                        <span className="item-name">Evening Staff Sales</span>
                      </Link>
                    </li>
                  )}
                  {morStockPermission && (
                    <li className="nav-item">
                      <Link
                        className={`${location.pathname === "/app/stock_close/mor" ? "active" : ""
                          } nav-link`}
                        to="/app/stock_close/mor"
                      >
                        <i className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <g>
                              <circle
                                cx="12"
                                cy="12"
                                r="8"
                                fill="currentColor"
                              ></circle>
                            </g>
                          </svg>
                        </i>
                        <i className="sidenav-mini-icon"> S </i>
                        <span className="item-name">Morning Stock Closing</span>
                      </Link>
                    </li>
                  )}
                  {evenStockPermission && (
                    <li className="nav-item">
                      <Link
                        className={`${location.pathname === "/app/stock_close/even" ? "active" : ""
                          } nav-link`}
                        to="/app/stock_close/even"
                      >
                        <i className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <g>
                              <circle
                                cx="12"
                                cy="12"
                                r="8"
                                fill="currentColor"
                              ></circle>
                            </g>
                          </svg>
                        </i>
                        <i className="sidenav-mini-icon"> S </i>
                        <span className="item-name">Evening Stock Closing</span>
                      </Link>
                    </li>
                  )}
                  {morCoffeePermission && (
                    <li className="nav-item">
                      <Link
                        className={`${location.pathname === "/app/coffee_close/mor" ? "active" : ""
                          } nav-link`}
                        to="/app/coffee_close/mor"
                      >
                        <i className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <g>
                              <circle
                                cx="12"
                                cy="12"
                                r="8"
                                fill="currentColor"
                              ></circle>
                            </g>
                          </svg>
                        </i>
                        <i className="sidenav-mini-icon"> C </i>
                        <span className="item-name">Morning Coffee Closing</span>
                      </Link>
                    </li>
                  )}
                  {evenCoffeePermission && (
                    <li className="nav-item">
                      <Link
                        className={`${location.pathname === "/app/coffee_close/even" ? "active" : ""
                          } nav-link`}
                        to="/app/coffee_close/even"
                      >
                        <i className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <g>
                              <circle
                                cx="12"
                                cy="12"
                                r="8"
                                fill="currentColor"
                              ></circle>
                            </g>
                          </svg>
                        </i>
                        <i className="sidenav-mini-icon"> C </i>
                        <span className="item-name">Evening Coffee Closing</span>
                      </Link>
                    </li>
                  )}
                  {popcornPermission && (
                    <li className="nav-item">
                      <Link
                        className={`${location.pathname === "/app/popcorn_yield" ? "active" : ""
                          } nav-link`}
                        to="/app/popcorn_yield"
                      >
                        <i className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <g>
                              <circle
                                cx="12"
                                cy="12"
                                r="8"
                                fill="currentColor"
                              ></circle>
                            </g>
                          </svg>
                        </i>
                        <i className="sidenav-mini-icon"> P </i>
                        <span className="item-name">Popcorn Yield</span>
                      </Link>
                    </li>
                  )}
                  {shortPunchPermission && (
                    <li className="nav-item">
                      <Link
                        className={`${location.pathname === "/app/short_punch" ? "active" : ""
                          } nav-link`}
                        to="/app/short_punch"
                      >
                        <i className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <g>
                              <circle
                                cx="12"
                                cy="12"
                                r="8"
                                fill="currentColor"
                              ></circle>
                            </g>
                          </svg>
                        </i>
                        <i className="sidenav-mini-icon"> S </i>
                        <span className="item-name">Short Punch</span>
                      </Link>
                    </li>
                  )}
                  {stockMistakePermission && (
                    <li className="nav-item">
                      <Link
                        className={`${location.pathname === "/app/stock_mistake" ? "active" : ""
                          } nav-link`}
                        to="/app/stock_mistake"
                      >
                        <i className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <g>
                              <circle
                                cx="12"
                                cy="12"
                                r="8"
                                fill="currentColor"
                              ></circle>
                            </g>
                          </svg>
                        </i>
                        <i className="sidenav-mini-icon"> S </i>
                        <span className="item-name">Stock Mistake</span>
                      </Link>
                    </li>
                  )}
                </ul>
              </Accordion.Collapse>
            </Accordion.Item>)}
        {(
          usernamePermission
        ) && (
            <Accordion.Item
              as="li"
              eventKey="sidebar-settings-information"
              bsPrefix="nav-item"
            >
              <CustomToggle
                eventKey="sidebar-settings-information"
                onClick={(activeKey) => setActiveMenu(activeKey)}
              >
                <i className="icon">
                  <svg
                    width="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.4"
                      d="M13.3051 5.88243V6.06547C12.8144 6.05584 12.3237 6.05584 11.8331 6.05584V5.89206C11.8331 5.22733 11.2737 4.68784 10.6064 4.68784H9.63482C8.52589 4.68784 7.62305 3.80152 7.62305 2.72254C7.62305 2.32755 7.95671 2 8.35906 2C8.77123 2 9.09508 2.32755 9.09508 2.72254C9.09508 3.01155 9.34042 3.24276 9.63482 3.24276H10.6064C12.0882 3.2524 13.2953 4.43736 13.3051 5.88243Z"
                      fill="currentColor"
                    ></path>
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M15.164 6.08279C15.4791 6.08712 15.7949 6.09145 16.1119 6.09469C19.5172 6.09469 22 8.52241 22 11.875V16.1813C22 19.5339 19.5172 21.9616 16.1119 21.9616C14.7478 21.9905 13.3837 22.0001 12.0098 22.0001C10.6359 22.0001 9.25221 21.9905 7.88813 21.9616C4.48283 21.9616 2 19.5339 2 16.1813V11.875C2 8.52241 4.48283 6.09469 7.89794 6.09469C9.18351 6.07542 10.4985 6.05615 11.8332 6.05615C12.3238 6.05615 12.8145 6.05615 13.3052 6.06579C13.9238 6.06579 14.5425 6.07427 15.164 6.08279ZM10.8518 14.7459H9.82139V15.767C9.82139 16.162 9.48773 16.4896 9.08538 16.4896C8.67321 16.4896 8.34936 16.162 8.34936 15.767V14.7459H7.30913C6.90677 14.7459 6.57311 14.4279 6.57311 14.0233C6.57311 13.6283 6.90677 13.3008 7.30913 13.3008H8.34936V12.2892C8.34936 11.8942 8.67321 11.5667 9.08538 11.5667C9.48773 11.5667 9.82139 11.8942 9.82139 12.2892V13.3008H10.8518C11.2542 13.3008 11.5878 13.6283 11.5878 14.0233C11.5878 14.4279 11.2542 14.7459 10.8518 14.7459ZM15.0226 13.1177H15.1207C15.5231 13.1177 15.8567 12.7998 15.8567 12.3952C15.8567 12.0002 15.5231 11.6727 15.1207 11.6727H15.0226C14.6104 11.6727 14.2866 12.0002 14.2866 12.3952C14.2866 12.7998 14.6104 13.1177 15.0226 13.1177ZM16.7007 16.4318H16.7988C17.2012 16.4318 17.5348 16.1139 17.5348 15.7092C17.5348 15.3143 17.2012 14.9867 16.7988 14.9867H16.7007C16.2885 14.9867 15.9647 15.3143 15.9647 15.7092C15.9647 16.1139 16.2885 16.4318 16.7007 16.4318Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </i>
                <span className="item-name">IT</span>
                <i className="right-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </i>
              </CustomToggle>
              <Accordion.Collapse eventKey="sidebar-settings-information">
                <ul className="sub-nav">
                  {usernamePermission && (
                    <li className="nav-item">
                      <Link
                        className={`${location.pathname === "/app/employee/username" ? "active" : ""
                          } nav-link`}
                        to="/app/employee/username"
                      >
                        <i className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <g>
                              <circle
                                cx="12"
                                cy="12"
                                r="8"
                                fill="currentColor"
                              ></circle>
                            </g>
                          </svg>
                        </i>
                        <i className="sidenav-mini-icon"> E </i>
                        <span className="item-name">Employee Username</span>
                      </Link>
                    </li>
                  )}

                  <li className="nav-item">
                    <Link
                      className={`${location.pathname === "/app/information-technology/dashboard" ? "active" : ""
                        } nav-link`}
                      to="/app/information-technology/dashboard"
                    >
                      <i className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                        >
                          <g>
                            <circle
                              cx="12"
                              cy="12"
                              r="8"
                              fill="currentColor"
                            ></circle>
                          </g>
                        </svg>
                      </i>
                      <i className="sidenav-mini-icon"> D </i>
                      <span className="item-name">Dashboard</span>
                    </Link>
                  </li>
                </ul>
              </Accordion.Collapse>
            </Accordion.Item>
          )}
        {/* <Accordion.Item as="li" eventKey="sidebar-special" bsPrefix="nav-item">
          <CustomToggle eventKey="sidebar-special" onClick={(activeKey) => setActiveMenu(activeKey)}>
            <i className="icon">
              <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.4" d="M13.3051 5.88243V6.06547C12.8144 6.05584 12.3237 6.05584 11.8331 6.05584V5.89206C11.8331 5.22733 11.2737 4.68784 10.6064 4.68784H9.63482C8.52589 4.68784 7.62305 3.80152 7.62305 2.72254C7.62305 2.32755 7.95671 2 8.35906 2C8.77123 2 9.09508 2.32755 9.09508 2.72254C9.09508 3.01155 9.34042 3.24276 9.63482 3.24276H10.6064C12.0882 3.2524 13.2953 4.43736 13.3051 5.88243Z" fill="currentColor"></path>
                <path fillRule="evenodd" clipRule="evenodd" d="M15.164 6.08279C15.4791 6.08712 15.7949 6.09145 16.1119 6.09469C19.5172 6.09469 22 8.52241 22 11.875V16.1813C22 19.5339 19.5172 21.9616 16.1119 21.9616C14.7478 21.9905 13.3837 22.0001 12.0098 22.0001C10.6359 22.0001 9.25221 21.9905 7.88813 21.9616C4.48283 21.9616 2 19.5339 2 16.1813V11.875C2 8.52241 4.48283 6.09469 7.89794 6.09469C9.18351 6.07542 10.4985 6.05615 11.8332 6.05615C12.3238 6.05615 12.8145 6.05615 13.3052 6.06579C13.9238 6.06579 14.5425 6.07427 15.164 6.08279ZM10.8518 14.7459H9.82139V15.767C9.82139 16.162 9.48773 16.4896 9.08538 16.4896C8.67321 16.4896 8.34936 16.162 8.34936 15.767V14.7459H7.30913C6.90677 14.7459 6.57311 14.4279 6.57311 14.0233C6.57311 13.6283 6.90677 13.3008 7.30913 13.3008H8.34936V12.2892C8.34936 11.8942 8.67321 11.5667 9.08538 11.5667C9.48773 11.5667 9.82139 11.8942 9.82139 12.2892V13.3008H10.8518C11.2542 13.3008 11.5878 13.6283 11.5878 14.0233C11.5878 14.4279 11.2542 14.7459 10.8518 14.7459ZM15.0226 13.1177H15.1207C15.5231 13.1177 15.8567 12.7998 15.8567 12.3952C15.8567 12.0002 15.5231 11.6727 15.1207 11.6727H15.0226C14.6104 11.6727 14.2866 12.0002 14.2866 12.3952C14.2866 12.7998 14.6104 13.1177 15.0226 13.1177ZM16.7007 16.4318H16.7988C17.2012 16.4318 17.5348 16.1139 17.5348 15.7092C17.5348 15.3143 17.2012 14.9867 16.7988 14.9867H16.7007C16.2885 14.9867 15.9647 15.3143 15.9647 15.7092C15.9647 16.1139 16.2885 16.4318 16.7007 16.4318Z" fill="currentColor"></path>
              </svg>
            </i>
            <span className="item-name">Inventory</span>
            <i className="right-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
              </svg>
            </i>
          </CustomToggle>
          <Accordion.Collapse eventKey="sidebar-special" >
            <ul className="sub-nav">
              <li className="nav-item">
                <Link className={`${location.pathname === '/app/supplier' ? 'active' : ''} nav-link`} to="/app/supplier">
                  <i className="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" viewBox="0 0 24 24" fill="currentColor">
                      <g>
                        <circle cx="12" cy="12" r="8" fill="currentColor"></circle>
                      </g>
                    </svg>
                  </i>
                  <i className="sidenav-mini-icon"> S </i>
                  <span className="item-name">Supplier</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link className={`${location.pathname === '/app/manufacturer' ? 'active' : ''} nav-link`} to="/app/manufacturer">
                  <i className="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" viewBox="0 0 24 24" fill="currentColor">
                      <g>
                        <circle cx="12" cy="12" r="8" fill="currentColor"></circle>
                      </g>
                    </svg>
                  </i>
                  <i className="sidenav-mini-icon"> M </i>
                  <span className="item-name">Manufacturer</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link className={`${location.pathname === '/app/warehouse' ? 'active' : ''} nav-link`} to="/app/warehouse">
                  <i className="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" viewBox="0 0 24 24" fill="currentColor">
                      <g>
                        <circle cx="12" cy="12" r="8" fill="currentColor"></circle>
                      </g>
                    </svg>
                  </i>
                  <i className="sidenav-mini-icon"> W </i>
                  <span className="item-name">Warehouse</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link className={`${location.pathname === '/app/category/0' ? 'active' : ''} nav-link`} to="/app/category/0">
                  <i className="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" viewBox="0 0 24 24" fill="currentColor">
                      <g>
                        <circle cx="12" cy="12" r="8" fill="currentColor"></circle>
                      </g>
                    </svg>
                  </i>
                  <i className="sidenav-mini-icon"> C </i>
                  <span className="item-name">Category</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link className={`${location.pathname === '/app/aux_item' ? 'active' : ''} nav-link`} to="/app/aux_item">
                  <i className="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" viewBox="0 0 24 24" fill="currentColor">
                      <g>
                        <circle cx="12" cy="12" r="8" fill="currentColor"></circle>
                      </g>
                    </svg>
                  </i>
                  <i className="sidenav-mini-icon"> A </i>
                  <span className="item-name">Aux Item</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link className={`${location.pathname === '/app/itemstock' ? 'active' : ''} nav-link`} to="/app/itemstock">
                  <i className="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" viewBox="0 0 24 24" fill="currentColor">
                      <g>
                        <circle cx="12" cy="12" r="8" fill="currentColor"></circle>
                      </g>
                    </svg>
                  </i>
                  <i className="sidenav-mini-icon"> I </i>
                  <span className="item-name">Item Stock</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link className={`${location.pathname === '/app/fiscalyear' ? 'active' : ''} nav-link`} to="/app/fiscalyear">
                  <i className="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" viewBox="0 0 24 24" fill="currentColor">
                      <g>
                        <circle cx="12" cy="12" r="8" fill="currentColor"></circle>
                      </g>
                    </svg>
                  </i>
                  <i className="sidenav-mini-icon"> F </i>
                  <span className="item-name">Fiscal Year</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link className={`${location.pathname === '/app/requestion' ? 'active' : ''} nav-link`} to="/app/requestion">
                  <i className="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" viewBox="0 0 24 24" fill="currentColor">
                      <g>
                        <circle cx="12" cy="12" r="8" fill="currentColor"></circle>
                      </g>
                    </svg>
                  </i>
                  <i className="sidenav-mini-icon"> R </i>
                  <span className="item-name">Requestion</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link className={`${location.pathname === '/app/purchase_order' ? 'active' : ''} nav-link`} to="/app/purchase_order">
                  <i className="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" viewBox="0 0 24 24" fill="currentColor">
                      <g>
                        <circle cx="12" cy="12" r="8" fill="currentColor"></circle>
                      </g>
                    </svg>
                  </i>
                  <i className="sidenav-mini-icon"> P </i>
                  <span className="item-name">Purchase Order</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link className={`${location.pathname === '/app/item_challan' ? 'active' : ''} nav-link`} to="/app/item_challan">
                  <i className="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" viewBox="0 0 24 24" fill="currentColor">
                      <g>
                        <circle cx="12" cy="12" r="8" fill="currentColor"></circle>
                      </g>
                    </svg>
                  </i>
                  <i className="sidenav-mini-icon"> C </i>
                  <span className="item-name">Item Challan</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link className={`${location.pathname === '/app/associate' ? 'active' : ''} nav-link`} to="/app/associate">
                  <i className="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" viewBox="0 0 24 24" fill="currentColor">
                      <g>
                        <circle cx="12" cy="12" r="8" fill="currentColor"></circle>
                      </g>
                    </svg>
                  </i>
                  <i className="sidenav-mini-icon"> A </i>
                  <span className="item-name">Associate</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link className={`${location.pathname === '/app/sub_deparment' ? 'active' : ''} nav-link`} to="/app/sub_department">
                  <i className="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" viewBox="0 0 24 24" fill="currentColor">
                      <g>
                        <circle cx="12" cy="12" r="8" fill="currentColor"></circle>
                      </g>
                    </svg>
                  </i>
                  <i className="sidenav-mini-icon"> S </i>
                  <span className="item-name">Sub-Department</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link className={`${location.pathname === '/app/process' ? 'active' : ''} nav-link`} to="/app/process">
                  <i className="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" viewBox="0 0 24 24" fill="currentColor">
                      <g>
                        <circle cx="12" cy="12" r="8" fill="currentColor"></circle>
                      </g>
                    </svg>
                  </i>
                  <i className="sidenav-mini-icon"> P </i>
                  <span className="item-name">Process </span>
                </Link>
              </li>
              <li className="nav-item">
                <Link className={`${location.pathname === '/app/worker' ? 'active' : ''} nav-link`} to="/app/worker">
                  <i className="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" viewBox="0 0 24 24" fill="currentColor">
                      <g>
                        <circle cx="12" cy="12" r="8" fill="currentColor"></circle>
                      </g>
                    </svg>
                  </i>
                  <i className="sidenav-mini-icon"> W </i>
                  <span className="item-name">Worker </span>
                </Link>
              </li>
              <li className="nav-item">
                <Link className={`${location.pathname === '/app/yarn_composition' ? 'active' : ''} nav-link`} to="/app/yarn_composition">
                  <i className="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" viewBox="0 0 24 24" fill="currentColor">
                      <g>
                        <circle cx="12" cy="12" r="8" fill="currentColor"></circle>
                      </g>
                    </svg>
                  </i>
                  <i className="sidenav-mini-icon"> Y </i>
                  <span className="item-name">Yarn Composition </span>
                </Link>
              </li>
              <li className="nav-item">
                <Link className={`${location.pathname === '/app/chart_color' ? 'active' : ''} nav-link`} to="/app/chart_color">
                  <i className="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" viewBox="0 0 24 24" fill="currentColor">
                      <g>
                        <circle cx="12" cy="12" r="8" fill="currentColor"></circle>
                      </g>
                    </svg>
                  </i>
                  <i className="sidenav-mini-icon"> C </i>
                  <span className="item-name">Chart Color </span>
                </Link>
              </li>
            </ul>
          </Accordion.Collapse>
        </Accordion.Item> */}
      </Accordion>
    </>
  );
};

export default VerticalNav;
