import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useStateContext } from "../../context/ContextProvider";
import attributeService from "../../services/attribute.service";
import coffeeCloseService from "../../services/coffee-close.service";
import coffeeCloseUpdateSchema from "./coffee-close-update-schema";
import coffeeCloseStoreSchema from "./coffee-close-store-schema";
import CoffeeCloseDetail from "./coffee-close-detail/coffee-close-detail";
import OpCoffeeCloseDetail from "./op-coffee-close-detail";

const OpAddCoffeeClose = () => {
  const navigate = useNavigate();
  const { id, type } = useParams();
  const { setIsLoading, setPageTitle, showToast } = useStateContext();

  // Get today's date and yesterday's date in YYYY-MM-DD format
  const today = new Date().toISOString().split('T')[0];
  const yesterday = new Date(Date.now() - 86400000).toISOString().split('T')[0];

  const data = {
    shift: "",
    machine_id: "",
    entry_date: today,
    status: "Active",
    created_by: "",
    updated_by: "",
  };

  /**
   * The function `displayError` takes an error object as input and displays each error message as a
   * toast notification.
   * @param error - The `error` parameter is an object that contains error messages for different
   * columns. Each key in the `error` object represents a column, and the corresponding value is an
   * array of error messages for that column.
   */
  const displayError = (error) => {
    let errArr = [];
    errArr = Object.keys(error).map((key) => {
      return error[key].join(",");
    });
    errArr.map((column) =>
      showToast(column, "error")
    );
  }

  const store = (values) => {
    setIsLoading(true);
    const type = values.shift === "Morning" ? 'mor' : values.shift === "Evening" ? 'even' : "";
    coffeeCloseService
      .store(values, type)
      .then((response) => {
        setIsLoading(false);
        navigate(`/app/coffee_close/${type}/edit/${response.data.data.close_id}`);
        showToast(`New Coffee Close added.`, "success");
      })
      .catch((error) => {
        setIsLoading(false);
        displayError(error.response.data.errors);
      });
  };

  const update = (values) => {
    setIsLoading(true);
    coffeeCloseService
      .update(id, values)
      .then((response) => {
        setIsLoading(false);
        navigate(`/app/coffee_close/${type}`);
        showToast(`Coffee Close updated.`, "success");
      })
      .catch((error) => {
        setIsLoading(false);
        displayError(error.response.data.errors);
      });
  };

  const submitForm = (values) => {
    id === undefined || id === "0" ? store(values) : update(values);
  };

  const [machineList, setMachineList] = useState();
  const [shift, setShift] = useState();

  //get the coffeeclose type
  const getCloseType = () => {
    attributeService.childrenList("Coffee Machine")
      .then((response) => {
        setMachineList(response.data);
      })
      .catch((error) => {
        displayError(error.response.data.errors);
      })
    attributeService.childrenList("Shift")
      .then((response) => {
        setShift(response.data);
      })
      .catch((error) => {
        displayError(error.response.data.errors);
      })
  }

  useEffect(() => {
    setPageTitle("Coffee Closing");
    getCloseType();
    if (type === 'mor') {
      formik.setFieldValue('shift', 'Morning');
    } else if (type === 'even') {
      formik.setFieldValue('shift', 'Evening');
    }
  }, [setPageTitle, type]);

  const formik = useFormik({
    initialValues: data,
    validationSchema: id !== undefined ? coffeeCloseUpdateSchema : coffeeCloseStoreSchema,
    onSubmit: submitForm,
  });
  return (
    <Container>
      <Card>
        <Card.Header className="d-flex justify-content-between">
          <div className="header-title">
            <h4 className="card-title">
              {id ? "Edit Coffee Closing" : "Add Coffee Closing"}
            </h4>
          </div>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={formik.handleSubmit}>
            <Row className="mb-3">
              <h5>Basic Information</h5>
              <hr className="my-3" />
              <Col md="6" className="p-2">
                <Form.Label htmlFor="validationDefault041">
                  Shift
                </Form.Label>
                <Form.Select
                  className="form-control form-select"
                  name="shift"
                  value={formik.values.shift}
                  onChange={(e) => {
                    formik.setFieldValue('shift', e.target.value);
                  }}
                  onBlur={formik.handleBlur}
                  isInvalid={
                    formik.touched.shift && !!formik.errors.shift
                  }
                >
                  <option value="">Select the shift</option>
                  {shift ? (
                    <>
                      {
                        shift.map((list) => (
                          <option key={list.id} value={list.text}>{list.text}</option>
                        ))
                      }
                    </>
                  ) :
                    null
                  }
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {formik.errors.shift}
                </Form.Control.Feedback>
              </Col>
              <Col md="6" className="p-2">
                <Form.Label htmlFor="validationDefault041">
                  Machine
                </Form.Label>
                <Form.Select
                  className="form-control form-select"
                  name="machine_id"
                  value={formik.values.machine_id}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={
                    formik.touched.machine_id && !!formik.errors.machine_id
                  }
                >
                  <option value="">Select the machine</option>
                  {machineList ? (
                    <>
                      {
                        machineList.map((list) => (
                          <option key={list.id} value={list.text}>{list.text}</option>
                        ))
                      }
                    </>
                  ) :
                    null
                  }
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {formik.errors.machine_id}
                </Form.Control.Feedback>
              </Col>
              <Col md="6">
                <Form.Label htmlFor="validationCustom01">
                  Entry Date
                </Form.Label>
                <Form.Control
                  type="date"
                  name="entry_date"
                  value={formik.values.entry_date}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  max={today} // Setting max date to today's date
                  min={yesterday} // Setting min date to yesterday's date
                  isInvalid={
                    formik.touched.entry_date &&
                    !!formik.errors.entry_date
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.entry_date}
                </Form.Control.Feedback>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
      {formik.isValid && formik.touched ? (
        <Card>
          <Card.Body>
            <OpCoffeeCloseDetail close_data={formik.values} />
          </Card.Body>
        </Card>
      ) : null}
    </Container>
  );
};

export default OpAddCoffeeClose;
