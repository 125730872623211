import axiosClient from "../axios";

class StockCloseService {
    storeAll(values, type) {
        return axiosClient.post(`/${type}_closes/store_all`, values);
    }
    lists() {
        return axiosClient.get('/mor_closes/list');
    }
    //to get all data
    view(page, rowsPerPage, type) {
        return axiosClient.get(`/${type}_closes?page=${page}&pp=${rowsPerPage}`);
    }
    //to store data
    store(values, type) {
        return axiosClient.post(`/${type}_closes`, values);
    }
    //to update data
    update(uuid, values, type) {
        return axiosClient.put(`/${type}_closes/${uuid}`, values);
    }
    // to simple search 
    search(url, type) {
        return axiosClient.get(`/${type}_closes?${url}`);
    }
    //to search single data
    singleView(uuid, type) {
        return axiosClient.get(`/${type}_closes/${uuid}`);
    }
    //to delete single data
    delete(uuid, type) {
        return axiosClient.delete(`/${type}_closes/` + uuid, { close_id: uuid });
    }
    //to restore single data
    restore(uuid, type) {
        return axiosClient.delete(`/${type}_closes/${uuid}/restore`);
    }
    //to prune single data
    prune(uuid, type) {
        return axiosClient.delete(`/${type}_closes/${uuid}/prune`);
    }
    //to delete selected row data
    deleteAll(selectedRowsUid, type) {
        return axiosClient.post(`/${type}_closes/deleteall`, { 'ids': selectedRowsUid });
    }
    //to restore selected row data
    restoreAll(selectedRowsUUID, type) {
        return axiosClient.post(`/${type}_closes/restoreall`, { 'ids': selectedRowsUUID });
    }
    //to prune selected row data
    pruneAll(selectedRowsUUID, type) {
        return axiosClient.post(`/${type}_closes/pruneall`, { 'ids': selectedRowsUUID });
    }
    //to get deleted data
    viewDeleted(type) {
        return axiosClient.get(`/${type}_closes`);
    }
}
export default new StockCloseService();