import React, { useState, useEffect } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useStateContext } from '../../context/ContextProvider';
import mistakeTicketService from "../../services/mistake-ticket.service";

export default function ViewMistakeTicket() {
  const { setPageTitle, showToast, setIsLoading } = useStateContext();

  const navigate = useNavigate();
  const routeParams = useParams();

  const [data, setData] = useState();

  const displayError = (error) => {
    let errArr = [];
    errArr = Object.keys().map((key) => {
      return error[key].join(",");
    });
    errArr.map((column) =>
      showToast(column, "error")
    );
  }

  const getData = () => {
    mistakeTicketService
      .singleView(routeParams.id)
      .then((response) => {
        setData(response.data.data);
      })
      .catch((error) => {
        displayError(error.response.data.errors);
      });
  };

  const [selectedValue, setSelectedValue] = useState('');

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleSubmit = () => {
    setIsLoading(true);
    if (selectedValue.trim() !== '') {
      mistakeTicketService.approve({
        entry_id: routeParams.id,
        approval_status: selectedValue
      }).then((response) => {
        setIsLoading(false);
        showToast(`The status is updated to ${selectedValue}`, "success");
        navigate("/app/mistake_ticket");
      }).catch((error) => {
        setIsLoading(false);
        displayError(error.response.data.errors);
      })
    } else {
      setIsLoading(false);
      showToast("Please provide status.", "error");
    }
  }

  useEffect(() => {
    setPageTitle("Mistake Tickets");
    getData();
  }, [setPageTitle]);
  return (
    <Container>
      <Card>
        <Card.Header> Approve the Ticket </Card.Header>
        <Card.Body>
          <Row>
            <Col>
              <Form.Select value={selectedValue} onChange={handleSelectChange}>
                <option defaultValue="">Select the status</option>
                <option value="Done">Done</option>
                <option value="Rejected">Rejected</option>
              </Form.Select>
            </Col>
            <Col>
              <Button variant="primary" onClick={handleSubmit}>Submit</Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          {data ? (
            <Row className="mb-3">
              <h5> About {data.ticket_number} Mistake Ticket</h5>
              <hr className="my-3" />
              <Col md="6" className="mb-3">
                <div className="mt-2">
                  <h6 className="mb-1">Ticket Number:</h6>
                  <p>{data.ticket_number}</p>
                </div>
              </Col>
              <Col md="6" className="mb-3">
                <div className="mt-2">
                  <h6 className="mb-1">Mistake Type:</h6>
                  <p>{data.mistake_type}</p>
                </div>
              </Col>
              <Col md="6" className="mb-3">
                <div className="mt-2">
                  <h6 className="mb-1">Pervious Data:</h6>
                  <p>{data.pervious_data}</p>
                </div>
              </Col>
              <Col md="6" className="mb-3">
                <div className="mt-2">
                  <h6 className="mb-1">Current Data:</h6>
                  <p>{data.current_data}</p>
                </div>
              </Col>
              <Col md="6" className="mb-3">
                <div className="mt-2">
                  <h6 className="mb-1">Remarks:</h6>
                  <p>{data.remarks}</p>
                </div>
              </Col>
              <Col md="6" className="mb-3">
                <div className="mt-2">
                  <h6 className="mb-1">Data Date:</h6>
                  <p>{data.data_date}</p>
                </div>
              </Col>
              <Col md="6" className="mb-3">
                <div className="mt-2">
                  <h6 className="mb-1">Entry Date:</h6>
                  <p>{data.entry_date}</p>
                </div>
              </Col>
              <Col md="6" className="mb-3">
                <div className="mt-2">
                  <h6 className="mb-1">Approval/Rejected Date:</h6>
                  <p>{data.approval_date}</p>
                </div>
              </Col>
              <Col md="6" className="mb-3">
                <div className="mt-2">
                  <h6 className="mb-1">Approval Status:</h6>
                  <p>{data.approval_status}</p>
                </div>
              </Col>
              <Col md="6" className="mb-3">
                <div className="mt-2">
                  <h6 className="mb-1">Approved/Rejected By:</h6>
                  <p>{data.approved_by}</p>
                </div>
              </Col>
              <div className="d-flex justify-content-end gap-2">
                <Button variant="secondary" onClick={() => navigate(`/app/mistake_ticket/edit/${data.entry_id}`)}>
                  Edit Mistake Ticket
                </Button>
                <Button variant="secondary"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  back
                </Button>
              </div>
            </Row>
          ) : null}
        </Card.Body>
      </Card>
    </Container>
  );
}
