import React, { memo, Fragment, useState, useEffect } from "react";
import { BsDatabaseFillX } from "react-icons/bs";
import { AiFillDelete, AiFillPlusCircle, AiFillFunnelPlot } from "react-icons/ai";
import { FaSearch } from "react-icons/fa";
import { LuDatabaseBackup } from "react-icons/lu";
// sweetalert
import Swal from "sweetalert2";
//react-bootstrap
import { HiMiniViewfinderCircle } from "react-icons/hi2";
import { Col, Card, Button, Form } from "react-bootstrap";
//components
import { useStateContext } from "../../context/ContextProvider";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import TableIcons from "../../components/TableIcons";
import purchaseReturnService from "../../services/purchase-return.service";
import PurchaseReturnFilter from "./purchase-return-filter";
import { usePermissionContext } from "../../context/PremissionContext";

const PurchaseReturnApproval = memo(() => {
  const navigate = useNavigate();

  const { permissions } = usePermissionContext();

  // const createPermission = permissions.some(permission => permission.name === 'Create Purchase Return');
  // const updatePermission = permissions.some(permission => permission.name === 'Update Purchase Return');
  // const viewPermission = permissions.some(permission => permission.name === 'View Purchase Return');
  // const deletePermission = permissions.some(permission => permission.name === 'Delete Purchase Return');
  // const restorePermission = permissions.some(permission => permission.name === 'Restore Purchase Return');
  // const purgePermission = permissions.some(permission => permission.name === 'Purge Purchase Return');

  const createPermission = true;
  const updatePermission = true;
  const viewPermission = true;
  const deletePermission = true;
  const restorePermission = true;
  const purgePermission = true;

  const { dtColorMode, showToast, setPageTitle, setIsLoading } = useStateContext();
  // Filter parameters state
  const [searchFilter, setSearchFilter] = useState(
    JSON.parse(sessionStorage.getItem("purchase_return_filter")) || {
      purchase_entry_id: [],
      vendor_id: [],
      invoice_date: [],
      item_id: [],
      batch_id: "",
      amount: "",
      remarks: "",
      entry_date: [],
      dataStatus: "Only Live",
      searchKey: "",
    }
  );
  const filterQueryMapping = {
    purchase_entry_id: "in",
    vendor_id: "in",
    item_id: "in",
    batch_id: "like",
    amount: "like",
    remarks: "like",
    invoice_date: "between",
    entry_date: "between",
    dataStatus: "is_del",
    searchKey: "",
  };

  // Data for the datatable
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [searchType, setSearchType] = useState([]);
  // State to manage the show/hide columns
  const [hideColumns, setHideColumns] = useState({
    invoice_no: false,
    challan_no: false,
    invoice_date: false,
    vendor_name: false,
    raw_material_name: false,
    batch_id: false,
    entry_date: false,
    amount: false,
    remarks: false,
    status: false,
    actions: false,
  });
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const handleOffcanvasToggle = () => {
    setShowOffcanvas(!showOffcanvas);
  }

  // Datatable columns
  const tableColumns = React.useMemo(
    () => [
      {
        id: "sno",
        name: "SNo",
        cell: (row, index) => index + 1,
      },
      {
        id: "invoice_no",
        name: (<span>Invoice Number</span>),
        selector: (row) => row.purchase_entry ? row.purchase_entry.invoice_no : "",
        sortable: true,
        omit: hideColumns.invoice_no,
        wrap: true,
      },
      {
        id: "challan_no",
        name: (<span>Challan Number</span>),
        selector: (row) => row.purchase_entry ? row.purchase_entry.challan_no : "",
        sortable: true,
        omit: hideColumns.challan_no,
        wrap: true,
      },
      {
        id: "invoice_date",
        name: (<span>Invoice Date</span>),
        selector: (row) => row.purchase_entry ? row.purchase_entry.invoice_date : "",
        sortable: true,
        omit: hideColumns.invoice_date,
        wrap: true,
      },
      {
        id: "vendor_name",
        name: (<span>Vendor Name</span>),
        selector: (row) => row.purchase_entry ? row.purchase_entry.vendor_name : "",
        sortable: true,
        omit: hideColumns.vendor_id,
        wrap: true,
      },
      {
        id: "raw_material_name",
        name: (<span>Item Name</span>),
        selector: (row) => row.item ? row.item.raw_material_name : "",
        sortable: true,
        omit: hideColumns.raw_material_name,
        wrap: true,
      },
      {
        id: "entry_date",
        name: (<span>Entry Date</span>),
        selector: (row) => row.entry_date,
        sortable: true,
        omit: hideColumns.entry_date,
        wrap: true,
      },
      {
        id: "amount",
        name: "Amount",
        selector: (row) => row.amount,
        sortable: true,
        omit: hideColumns.amount,
        wrap: true,
      },
      {
        id: "remarks",
        name: "Remarks",
        selector: (row) => row.remarks,
        sortable: true,
        omit: hideColumns.remarks,
        wrap: true,
      },
      //TODO
      {
        id: "status",
        name: (<span>Purchase Return Status</span>),
        selector: (row) => row.status,
        sortable: true,
        omit: hideColumns.status,
        wrap: true,
        grow: 2,
        cell: (row) => (
          <span className={`badge rounded-pill bg-${row.status.some(status => status.status === "Pending") ? "danger" : "success"} p-2 text-data.statusColor`}>
            {row.status.some(status => status.status === "Pending") && (
              <>
                {row.status.find(status => status.status === "Pending").approval_name}: Pending
              </>
            )}
            {!row.status.some(status => status.status === "Pending") && (
              <>
                {row.status[row.status.length - 1].approval_name}: {row.status[row.status.length - 1].status}
              </>
            )}
          </span>

        ),
      },
      {
        id: "actions",
        name: "Actions",
        cell: (row) => (
          <div className="flex align-items-center list-user-action">
            {viewPermission && (
              <HiMiniViewfinderCircle
                style={{ fontSize: "1.8rem", marginRight: "5px" }}
                className="btn btn-sm btn-icon btn-warning mr-2"
                onClick={() => handleView(row.entry_id)}
              />
            )}
          </div>
        ),
      },
    ],
    [hideColumns],
  );
  // Function to handle column visibility
  const handleHideColumnsChange = (columnId, value) => {
    setHideColumns({
      ...hideColumns,
      [columnId]: !value
    });
  };

  // Data filter and search related functions
  // Get query string for DataStatus
  const getDataStatusQuery = (e) => {
    let is_del_value = `is_del=${searchFilter.dataStatus === "Only Live"
      ? 0
      : searchFilter.dataStatus === "Deleted Only"
        ? 1
        : 2
      }`;
    return is_del_value;
  };

  const getFilterQueryString = (key, value, index) => {
    const mapping = filterQueryMapping[key];
    if (mapping === "like") {
      const values = Array.isArray(value) ? value.join(",") : value;
      return `${mapping}[${index}]=${key},${values}`;
    } else if (mapping === "in") {
      const values = value.map((item) => item.value).join(",");
      return `${mapping}=${key},${values}`;
    } else if (mapping === "between") {
      const values = value.map((item) => item.value).join(",");
      return `${mapping}=${key},${value}`;
    }
  }

  const getSearchFilterQuery = () => {
    const queryParams = [];
    let index = 0;

    for (const key in searchFilter) {
      if (key == 'dataStatus') continue;
      let value = searchFilter[key];
      if (key == 'searchKey' && value !== "") {
        // remove white spaces in the searchKey
        value = value.replace(/\s/g, '');
        // create the query string over here
        return `&like[0]=invoice_no,${value}&like[1]=status,${value}`
      }
      // Check if the value is defined and not an empty array
      if (value && (Array.isArray(value) ? value.length > 0 : true)) {
        queryParams.push(getFilterQueryString(key, value, index));
        if (filterQueryMapping[key] === "like") {
          ++index;
        }
      }
    }
    if (queryParams.length)
      return "&" + `${queryParams.join("&")}`;
    return "";
  };

  const getDataList = (page, rowsPerPage) => {
    setIsLoading(true);
    let url = `page=${page}&pp=${rowsPerPage}&${getDataStatusQuery()}${getSearchFilterQuery()}`;
    purchaseReturnService
      .search(url)
      .then((response) => {
        setData(response.data.data);
        setTotalRows(response.data.meta.total);
        setIsLoading(false);
      })
      .catch((error) => {
        showToast(error.response.data.message, "error");
        setIsLoading(false);
      });
  }
  // Upd ate this function to handle the switching between searchKey and search filter parameters
  const handleSearchFilter = (value) => {
    // when keysearch is pressed, set the searchKey and clean others
    // when filtersearch is pressed, clear the search key and set others
    if (value === "keySearch") {
      setSearchFilter(
        {
          ...searchFilter,
          invoice_no: "",
          category_id: [],
          status: []
        }
      );
    } else {
      setSearchFilter({
        ...searchFilter,
        searchKey: "",
      });
    }
    sessionStorage.setItem("purchase_return_filter", JSON.stringify(searchFilter));
    getDataList(1, perPage);
  };

  const conditionalRowStyles = [
    {
      when: (row) => row.deleted_at,
      style: {
        backgroundColor: "#FFCCCB",
      },
    },
    //we can add more conditions with style
  ];

  const handleInputChange = (name, value) => {
    setSearchFilter({
      ...searchFilter,
      [name]: value,
    });
  };

  const handleExport = () => {
    setIsLoading(true);
    showToast("Data exported successfully", "success");
    setIsLoading(false);
  };

  const handleView = (value) => {
    navigate(`/app/purchase_return_approval/view/${value}`);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearchFilter("keySearch");
    }
  }

  useEffect(() => {
    getDataList(currentPage, perPage);
    setPageTitle('Purchase Returns');
  }, [currentPage, perPage]);


  return (
    <>
      <Fragment>
        <Card>
          <Card.Header className="d-flex justify-content-between">
            <div className="header-title">
              <h4 className="card-title">Purchase Return List</h4>
            </div>
          </Card.Header>
          <Card.Body>
            <div
            // className="custom-table-effect table-responsive mt-3"
            >
              <DataTable
                columns={tableColumns}
                data={data}
                pagination
                paginationServer
                debugger
                paginationPerPage={perPage}
                paginationComponentOptions={{
                  selectAllRowsItem: true,
                  selectAllRowsItemText: "All",
                }}
                paginationRowsPerPageOptions={[10, 20, 30, 50]}
                paginationServerPage={currentPage}
                paginationTotalRows={totalRows}
                onChangePage={(page) => {
                  setCurrentPage(page);
                }}
                onChangeRowsPerPage={(newPerPage, page) => {
                  setPerPage(newPerPage === "All" ? data.length : newPerPage);
                  setCurrentPage(page);
                }}
                selectableRows
                className=""
                theme={dtColorMode == "dark" ? "dark" : ""}
                selectableRowsHighlight
                highlightOnHover
                fixedHeader
                conditionalRowStyles={conditionalRowStyles} //Apply conditional style
                subHeader
                subHeaderComponent={
                  <>
                    <Col md="12">
                      <div className="d-flex align-items-center justify-content-between gap-2 flex-wrap">
                        <div className="d-flex gap-2 w-50 flex-wrap col-md-3 mb-3">
                          <Form
                            onKeyPress={handleKeyPress}
                            className="d-flex gap-2 w-50 flex-wrap col-md-3 mb-3"
                          >
                            <Form.Control
                              name="searchKey"
                              style={{ width: "200px" }}
                              type="text"
                              placeholder="Search"
                              value={searchFilter.searchKey}
                              onChange={(e) => {
                                handleInputChange(e.target.name, e.target.value);
                              }}
                            />
                            <a className="btn btn-sm btn-outline-secondary d-flex p-2"
                              onClick={() => handleSearchFilter("keySearch")}
                            >
                              <FaSearch style={{ fontSize: "1.3rem" }} />
                            </a>
                            <a className="btn btn-sm btn-outline-secondary d-flex p-2"
                              onClick={() => setShowOffcanvas(!showOffcanvas)} // Toggle the Offcanvas
                              data-bs-toggle="offcanvas"
                              data-bs-target="#offcanvasRight"
                              aria-controls="offcanvasRight"
                            >
                              <AiFillFunnelPlot
                                style={{ fontSize: "1.3rem" }}
                              />
                            </a>

                          </Form>
                        </div>
                        <PurchaseReturnFilter tableColumns={tableColumns} showOffcanvas={showOffcanvas} searchFilter={searchFilter} handleOffcanvasToggle={handleOffcanvasToggle} handleHideColumnsChange={handleHideColumnsChange} handleInputChange={handleInputChange} handleSearchFilter={handleSearchFilter} />
                      </div>
                    </Col>
                  </>
                }
              />
            </div>
          </Card.Body>
        </Card>
      </Fragment>
    </>
  );
});

PurchaseReturnApproval.displayName = "PurchaseReturnApproval";
export default PurchaseReturnApproval;
