import * as Yup from 'yup';

// Get today's date and yesterday's date in YYYY-MM-DD format
const today = new Date().toISOString().split('T')[0];
const yesterday = new Date(Date.now() - 86400000).toISOString().split('T')[0];

const coffeeCloseUpdateSchema = Yup.object({
    shift: Yup.string().required('Shift is required'),
    machine_id: Yup.string().required('Machine is required'),
    entry_date: Yup.date()
        .required('Entry Date is required')
        .min(yesterday, `Entry Date cannot be before ${yesterday}`)
        .max(today, `Entry Date cannot be after ${today}`),
    status: Yup.string().required('ShiftClose Status is required.'),
});

export default coffeeCloseUpdateSchema;
