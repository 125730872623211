import axiosClient from "../axios";

class CoffeeCloseDetailService {
    lists() {
        return axiosClient.get('/mor_coffees/details/list');
    }
    //to get all data
    view(page, rowsPerPage, type) {
        return axiosClient.get(`/${type}_coffees/details?page=${page}&pp=${rowsPerPage}`);
    }
    //to store data
    store(values, type) {
        return axiosClient.post(`/${type}_coffees/details`, values);
    }
    //to update data
    update(uuid, values, type) {
        return axiosClient.put(`/${type}_coffees/details/${uuid}`, values);
    }
    // to simple search 
    search(url, type) {
        return axiosClient.get(`/${type}_coffees/details?${url}`);
    }
    //to search single data
    singleView(uuid, type) {
        return axiosClient.get(`/${type}_coffees/details/${uuid}`);
    }
    //to delete single data
    delete(uuid, type) {
        return axiosClient.delete(`/${type}_coffees/details/` + uuid, { detail_id: uuid });
    }
    //to restore single data
    restore(uuid) {
        return axiosClient.delete(`/mor_coffees/details/${uuid}/restore`);
    }
    //to prune single data
    prune(uuid) {
        return axiosClient.delete(`/mor_coffees/details/${uuid}/prune`);
    }
    //to delete selected row data
    deleteAll(selectedRowsUid) {
        return axiosClient.post("/mor_coffees/details/deleteall", { 'ids': selectedRowsUid });
    }
    //to restore selected row data
    restoreAll(selectedRowsUUID) {
        return axiosClient.post("/mor_coffees/details/restoreall", { 'ids': selectedRowsUUID });
    }
    //to prune selected row data
    pruneAll(selectedRowsUUID) {
        return axiosClient.post("/mor_coffees/details/pruneall", { 'ids': selectedRowsUUID });
    }
    //to get deleted data
    viewDeleted() {
        return axiosClient.get("/mor_coffees/details");
    }
}
export default new CoffeeCloseDetailService();