import { useFormik } from "formik";
import React, { useState, useEffect, Fragment } from "react";
import {
    Button,
    Card,
    Col,
    Container,
    Form,
    FormCheck,
    Offcanvas,
    Row,
} from "react-bootstrap";
import Select from 'react-select';
import { useNavigate, useParams } from "react-router-dom";
import TableIcons from "../../../components/TableIcons";
import Swal from "sweetalert2";
import { AiFillPlusCircle } from "react-icons/ai";
import DataTable from "react-data-table-component";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { HiMiniViewfinderCircle } from "react-icons/hi2";
import { useStateContext } from "../../../context/ContextProvider";
import AddStockCloseDetail from "./add-stock-close-detail";

import rawMaterialService from "../../../services/raw-material.service";
import stockCloseDetailService from "../../../services/stock-close-detail.service";
import stockCloseDetailUpdateSchema from "./stock-close-detail-update-schema";
import stockCloseDetailStoreSchema from "./stock-close-detail-store-schema";
import stockCloseService from "../../../services/stock-close.service";
import attributeService from "../../../services/attribute.service";
import { usePermissionContext } from "../../../context/PremissionContext";

const StockCloseDetail = ({ close_id }) => {
    const { id, type } = useParams();
    const navigate = useNavigate();

    const { permissions } = usePermissionContext();

    const createPermission = permissions.some(permission => permission.name === 'Create Mor Close Detail');
    const updatePermission = permissions.some(permission => permission.name === 'Update Mor Close Detail');
    const viewPermission = permissions.some(permission => permission.name === 'View Mor Close Detail');
    const deletePermission = permissions.some(permission => permission.name === 'Delete Mor Close Detail');
    const restorePermission = permissions.some(permission => permission.name === 'Restore Mor Close Detail');
    const purgePermission = permissions.some(permission => permission.name === 'Purge Mor Close Detail');


    if (type === "mor") {
        const createPermission = permissions.some(permission => permission.name === 'Create Mor Close Detail');
        const updatePermission = permissions.some(permission => permission.name === 'Update Mor Close Detail');
        const viewPermission = permissions.some(permission => permission.name === 'View Mor Close Detail');
        const deletePermission = permissions.some(permission => permission.name === 'Delete Mor Close Detail');
        const restorePermission = permissions.some(permission => permission.name === 'Restore Mor Close Detail');
        const purgePermission = permissions.some(permission => permission.name === 'Purge Mor Close Detail');
    }
    if (type === "even") {
        const createPermission = permissions.some(permission => permission.name === 'Create Even Close Detail');
        const updatePermission = permissions.some(permission => permission.name === 'Update Even Close Detail');
        const viewPermission = permissions.some(permission => permission.name === 'View Even Close Detail');
        const deletePermission = permissions.some(permission => permission.name === 'Delete Even Close Detail');
        const restorePermission = permissions.some(permission => permission.name === 'Restore Even Close Detail');
        const purgePermission = permissions.some(permission => permission.name === 'Purge Even Close Detail');

    }

    const {
        dtColorMode,
        setIsLoading,
        setPageTitle,
        showToast,
        setRefereshData,
        refereshData,
    } = useStateContext();

    /**
     * The function `displayError` takes an error object as input and displays each error message as a
     * toast notification.
     * @param error - The `error` parameter is an object that contains error messages for different
     * columns. Each key in the `error` object represents a column, and the corresponding value is an
     * array of error messages for that column.
     */
    const displayError = (error) => {
        let errArr = [];
        errArr = Object.keys(error).map((key) => {
            return error[key].join(",");
        });
        errArr.map((column) => showToast(column, "error"));
    };
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [totalRows, setTotalRows] = useState(0);
    // Datatable columns

    const getDataList = (page, rowsPerPage) => {
        setIsLoading(true);
        let url = `?in=close_id,${close_id}`;
        stockCloseDetailService
            .search(url, type)
            .then((response) => {
                if (response.data && response.data.data) {
                    setData(response.data.data);
                    setTotalRows(response.data.meta.total);
                } else {
                    showToast("Invalid data format in the API response", "error");
                }
                setIsLoading(false);
            })
            .catch((error) => {
                showToast(
                    error.response?.data?.message || "Error fetching data",
                    "error"
                );
                setIsLoading(false);
            });
    };

    // handle delete function
    const handleDelete = (uuid) => {
        Swal.fire({
            title: "Are you sure you want to delete the stock close detail?",
            text: "You might not be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            backdrop: `rgba(60,60,60,0.8)`,
            confirmButtonText: "Yes, Delete it!",
        }).then((result) => {
            if (result.value) {
                setIsLoading(true);
                stockCloseDetailService
                    .delete(uuid, type)
                    .then((response) => {
                        setIsLoading(false);
                        getDataList(currentPage, perPage);
                        Swal.fire("Stock Close Detail Deleted", "The selected detail has been marked deleted.", "success");
                    })
                    .catch((error) => {
                        setIsLoading(false);
                        displayError(error.response.data.errors);
                    });
                setIsLoading(false);
            }
        });
    };

    const showReferesh = () => {
        if (refereshData) {
            getDataList(currentPage, perPage);
            setRefereshData(false);
        }
    };

    const [showTransferable, setShowTransferable] = useState(false);

    const detailData = {
        close_id: "",
        item_id: "",
        close_amount: "",
        trans_to_shift: "0",
        created_by: "",
        updated_by: "",
    }

    const store = (values) => {
        setIsLoading(true);
        let updatedValues = {
            ...values,
            close_id: id
        }
        stockCloseDetailService
            .store(updatedValues, type)
            .then((response) => {
                setIsLoading(false);
                navigate(`/app/stock_close/${type}/edit/${id}`);
                setRefereshData(true)
                showToast(`New Stock Close Detail added.`, "success");
            })
            .catch((error) => {
                setIsLoading(false);
                displayError(error.response.data.errors);
            });
    };


    const submitForm = (values) => {
        store(values);
    };

    const [itemList, setItemList] = useState();
    const getItemList = () => {
        rawMaterialService.lists()
            .then((response) => {
                setItemList(response.data.data);
            })
            .catch((error) => {
                displayError(error.response.data.errors);
            })
    }
    const setInitialData = () => {
        if (id !== undefined) {
            setIsLoading(true);
            stockCloseDetailService
                .singleView(id, type)
                .then((response) => {
                    if (response.status === 200) {
                        formik.setValues(response.data.data);
                    }
                })
                .catch((error) => {
                    showToast(error.response.data.message, "error");
                    navigate("/app/stock_close");
                });
            setIsLoading(false);
        } else {
            formik.setValues(data);
        }
    };

    const formik = useFormik({
        initialValues: detailData,
        validationSchema:
            id !== undefined ? stockCloseDetailUpdateSchema : stockCloseDetailStoreSchema,
        onSubmit: submitForm,
    });

    useEffect(() => {
        getItemList()
        getDataList(currentPage, perPage);
        showReferesh();
    }, [currentPage, perPage, refereshData, close_id]);

    const options = itemList?.map((item) => ({
        value: item.id,
        label: item.text,
        unit: item.unit,
        transferable: item.transferable,
    })) || [];

    const tableColumns = React.useMemo(
        () => [
            {
                id: "item_id",
                name: "Raw Material Name",
                selector: (row) => row.item.raw_material_name,
                sortable: true,
            },
            {
                id: "close_amount",
                name: "Close Amount",
                selector: (row) => row.close_amount,
                sortable: true,
            },
            {
                id: "trans_to_shift",
                name: "Transferred Amount",
                selector: (row) => row.trans_to_shift,
                sortable: true,
            },
            {
                id: "status",
                name: "Detail Status",
                selector: (row) => row.status,
                sortable: true,
                cell: (row) => (
                    <span
                        className={`badge rounded-pill bg-${row.status === "Active" ? "success" : "danger"
                            } p-2 text-data.statusColor`}
                    >
                        {row.status}
                    </span>
                ),
            },
            {
                id: "actions",
                name: "Actions",
                cell: (row) => (
                    <div className="flex align-items-center list-user-action">
                        {deletePermission && (
                            <a
                                className="btn btn-sm btn-icon btn-danger mr-2"
                                style={{ marginRight: "5px" }}
                                data-toggle="tooltip"
                                data-placement="top"
                                title=""
                                data-original-title="Add"
                                to="#"
                                onClick={() => handleDelete(row.detail_id)}
                            >
                                <span className="btn-inner">{TableIcons.remove()}</span>
                            </a>
                        )}
                        {viewPermission && (
                            <HiMiniViewfinderCircle
                                style={{ fontSize: "1.8rem", marginRight: "5px" }}
                                className="btn btn-sm btn-icon btn-warning mr-2"
                                onClick={() => handleView(row.detail_id)}
                            />
                        )}
                    </div>
                ),
            },
        ],
        [],
    );

    const [viewId, setViewId] = useState();
    const [showView, setShowView] = useState(false);
    const handleView = (value) => {
        setShowView(!showView);
        setViewId(value);
    };

    return (
        <Fragment>
            <Col md="12">
                <div className="d-flex align-items-center justify-content-between gap-2 flex-wrap">
                    <div className="d-flex gap-2 w-50 flex-wrap col-md-3 mb-3">
                        <h4>Stock Closing Detail</h4>
                    </div>
                </div>
                <DataTable
                    columns={tableColumns}
                    data={data}
                    theme={dtColorMode == "dark" ? "dark" : ""}
                    selectableRowsHighlight
                    highlightOnHover
                    fixedHeader
                    subHeader
                    subHeaderComponent={
                        <Form onSubmit={formik.handleSubmit} className="justify-content-md-start">
                            <Row className="text-start">
                                <Col md="4">
                                    <Form.Label htmlFor="validationCustom02" className="h6">
                                        Item Name
                                    </Form.Label>
                                    <Select
                                        id="item_id"
                                        name="item_id"
                                        options={options}
                                        value={options.find(option => option.value === formik.values.item_id)}
                                        onChange={(selectedOption) => {
                                            formik.setFieldValue('item_id', selectedOption.value);
                                            if (selectedOption) {
                                                if (selectedOption.transferable === "Yes") {
                                                    formik.setFieldValue("trans_to_shift", 0);
                                                    setShowTransferable(true);
                                                } else {
                                                    formik.setFieldValue("trans_to_shift", 0);
                                                    setShowTransferable(false);
                                                }
                                                formik.setFieldValue('unit', selectedOption.unit);
                                            }
                                        }}
                                        onBlur={formik.handleBlur}
                                        className={`form-control p-0 ${formik.touched.item_id && formik.errors.item_id ? 'is-invalid' : ''}`}
                                    />
                                </Col>
                                <Col md="2">
                                    <Form.Label htmlFor="validationCustom01" className="h6">
                                        Unit
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="unit"
                                        disabled={true}
                                        value={formik.values.unit}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        isInvalid={formik.touched.unit && !!formik.errors.unit}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {formik.errors.unit}
                                    </Form.Control.Feedback>
                                </Col>
                                <Col md="3">
                                    <Form.Label htmlFor="validationCustom01" className="h6">
                                        Close Amount
                                    </Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="close_amount"
                                        value={formik.values.close_amount}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        isInvalid={formik.touched.close_amount && !!formik.errors.close_amount}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {formik.errors.close_amount}
                                    </Form.Control.Feedback>
                                </Col>
                                <Col md="3">
                                    <Form.Label htmlFor="validationCustom01" className="h6">
                                        Transfered Amount
                                    </Form.Label>
                                    <Form.Control
                                        type="number"
                                        disabled={showTransferable ? false : true}
                                        name="trans_to_shift"
                                        value={formik.values.trans_to_shift}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        isInvalid={formik.touched.trans_to_shift && !!formik.errors.trans_to_shift}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {formik.errors.trans_to_shift}
                                    </Form.Control.Feedback>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col md="12" className="d-flex justify-content-start">
                                    <Button type="submit">Save</Button>
                                </Col>
                            </Row>
                        </Form>

                    } //Apply conditional style
                />
            </Col>
        </Fragment>
    );
};

export default StockCloseDetail;
