import * as Yup from 'yup';
const stsaleCloseDetailUpdateSchema = Yup.object({
    staff_id: Yup.string().required('Staff ID is required').max(255, 'Staff ID cannot exceed 255 characters'),
    job_location: Yup.string().required('Job Location is required').max(255, 'Job Location cannot exceed 255 characters'),
    cash: Yup.number().required('Cash is required').min(0, 'Cash cannot be less than 0').typeError('Cash must be a number'),
    e_card: Yup.number().required('E-Card is required').min(0, 'E-Card cannot be less than 0').typeError('E-Card must be a number'),
    qr_pay: Yup.number().required('QR Pay is required').min(0, 'QR Pay cannot be less than 0').typeError('QR Pay must be a number'),
    fone_pe: Yup.number().required('Fone Pe is required').min(0, 'Fone Pe cannot be less than 0').typeError('Fone Pe must be a number'),
    online: Yup.number().required('Online is required').min(0, 'Online cannot be less than 0').typeError('Online must be a number'),
    comp: Yup.number().required('Comp is required').min(0, 'Comp cannot be less than 0').typeError('Comp must be a number'),
    refund: Yup.number().required('Refund is required').min(0, 'Refund cannot be less than 0').typeError('Refund must be a number'),
});

export default stsaleCloseDetailUpdateSchema;