import axiosClient from "../axios";

class ItemChallanService {
    storeAllOut(values) {
        return axiosClient.post('/item_challans/store_all_out', values);
    }
    storeAllIn(values) {
        return axiosClient.post('/item_challans/store_all_in', values);
    }
    lists() {
        return axiosClient.get('/item_challans/list');
    }
    //to get all data
    view(page, rowsPerPage) {
        return axiosClient.get(`/item_challans?page=${page}&pp=${rowsPerPage}`);
    }
    //to store data
    store(values) {
        return axiosClient.post("/item_challans", values);
    }
    //to update data
    update(uuid, values) {
        return axiosClient.put(`/item_challans/${uuid}`, values);
    }
    // to simple search 
    search(url) {
        return axiosClient.get(`/item_challans?${url}`);
    }
    //to search single data
    singleView(uuid) {
        return axiosClient.get(`/item_challans/${uuid}`);
    }
    //to delete single data
    delete(uuid) {
        return axiosClient.delete("/item_challans/" + uuid, { transaction_id: uuid });
    }
    //to restore single data
    restore(uuid) {
        return axiosClient.delete(`/item_challans/${uuid}/restore`);
    }
    //to prune single data
    prune(uuid) {
        return axiosClient.delete(`/item_challans/${uuid}/prune`);
    }
    //to delete selected row data
    deleteAll(selectedRowsUid) {
        return axiosClient.post("/item_challans/deleteall", { 'ids': selectedRowsUid });
    }
    //to restore selected row data
    restoreAll(selectedRowsUUID) {
        return axiosClient.post("/item_challans/restoreall", { 'ids': selectedRowsUUID });
    }
    //to prune selected row data
    pruneAll(selectedRowsUUID) {
        return axiosClient.post("/item_challans/pruneall", { 'ids': selectedRowsUUID });
    }
    //to get deleted data
    viewDeleted() {
        return axiosClient.get("/item_challans");
    }
}
export default new ItemChallanService();