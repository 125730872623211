import axiosClient from "../axios";

class EmployeeService {
    //approval of employee
    approvalEmployee(values) {
        return axiosClient.post('/employees/approval', values);
    }
    //inital data for attribute list
    employeeInitalData() {
        return axiosClient.get('/employees/intial_data');
    }
    employeeDocInitalData() {
        return axiosClient.get('/employees/docs/intial_data');
    }
    lists() {
        return axiosClient.get('/employees/list');
    }
    //to get all data
    view(page, rowsPerPage) {
        return axiosClient.get(`/employees?page=${page}&pp=${rowsPerPage}`);
    }
    //to store data
    store(values) {
        return axiosClient.post("/employees", values);
    }
    //to update data
    update(uuid, values) {
        return axiosClient.put(`/employees/${uuid}`, values);
    }
    // to simple search 
    search(url) {
        return axiosClient.get(`/employees?${url}`);
    }
    //to search single data
    singleView(uuid) {
        return axiosClient.get(`/employees/${uuid}`);
    }
    //to delete single data
    delete(uuid) {
        return axiosClient.delete("/employees/" + uuid, { employee_id: uuid });
    }
    //to restore single data
    restore(uuid) {
        return axiosClient.delete(`/employees/${uuid}/restore`);
    }
    //to prune single data
    prune(uuid) {
        return axiosClient.delete(`/employees/${uuid}/prune`);
    }
    //to delete selected row data
    deleteAll(selectedRowsUid) {
        return axiosClient.post("/employees/deleteall", { 'ids': selectedRowsUid });
    }
    //to restore selected row data
    restoreAll(selectedRowsUUID) {
        return axiosClient.post("/employees/restoreall", { 'ids': selectedRowsUUID });
    }
    //to prune selected row data
    pruneAll(selectedRowsUUID) {
        return axiosClient.post("/employees/pruneall", { 'ids': selectedRowsUUID });
    }
    //to get deleted data
    viewDeleted() {
        return axiosClient.get("/employees");
    }
}
export default new EmployeeService();