import React, { memo, Fragment, useState, useEffect } from "react";
import { BsDatabaseFillX } from "react-icons/bs";
import { AiFillDelete, AiFillPlusCircle, AiFillFunnelPlot } from "react-icons/ai";
import { FaSearch } from "react-icons/fa";
import { LuDatabaseBackup } from "react-icons/lu";
// sweetalert
import Swal from "sweetalert2";
//react-bootstrap
import { HiMiniViewfinderCircle } from "react-icons/hi2";
import { Col, Card, Button, Form, Row } from "react-bootstrap";
//components
import { useStateContext } from "../../context/ContextProvider";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import TableIcons from "../../components/TableIcons";
import purchaseEntryService from "../../services/purchase-entry.service";
import PurchaseEntryFilter from "./bill-mapping-filter";
import { usePermissionContext } from "../../context/PremissionContext";
import billMappingService from "../../services/bill-mapping.service";

const BillMapping = memo(() => {
  const navigate = useNavigate();

  const { permissions } = usePermissionContext();

  const createPermission = permissions.some(permission => permission.name === 'Create Purchase Entry');
  const updatePermission = permissions.some(permission => permission.name === 'Update Purchase Entry');
  const viewPermission = permissions.some(permission => permission.name === 'View Purchase Entry');
  const deletePermission = permissions.some(permission => permission.name === 'Delete Purchase Entry');
  const restorePermission = permissions.some(permission => permission.name === 'Restore Purchase Entry');
  const purgePermission = permissions.some(permission => permission.name === 'Purge Purchase Entry');

  const { dtColorMode, showToast, setPageTitle, setIsLoading } = useStateContext();
  // Filter parameters state
  const [searchFilter, setSearchFilter] = useState(
    JSON.parse(sessionStorage.getItem("purchase_entry_filter")) || {
      invoice_no: "",
      challan_no: "",
      invoice_date: [],
      vendor_id: [],
      entry_date: [],
      status_id: [],
      dataStatus: "Only Live",
      searchKey: "",
    }
  );
  const filterQueryMapping = {
    invoice_no: "like",
    category_id: "in",
    entry_date: "between",
    invoice_date: "between",
    status: "in",
    dataStatus: "is_del",
    searchKey: "",
  };

  // Data for the datatable
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [searchType, setSearchType] = useState([]);
  // State to manage the show/hide columns
  const [hideColumns, setHideColumns] = useState({
    invoice_no: false,
    challan_no: false,
    invoice_date: false,
    vendor_id: false,
    entry_date: false,
    status: false,
    actions: false,
  });
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const handleOffcanvasToggle = () => {
    setShowOffcanvas(!showOffcanvas);
  }

  // Datatable columns
  const tableColumns = React.useMemo(
    () => [
      {
        id: "sno",
        name: "SNo",
        cell: (row, index) => index + 1,
      },
      {
        id: "warehouse_id",
        name: "Warehouse Name",
        selector: (row) => row.warehouse.warehouse_name,
        sortable: true,
        omit: hideColumns.warehouse_id,
      },
      {
        id: "invoice_no",
        name: "Invoice Number",
        selector: (row) => row.invoice_no,
        sortable: true,
        omit: hideColumns.invoice_no,
      },
      {
        id: "challan_no",
        name: "Challan Number",
        selector: (row) => row.challan_no,
        sortable: true,
        omit: hideColumns.challan_no,
      },
      {
        id: "vendor_id",
        name: "Vendor Name",
        selector: (row) => {
          if (row.vendor && row.vendor.vendor_name) {
            return row.vendor.vendor_name;
          } else {
            return '';
          }
        },
        sortable: true,
        omit: hideColumns.vendor_id,
      },
      {
        id: "invoice_date",
        name: "Invoice Date",
        selector: (row) => row.invoice_date,
        sortable: true,
        omit: hideColumns.invoice_date,
      },
      {
        id: "entry_date",
        name: "Entry Date",
        selector: (row) => row.entry_date,
        sortable: true,
        omit: hideColumns.entry_date,
      },
      //TODO
      {
        id: "status",
        name: "Purchase Entry Status",
        selector: (row) => row.status,
        sortable: true,
        omit: hideColumns.status,
        cell: (row) => (
          <span className={`badge rounded-pill bg-${row.status.some(status => status.status === "Pending") ? "danger" : "success"} p-2 text-data.statusColor`}>
            {row.status.some(status => status.status === "Pending") && (
              <>
                {row.status.find(status => status.status === "Pending").approval_name}: Pending
              </>
            )}
            {!row.status.some(status => status.status === "Pending") && (
              <>
                {row.status[row.status.length - 1].approval_name}: {row.status[row.status.length - 1].status}
              </>
            )}
          </span>

        ),
      },
    ],
    [hideColumns],
  );
  // Function to handle column visibility
  const handleHideColumnsChange = (columnId, value) => {
    setHideColumns({
      ...hideColumns,
      [columnId]: !value
    });
  };

  // Data filter and search related functions
  // Get query string for DataStatus
  const getDataStatusQuery = (e) => {
    let is_del_value = `is_del=${searchFilter.dataStatus === "Only Live"
      ? 0
      : searchFilter.dataStatus === "Deleted Only"
        ? 1
        : 2
      }`;
    return is_del_value;
  };

  const getFilterQueryString = (key, value, index) => {
    const mapping = filterQueryMapping[key];
    if (mapping === "like") {
      const values = Array.isArray(value) ? value.join(",") : value;
      return `${mapping}[${index}]=${key},${values}`;
    } else if (mapping === "in") {
      const values = value.map((item) => item.value).join(",");
      return `${mapping}=${key},${values}`;
    } else if (mapping === "between") {
      const values = value.map((item) => item.value).join(",");
      return `${mapping}=${key},${value}`;
    }
  }

  const getSearchFilterQuery = () => {
    const queryParams = [];
    let index = 0;

    for (const key in searchFilter) {
      if (key == 'dataStatus') continue;
      let value = searchFilter[key];
      if (key == 'searchKey' && value !== "") {
        // remove white spaces in the searchKey
        value = value.replace(/\s/g, '');
        // create the query string over here
        return `&like[0]=invoice_no,${value}&like[1]=status,${value}`
      }
      // Check if the value is defined and not an empty array
      if (value && (Array.isArray(value) ? value.length > 0 : true)) {
        queryParams.push(getFilterQueryString(key, value, index));
        if (filterQueryMapping[key] === "like") {
          ++index;
        }
      }
    }
    if (queryParams.length)
      return "&" + `${queryParams.join("&")}`;
    return "";
  };

  const getDataList = (page, rowsPerPage) => {
    setIsLoading(true);
    let url = `page=${page}&pp=${rowsPerPage}&${getDataStatusQuery()}${getSearchFilterQuery()}`;
    billMappingService
      .search(url)
      .then((response) => {
        setData(response.data.data);
        setTotalRows(response.data.meta.total);
        setIsLoading(false);
      })
      .catch((error) => {
        showToast(error.response.data.message, "error");
        setIsLoading(false);
      });
  }
  // Upd ate this function to handle the switching between searchKey and search filter parameters
  const handleSearchFilter = (value) => {
    // when keysearch is pressed, set the searchKey and clean others
    // when filtersearch is pressed, clear the search key and set others
    if (value === "keySearch") {
      setSearchFilter(
        {
          ...searchFilter,
          invoice_no: "",
          category_id: [],
          status: []
        }
      );
    } else {
      setSearchFilter({
        ...searchFilter,
        searchKey: "",
      });
    }
    sessionStorage.setItem("purchase_entry_filter", JSON.stringify(searchFilter));
    getDataList(1, perPage);
  };

  const conditionalRowStyles = [
    {
      when: (row) => row.deleted_at,
      style: {
        backgroundColor: "#FFCCCB",
      },
    },
    //we can add more conditions with style
  ];

  const handleInputChange = (name, value) => {
    setSearchFilter({
      ...searchFilter,
      [name]: value,
    });
  };

  const handleExport = () => {
    setIsLoading(true);
    showToast("Data exported successfully", "success");
    setIsLoading(false);
  };

  const [selectedRows, setSelectedRows] = useState([]);

  // This function handle on select
  const handleOnSelect = ({ selectedRows }) => {
    setSelectedRows(selectedRows);
  };

  const selectedRowsUid = [];
  const selectedRowsData = [];
  selectedRows.map((row) => {
    selectedRowsData.push(row);
    selectedRowsUid.push(row.entry_id);
  });

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearchFilter("keySearch");
    }
  }

  const [invoiceNumber, setInvoiceNumber] = useState();

  const handleFormSubmit = (event) => {
    event.preventDefault();
    Swal.fire({
      title: "Are you sure you want to bill map?",
      icon: "info",
      showCancelButton: true,
      backdrop: `rgba(60,60,60,0.8)`,
      confirmButtonText: "Yes,Update it!",
    }).then((result) => {
      if (result.value) {
        setIsLoading(true);
        billMappingService.store(
          {
            'invoice_no': invoiceNumber,
            'ids': selectedRowsUid
          }
        ).then((response) => {
          setIsLoading(false);
          getDataList(currentPage, perPage);
          Swal.fire("Bill Mapping done", "The selected purchase entry has been updated.", "success");
        })
          .catch((error) => {
            setIsLoading(false);
            showToast(error.response.data.message, "error");
          });
        setIsLoading(false);
      }
    });
  }

  useEffect(() => {
    getDataList(currentPage, perPage);
    setPageTitle('Bill Mapping');
  }, [currentPage, perPage]);


  return (
    <>
      <Fragment>
        {selectedRowsData.length > 0 && (
          <Card>
            <Card.Body>
              <Form onSubmit={handleFormSubmit}>
                <Row className="mb-3">
                  <Col md="4">
                    <Form.Label>Invoice Number</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter invoice number"
                      value={invoiceNumber}
                      onChange={(e) => setInvoiceNumber(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          handleFormSubmit(e);
                        }
                      }}
                      required
                    />
                  </Col>
                  <Col className="d-flex align-items-end">
                    <Button variant="primary" type="submit">
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Form>

              {selectedRowsData && selectedRowsData.map((index) => (
                <>
                  <h5>Selected Row Details</h5>
                  <Row>
                    <p className="col-md-4">Vendor Name: {index.vendor ? index.vendor.vendor_name : ""}</p>
                    <p className="col-md-4">Invoice Date: {index.invoice_date}</p>
                    <p className="col-md-4">Challan Number: {index.challan_no}</p>
                  </Row>
                  <hr />
                </>
              ))}
            </Card.Body>
          </Card>
        )}
        <Card>
          <Card.Header className="d-flex justify-content-between">
            <div className="header-title">
              <h4 className="card-title">Bill Mapping List</h4>
            </div>
          </Card.Header>
          <Card.Body>
            <div
            // className="custom-table-effect table-responsive mt-3"
            >
              <DataTable
                columns={tableColumns}
                data={data}
                pagination
                paginationServer
                debugger
                paginationPerPage={perPage}
                paginationComponentOptions={{
                  selectAllRowsItem: true,
                  selectAllRowsItemText: "All",
                }}
                paginationRowsPerPageOptions={[10, 20, 30, 50]}
                paginationServerPage={currentPage}
                paginationTotalRows={totalRows}
                onChangePage={(page) => {
                  setCurrentPage(page);
                }}
                onChangeRowsPerPage={(newPerPage, page) => {
                  setPerPage(newPerPage === "All" ? data.length : newPerPage);
                  setCurrentPage(page);
                }}
                selectableRows
                className=""
                theme={dtColorMode == "dark" ? "dark" : ""}
                selectableRowsHighlight
                highlightOnHover
                onSelectedRowsChange={handleOnSelect}
                fixedHeader
                conditionalRowStyles={conditionalRowStyles} //Apply conditional style
                subHeader
                subHeaderComponent={
                  <>
                    <Col md="12">
                      <div className="d-flex align-items-center justify-content-between gap-2 flex-wrap">
                        <div className="d-flex gap-2 w-50 flex-wrap col-md-3 mb-3">
                          <Form
                            onKeyPress={handleKeyPress}
                            className="d-flex gap-2 w-50 flex-wrap col-md-3 mb-3"
                          >
                            <Form.Control
                              name="searchKey"
                              style={{ width: "200px" }}
                              type="text"
                              placeholder="Search"
                              value={searchFilter.searchKey}
                              onChange={(e) => {
                                handleInputChange(e.target.name, e.target.value);
                              }}
                            />
                            <a className="btn btn-sm btn-outline-secondary d-flex p-2"
                              onClick={() => handleSearchFilter("keySearch")}
                            >
                              <FaSearch style={{ fontSize: "1.3rem" }} />
                            </a>
                            <a className="btn btn-sm btn-outline-secondary d-flex p-2"
                              onClick={() => setShowOffcanvas(!showOffcanvas)} // Toggle the Offcanvas
                              data-bs-toggle="offcanvas"
                              data-bs-target="#offcanvasRight"
                              aria-controls="offcanvasRight"
                            >
                              <AiFillFunnelPlot
                                style={{ fontSize: "1.3rem" }}
                              />
                            </a>
                          </Form>
                        </div>
                        <PurchaseEntryFilter tableColumns={tableColumns} showOffcanvas={showOffcanvas} searchFilter={searchFilter} handleOffcanvasToggle={handleOffcanvasToggle} handleHideColumnsChange={handleHideColumnsChange} handleInputChange={handleInputChange} handleSearchFilter={handleSearchFilter} />
                      </div>
                    </Col>
                  </>
                }
              />
            </div>
          </Card.Body>
        </Card>
      </Fragment>
    </>
  );
});

BillMapping.displayName = "BillMapping";
export default BillMapping;
