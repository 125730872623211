import axiosClient from "../axios";
import axiosFile from "../axios-file";

class PurchaseEntryService {
    approve(values) {
        return axiosClient.post('/purchase_entries/status/approve', values);
    }
    lists() {
        return axiosClient.get('/purchase_entries/list');
    }
    //to get all data
    view(page, rowsPerPage) {
        return axiosClient.get(`/purchase_entries?page=${page}&pp=${rowsPerPage}`);
    }
    //to store data
    store(values) {
        return axiosFile.post("/purchase_entries", values);
    }
    //to update data
    update(uuid, values) {
        return axiosFile.put(`/purchase_entries/${uuid}`, values);
    }
    // to simple search 
    search(url) {
        return axiosClient.get(`/purchase_entries?${url}`);
    }
    //to search single data
    singleView(uuid) {
        return axiosClient.get(`/purchase_entries/${uuid}`);
    }
    //to delete single data
    delete(uuid) {
        return axiosClient.delete("/purchase_entries/" + uuid, { entry_id: uuid });
    }
    //to restore single data
    restore(uuid) {
        return axiosClient.delete(`/purchase_entries/${uuid}/restore`);
    }
    //to prune single data
    prune(uuid) {
        return axiosClient.delete(`/purchase_entries/${uuid}/prune`);
    }
    //to delete selected row data
    deleteAll(selectedRowsUid) {
        return axiosClient.post("/purchase_entries/deleteall", { 'ids': selectedRowsUid });
    }
    //to restore selected row data
    restoreAll(selectedRowsUUID) {
        return axiosClient.post("/purchase_entries/restoreall", { 'ids': selectedRowsUUID });
    }
    //to prune selected row data
    pruneAll(selectedRowsUUID) {
        return axiosClient.post("/purchase_entries/pruneall", { 'ids': selectedRowsUUID });
    }
    //to get deleted data
    viewDeleted() {
        return axiosClient.get("/purchase_entries");
    }
}
export default new PurchaseEntryService();