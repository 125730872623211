import React, { useState, useEffect } from "react";
import { Button, Card, Col, Container, Offcanvas, Row } from "react-bootstrap";
import { useStateContext } from "../../../context/ContextProvider";
import stsaleCloseDetailService from "../../../services/stsale-close-detail.service";

export default function ViewStsaleDetail({ setShowView, showView, id, type }) {
    const { setPageTitle, showToast } = useStateContext();
    const [data, setData] = useState();

    const displayError = (error) => {
        if (error && typeof error === "object") {
            let errArr = Object.keys(error).map((key) => {
                return error[key].join(",");
            });
            errArr.map((column) => showToast(column, "error"));
        } else {
            showToast("An unexpected error occurred.", "error");
        }
    };

    const getData = () => {
        stsaleCloseDetailService
            .singleView(id, type)
            .then((response) => {
                setData(response.data.data);
            })
            .catch((error) => {
                displayError(error.response.data.errors);
            });
    };
    useEffect(() => {
        setPageTitle("Staff Closes");
        getData();
    }, [setPageTitle, id]);
    return (
        <Container>
            {data ? (
                <Offcanvas
                    show={showView} // Control Offcanvas visibility
                    onHide={() => setShowView(false)} // Close Offcanvas when clicking the backdrop
                    placement="end"
                    target="offcanvasRight"
                    tabindex="-1"
                    id="offcanvasRight"
                    aria-labelledby="offcanvasRightLabel"
                >
                    <Offcanvas.Header class="offcanvas-header">
                        <Offcanvas.Title id="offcanvasRightLabel">
                            Staff Close Details
                        </Offcanvas.Title>
                        <button
                            type="button"
                            class="btn-close"
                            onClick={() => {
                                setShowView(false);
                            }}
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                        ></button>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Row className="mb-3">
                            <Row className="mb-2">
                                <h3> About {data.staff !== null ? `${data.staff.first_name}'s Sales` : "Sale Detail"} </h3>
                                <hr className="my-3" />
                                <Col md="12">
                                    <h6 className="mb-1" style={{ width: "280px" }}>
                                        Staff Name:
                                    </h6>
                                    <p>{data.staff !== null ? `${data.staff.first_name}` : ""}</p>
                                </Col>
                                <Col md="12">
                                    <h6 className="mb-1" style={{ width: "280px" }}>
                                        Job Location:
                                    </h6>
                                    <p>{data.job_location}</p>
                                </Col>
                                <Col md="12">
                                    <h6 className="mb-1" style={{ width: "280px" }}>
                                        Cash:
                                    </h6>
                                    <p>{data.cash}</p>
                                </Col>
                                <Col md="12">
                                    <h6 className="mb-1" style={{ width: "280px" }}>
                                        E-Card:
                                    </h6>
                                    <p>{data.e_card}</p>
                                </Col>
                                <Col md="12">
                                    <h6 className="mb-1" style={{ width: "280px" }}>
                                        QR Pay:
                                    </h6>
                                    <p>{data.qr_pay}</p>
                                </Col>
                                <Col md="12">
                                    <h6 className="mb-1" style={{ width: "280px" }}>
                                        FonePay:
                                    </h6>
                                    <p>{data.fone_pe}</p>
                                </Col>
                                <Col md="12">
                                    <h6 className="mb-1" style={{ width: "280px" }}>
                                        Online:
                                    </h6>
                                    <p>{data.online}</p>
                                </Col>
                                <Col md="12">
                                    <h6 className="mb-1" style={{ width: "280px" }}>
                                        Comp:
                                    </h6>
                                    <p>{data.refund}</p>
                                </Col>
                                <Col md="12">
                                    <h6 className="mb-1" style={{ width: "280px" }}>
                                        Refund:
                                    </h6>
                                    <p>{data.refund}</p>
                                </Col>
                                <Col md="12">
                                    <h6 className="mb-1" style={{ width: "280px" }}>
                                        Total Amount:
                                    </h6>
                                    <p>{data.total_amount}</p>
                                </Col>
                            </Row>
                        </Row>
                    </Offcanvas.Body>
                    <Button
                        variant="outline-warning"
                        className="btn  m-3"
                        onClick={() => {
                            setShowView(false);
                        }}
                    >
                        back
                    </Button>
                </Offcanvas>
            ) : null}
        </Container>
    );
}
