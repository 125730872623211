import axiosClient from "../axios";

class WarehouseService {
    lists(){
        return axiosClient.get('/warehouses/list');
    }
    //to get all data
    view(page, rowsPerPage){
        return axiosClient.get(`/warehouses?page=${page}&pp=${rowsPerPage}`);
    }
    //to store data
    store(values) {
        return axiosClient.post("/warehouses", values);
    }
    //to update data
    update(uuid, values) {
        return axiosClient.put(`/warehouses/${uuid}`, values);
    }
    // to simple search 
    search(url) {
        return axiosClient.get(`/warehouses?${url}`);
    }
    //to search single data
    singleView(uuid) {
        return axiosClient.get(`/warehouses/${uuid}`);
    }
    //to delete single data
    delete(uuid) {
        return axiosClient.delete("/warehouses/" + uuid, { warehouse_id: uuid });
    }
    //to restore single data
    restore(uuid) {
        return axiosClient.delete(`/warehouses/${uuid}/restore`);
    }
    //to prune single data
    prune(uuid) {
        return axiosClient.delete(`/warehouses/${uuid}/prune`);
    }
    //to delete selected row data
    deleteAll(selectedRowsUid) {
        return axiosClient.post("/warehouses/deleteall" , { 'ids': selectedRowsUid });
    }
    //to restore selected row data
    restoreAll(selectedRowsUUID) {
        return axiosClient.post("/warehouses/restoreall", {'ids': selectedRowsUUID});
    }
    //to prune selected row data
    pruneAll(selectedRowsUUID) {
        return axiosClient.post("/warehouses/pruneall",{'ids': selectedRowsUUID});
    }
    //to get deleted data
    viewDeleted(){
        return axiosClient.get("/warehouses");
    }
}
export default new WarehouseService();