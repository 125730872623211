import axiosClient from "../axios";

class ApprovalService {
    lists() {
        return axiosClient.get('/approvals/list');
    }
    childrenList(values) {
        return axiosClient.post('/approvals/children_list', values);
    }
    //to get all data
    view(page, rowsPerPage) {
        return axiosClient.get(`/approvals?page=${page}&pp=${rowsPerPage}`);
    }
    //to store data
    store(values) {
        return axiosClient.post("/approvals", values);
    }
    //to update data
    update(uuid, values) {
        return axiosClient.put(`/approvals/${uuid}`, values);
    }
    // to simple search 
    search(url) {
        return axiosClient.get(`/approvals?${url}`);
    }
    //to search single data
    singleView(uuid) {
        return axiosClient.get(`/approvals/${uuid}`);
    }
    //to delete single data
    delete(uuid) {
        return axiosClient.delete("/approvals/" + uuid, { approval_id: uuid });
    }
    //to restore single data
    restore(uuid) {
        return axiosClient.delete(`/approvals/${uuid}/restore`);
    }
    //to prune single data
    prune(uuid) {
        return axiosClient.delete(`/approvals/${uuid}/prune`);
    }
    //to delete selected row data
    deleteAll(selectedRowsUid) {
        return axiosClient.post("/approvals/deleteall", { 'ids': selectedRowsUid });
    }
    //to restore selected row data
    restoreAll(selectedRowsUUID) {
        return axiosClient.post("/approvals/restoreall", { 'ids': selectedRowsUUID });
    }
    //to prune selected row data
    pruneAll(selectedRowsUUID) {
        return axiosClient.post("/approvals/pruneall", { 'ids': selectedRowsUUID });
    }
    //to get deleted data
    viewDeleted() {
        return axiosClient.get("/approvals");
    }
}
export default new ApprovalService();