import { Fragment, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import axiosFile from "../../axios-file"; // Ensure axiosFile has the base URL and timeout set

const Upload = () => {
    const [file, setFile] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent the default form submission

        if (!file) {
            setError("Please select a file to upload");
            return;
        }

        const formData = new FormData();
        formData.append("file", file);

        try {
            setIsLoading(true);
            setError(null);
            await axiosFile.post("/upload", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                timeout: 300000, // 5 minutes timeout
            });
            setIsLoading(false);
            alert("File uploaded successfully");
        } catch (err) {
            setIsLoading(false);
            setError("File upload failed. Please try again.");
        }
    };

    return (
        <Fragment>
            <Card>
                <Card.Body>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group>
                            <Form.Control
                                type="file"
                                onChange={(e) => setFile(e.target.files[0])}
                            />
                        </Form.Group>
                        {error && <p style={{ color: 'red' }}>{error}</p>}
                        <Button type="submit" disabled={isLoading}>
                            {isLoading ? "Uploading..." : "Save"}
                        </Button>
                    </Form>
                </Card.Body>
            </Card>
        </Fragment>
    );
}

export default Upload;
