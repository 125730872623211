import { useFormik } from "formik";
import React, { useState, useEffect, Fragment } from "react";
import {
    Button,
    Card,
    Col,
    Container,
    Form,
    FormCheck,
    Offcanvas,
    Row,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import TableIcons from "../../../components/TableIcons";
import Swal from "sweetalert2";
import { AiFillPlusCircle } from "react-icons/ai";
import DataTable from "react-data-table-component";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { HiMiniViewfinderCircle } from "react-icons/hi2";
import { useStateContext } from "../../../context/ContextProvider";
import coffeeCloseDetailService from "../../../services/coffee-close-detail.service";
import AddCoffeeCloseDetail from "./add-coffee-close-detail";
import { usePermissionContext } from "../../../context/PremissionContext";

const CoffeeCloseDetail = ({ close_id }) => {
    const { id, type } = useParams();
    const navigate = useNavigate();

    const { permissions } = usePermissionContext();

    const createPermission = permissions.some(permission => permission.name === 'Create Mor Coffee Close Detail');
    const updatePermission = permissions.some(permission => permission.name === 'Update Mor Coffee Close Detail');
    const viewPermission = permissions.some(permission => permission.name === 'View Mor Coffee Close Detail');
    const deletePermission = permissions.some(permission => permission.name === 'Delete Mor Coffee Close Detail');
    const restorePermission = permissions.some(permission => permission.name === 'Restore Mor Coffee Close Detail');
    const purgePermission = permissions.some(permission => permission.name === 'Purge Mor Coffee Close Detail');


    if (type === "mor") {
        const createPermission = permissions.some(permission => permission.name === 'Create Mor Coffee Close Detail');
        const updatePermission = permissions.some(permission => permission.name === 'Update Mor Coffee Close Detail');
        const viewPermission = permissions.some(permission => permission.name === 'View Mor Coffee Close Detail');
        const deletePermission = permissions.some(permission => permission.name === 'Delete Mor Coffee Close Detail');
        const restorePermission = permissions.some(permission => permission.name === 'Restore Mor Coffee Close Detail');
        const purgePermission = permissions.some(permission => permission.name === 'Purge Mor Coffee Close Detail');
    }
    if (type === "even") {
        const createPermission = permissions.some(permission => permission.name === 'Create Even Coffee Close Detail');
        const updatePermission = permissions.some(permission => permission.name === 'Update Even Coffee Close Detail');
        const viewPermission = permissions.some(permission => permission.name === 'View Even Coffee Close Detail');
        const deletePermission = permissions.some(permission => permission.name === 'Delete Even Coffee Close Detail');
        const restorePermission = permissions.some(permission => permission.name === 'Restore Even Coffee Close Detail');
        const purgePermission = permissions.some(permission => permission.name === 'Purge Even Coffee Close Detail');
    }

    const {
        dtColorMode,
        setIsLoading,
        setPageTitle,
        showToast,
        setRefereshData,
        refereshData,
    } = useStateContext();

    /**
     * The function `displayError` takes an error object as input and displays each error message as a
     * toast notification.
     * @param error - The `error` parameter is an object that contains error messages for different
     * columns. Each key in the `error` object represents a column, and the corresponding value is an
     * array of error messages for that column.
     */
    const displayError = (error) => {
        let errArr = [];
        errArr = Object.keys(error).map((key) => {
            return error[key].join(",");
        });
        errArr.map((column) => showToast(column, "error"));
    };
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [totalRows, setTotalRows] = useState(0);
    // Datatable columns

    const getDataList = (page, rowsPerPage) => {
        setIsLoading(true);
        let url = `?in=close_id,${close_id}`;
        coffeeCloseDetailService
            .search(url, type)
            .then((response) => {
                if (response.data && response.data.data) {
                    setData(response.data.data);
                    setTotalRows(response.data.meta.total);
                } else {
                    showToast("Invalid data format in the API response", "error");
                }
                setIsLoading(false);
            })
            .catch((error) => {
                showToast(
                    error.response?.data?.message || "Error fetching data",
                    "error"
                );
                setIsLoading(false);
            });
    };

    // handle delete function
    const handleDelete = (uuid) => {
        Swal.fire({
            title: "Are you sure you want to delete the coffee close detail?",
            text: "You might not be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            backdrop: `rgba(60,60,60,0.8)`,
            confirmButtonText: "Yes, Delete it!",
        }).then((result) => {
            if (result.value) {
                setIsLoading(true);
                coffeeCloseDetailService
                    .delete(uuid, type)
                    .then((response) => {
                        setIsLoading(false);
                        getDataList(currentPage, perPage);
                        Swal.fire("Coffee Close Detail Deleted", "The selected detail has been marked deleted.", "success");
                    })
                    .catch((error) => {
                        setIsLoading(false);
                        displayError(error.response.data.errors);
                    });
                setIsLoading(false);
            }
        });
    };
    const showReferesh = () => {
        if (refereshData) {
            getDataList(currentPage, perPage);
            setRefereshData(false);
        }
    };
    useEffect(() => {
        getDataList(currentPage, perPage);
        showReferesh();
    }, [currentPage, perPage, refereshData, close_id]);

    const tableColumns = React.useMemo(
        () => [
            {
                id: "item_id",
                name: "Raw Material Name",
                selector: (row) => row.item.raw_material_name,
                sortable: true,
            },
            {
                id: "open_amount",
                name: "Open Amount",
                selector: (row) => row.open_amount,
                sortable: true,
            },
            {
                id: "close_amount",
                name: "Close Amount",
                selector: (row) => row.close_amount,
                sortable: true,
            },
            {
                id: "status",
                name: "Detail Status",
                selector: (row) => row.status,
                sortable: true,
                cell: (row) => (
                    <span
                        className={`badge rounded-pill bg-${row.status === "Active" ? "success" : "danger"
                            } p-2 text-data.statusColor`}
                    >
                        {row.status}
                    </span>
                ),
            },
            {
                id: "actions",
                name: "Actions",
                cell: (row) => (
                    <div className="flex align-items-center list-user-action">
                        {updatePermission && (
                            <a
                                className="btn btn-sm btn-icon btn-primary mr-2"
                                style={{ marginRight: "5px" }}
                                data-toggle="tooltip"
                                data-placement="top"
                                title=""
                                data-original-title="Add"
                                to="#"
                                onClick={() => handleAdd(row.detail_id)}
                            >
                                <span className="btn-inner">{TableIcons.edit()}</span>
                            </a>
                        )}
                        {deletePermission && (
                            <a
                                className="btn btn-sm btn-icon btn-danger mr-2"
                                style={{ marginRight: "5px" }}
                                data-toggle="tooltip"
                                data-placement="top"
                                title=""
                                data-original-title="Add"
                                to="#"
                                onClick={() => handleDelete(row.detail_id)}
                            >
                                <span className="btn-inner">{TableIcons.remove()}</span>
                            </a>
                        )}
                        {viewPermission && (
                            <HiMiniViewfinderCircle
                                style={{ fontSize: "1.8rem", marginRight: "5px" }}
                                className="btn btn-sm btn-icon btn-warning mr-2"
                                onClick={() => handleView(row.detail_id)}
                            />
                        )}
                    </div>
                ),
            },
        ],
        [],
    );

    const [showView, setShowView] = useState(false);
    const [showAdd, setShowAdd] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [editId, setEditId] = useState();

    const handleAdd = (value) => {
        if (value === "") {
            setShowAdd(!showAdd);
        }
        else {
            setShowEdit(!showEdit);
            setEditId(value);
        }

    };

    const [viewId, setViewId] = useState();
    const handleView = (value) => {
        setShowView(!showView);
        setViewId(value);
    };

    return (
        <Fragment>
            {updatePermission && editId ? (
                <AddCoffeeCloseDetail
                    setShowAdd={setEditId}
                    showAdd={showEdit}
                    id={editId}
                    close_id={close_id}
                    type={type}
                />
            ) : null}
            {createPermission && showAdd ? (
                <AddCoffeeCloseDetail
                    setShowAdd={setShowAdd}
                    showAdd={showAdd}
                    close_id={close_id}
                    type={type}
                />
            ) : null}
            <Col md="12">
                <div className="d-flex align-items-center justify-content-between gap-2 flex-wrap">
                    <div className="d-flex gap-2 w-50 flex-wrap col-md-3 mb-3">
                        <h4>Coffee Closing Detail</h4>
                    </div>

                    <span>
                        {createPermission && (
                            <Button
                                variant="outline-primary"
                                onClick={() => {
                                    handleAdd("");
                                }}>
                                <AiFillPlusCircle style={{ fontSize: "1.3rem" }} />{" "}
                                Add New
                            </Button>
                        )}
                    </span>
                </div>
                <DataTable
                    columns={tableColumns}
                    data={data}
                    theme={dtColorMode == "dark" ? "dark" : ""}
                    selectableRowsHighlight
                    highlightOnHover
                    fixedHeader //Apply conditional style
                />
            </Col>
        </Fragment>
    );
};

export default CoffeeCloseDetail;
