import * as Yup from "yup";
const challanDetailInStoreSchema = Yup.object({
  item_id: Yup.string().required("Item is required."),
  quantity: Yup.number()
    .min(1, "Quantity must be at least 1.")
    .required("Quantity is required."),
  item_status: Yup.string().required("Status is required."),
});

export default challanDetailInStoreSchema;
