import { useFormik } from "formik";
import React, { useState, useEffect } from "react";
import {
    Button,
    Card,
    Col,
    Container,
    Form,
    Row,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useStateContext } from "../../context/ContextProvider";
import itemChallanUpdateSchema from "./item-challan-update-schema";
import itemChallanStoreSchema from "./item-challan-store-schema";
import { ADToBS } from "bikram-sambat-js";
import ItemChallanDetailIn from "./item-challan-in-detail/item-challan-detail-in";
import ItemChallanDetailOut from "./item-challan-out-detail/item-challan-detail-out";
import itemChallanService from "../../services/item-challan.service";
import employeeService from "../../services/employee.service";
import OpItemChallanOutDetail from "./op-item-challan-out-detail";
import OpItemChallanInDetail from "./op-item-challan-in-detail";

const OpAddItemChallan = () => {
    const currentURL = window.location.href;
    const navigate = useNavigate();
    const { type, id } = useParams();
    const { setIsLoading, setPageTitle, showToast } = useStateContext();

    useEffect(() => {
        setPageTitle("Transfer");
        setInitialData();
    }, [setPageTitle, id, currentURL]);

    const data = {
        challan_type: "Employee",
        shift_name: "",
        from_id: "",
        from_name: "",
        to_id: "",
        to_name: "",
        challan_date: "",
        np_challan_date: "",
        remarks: "",
        status: "",
        created_by: "",
        updated_by: "",
    };

    const displayError = (error) => {
        let errArr = [];
        errArr = Object.keys(error).map((key) => {
            return error[key].join(",");
        });
        errArr.forEach((column) => showToast(column, "error"));
    };

    const [enable, setEnable] = useState(false);
    const [selectedLabel, setSelectedLabel] = useState('');

    const handleSelectChange = (event) => {
        const selectedValue = event.target.value;
        const selectedOption = tos.find((tos) => tos.id === selectedValue);

        if (selectedOption) {
            formik.setFieldValue('to_id', selectedValue);
            formik.setFieldValue('to_name', selectedOption.text);
        } else {
            formik.setFieldValue('to_id', '');
            setSelectedLabel('');
        }
        formik.setFieldTouched('to_id', true);
    };

    const store = (values) => {
        setIsLoading(true);
        let updatedValue = {
            ...values,
            np_challan_date: ADToBS(formik.values.challan_date),
        };
        itemChallanService
            .store(updatedValue)
            .then((response) => {
                setIsLoading(false);
                if (type === "out") {
                    navigate(`/app/transfer/out/edit/${response.data.data.challan_id}`);
                }
                if (type === "in") {
                    navigate(`/app/transfer/in/edit/${response.data.data.challan_id}`);
                }
                showToast(`New Challan added.`, "success");
            })
            .catch((error) => {
                setIsLoading(false);
                displayError(error.response.data.errors);
            });
    };

    const submitForm = (values) => {
        store(values);
    };

    const handleChallanType = (value) => {
        formik.setValues({
            ...formik.values,
            challan_type: value,
        });
        getToList(value);
    };

    const [tos, setTos] = useState();

    const getToList = (value) => {
        setIsLoading(true);
        employeeService.lists()
            .then((response) => {
                setIsLoading(false);
                setTos(response.data.data);
            })
            .catch((error) => {
                setIsLoading(false);
                displayError(error.response.data.errors);
            });
    };

    const setInitialData = () => {
        if (id !== undefined) {
            setIsLoading(true);
            itemChallanService
                .singleView(id)
                .then((response) => {
                    if (response.status === 200) {
                        formik.setValues(response.data.data);
                    }
                })
                .catch((error) => {
                    showToast(error.response.data.message, "error");
                    navigate("/app/transfer");
                });
            setIsLoading(false);
        } else {
            formik.setValues(data);
        }
    };

    const formik = useFormik({
        initialValues: data,
        validationSchema: id !== undefined ? itemChallanUpdateSchema : itemChallanStoreSchema,
        onSubmit: submitForm,
    });

    return (
        <Container>
            <Card>
                <Card.Header className="d-flex justify-content-between">
                    <div className="header-title">
                        <h4 className="card-title">
                            {"Add Transfer"}
                        </h4>
                    </div>
                </Card.Header>
                <Card.Body>
                    <Form onSubmit={formik.handleSubmit}>
                        <Row className="mb-3">
                            <h5>Basic Information</h5>
                            <hr className="my-3" />
                            <Col md="6">
                                <Form.Label htmlFor="validationCustom01">
                                    {type === 'out' ? "Issue To " : "From"}
                                </Form.Label>
                                <Form.Select
                                    type="select"
                                    name="challan_type"
                                    value={formik.values.challan_type}
                                    onChange={(e) => handleChallanType(e.target.value)}
                                    onBlur={formik.handleBlur}
                                    isInvalid={
                                        formik.touched.challan_type && !!formik.errors.challan_type
                                    }
                                >
                                    <option defaultValue="">Select the option</option>
                                    <option value={type === "in" ? "emst" : type === "out" ? "stem" : ""}>Cafe</option>
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.challan_type}
                                </Form.Control.Feedback>
                            </Col>
                            {formik.values.challan_type && (
                                <Col md="6">
                                    <Form.Label htmlFor="validationCustom01">
                                        Employee
                                    </Form.Label>
                                    <Form.Select
                                        className="form-control"
                                        name="to_id"
                                        value={formik.values.to_id}
                                        onChange={handleSelectChange}
                                        onBlur={formik.handleBlur}
                                        isInvalid={
                                            formik.touched.to_id && !!formik.errors.to_id
                                        }
                                    >
                                        <option value="">Select</option>
                                        {tos ? (
                                            tos.map((tos) => (
                                                <option key={tos.id} value={tos.id}>
                                                    {tos.text}
                                                </option>
                                            ))
                                        ) : null}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {formik.errors.to_id}
                                    </Form.Control.Feedback>
                                </Col>
                            )}
                            <Col md="6">
                                <Form.Label htmlFor="validationCustom01">
                                    Shift Type
                                </Form.Label>
                                <Form.Select
                                    className="form-control"
                                    name="shift_name"
                                    value={formik.values.shift_name}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    isInvalid={
                                        formik.touched.shift_name && !!formik.errors.shift_name
                                    }
                                >
                                    <option value="">Select the shift</option>
                                    <option value="morning">Morning Shift</option>
                                    <option value="evening">Evening Shift</option>
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.shift_name}
                                </Form.Control.Feedback>
                            </Col>
                            <Col md="6">
                                <Form.Label htmlFor="validationCustom01">
                                    Date
                                </Form.Label>
                                <Form.Control
                                    type="date"
                                    name="challan_date"
                                    value={formik.values.challan_date}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    isInvalid={
                                        formik.touched.challan_date && !!formik.errors.challan_date
                                    }
                                />
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.challan_date}
                                </Form.Control.Feedback>
                            </Col>
                            <Col md="6">
                                <Form.Label htmlFor="validationCustom01">
                                    Purpose
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="remarks"
                                    value={formik.values.remarks}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    isInvalid={
                                        formik.touched.remarks && !!formik.errors.remarks
                                    }
                                />
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.remarks}
                                </Form.Control.Feedback>
                            </Col>
                            <Col md="6">
                                <Form.Label htmlFor="validationCustom01">
                                    Status
                                </Form.Label>
                                <Form.Select
                                    type="select"
                                    name="status"
                                    value={formik.values.status}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    isInvalid={
                                        formik.touched.status && !!formik.errors.status
                                    }
                                >
                                    <option defaultValue="">Select the option</option>
                                    <option value="new">New</option>
                                    {/* <option value="partly_settled">Partly Settled</option>
                                    <option value="settled">Settled</option> */}
                                    <option value="cancelled">Cancelled</option>
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.status}
                                </Form.Control.Feedback>
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>
            {formik.isValid && formik.touched && type !== "" ? (
                <>
                    {type === 'in' ? <OpItemChallanInDetail challan_data={formik.values} /> : type === 'out' ? <OpItemChallanOutDetail challan_data={formik.values} /> : <></>}
                </>
            ) : null}
        </Container>
    );
};

export default OpAddItemChallan;
