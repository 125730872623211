import * as Yup from 'yup';
const personalDetailStoreSchema = Yup.object({
    first_name: Yup.string().required('First Name is required'),
    middle_name: Yup.string(),
    last_name: Yup.string().required('Last Name is required'),
    father_name: Yup.string().required('Father Name is required'),
    mother_name: Yup.string().required('Mother Name is required'),
    brother_sister_name: Yup.string(),
    nominee_name: Yup.string().required('Nominee Name is required'),
    nominee_relation: Yup.string().required('Nominee Relation is required'),
    gender: Yup.string().required('Gender is required'),
    marital_status: Yup.string().required('Marital Status is required'),
    nationality: Yup.string().required('Nationality is required'),
    religion: Yup.string(),
    date_of_birth: Yup.date().required('Date of Birth is required'),
    date_of_celebration: Yup.date().required('Date of Celebration is required.'),
    email: Yup.string().email('Invalid Email Address').required('Email is required'),
    phone_number: Yup.string().required('Phone Number is required'),
    blood_group: Yup.string().required('Blood Group is required'),
    prem_street: Yup.string().required('Permanent Street is required'),
    prem_ward_no: Yup.string().required('Permanent Ward Number is required'),
    prem_city: Yup.string().required('Permanent City is required'),
    prem_province: Yup.string().required('Permanent Province is required'),
    temp_street: Yup.string().required('Temporary Street is required'),
    temp_ward_no: Yup.string().required('Temporary Ward Number is required'),
    temp_city: Yup.string().required('Temporary City is required'),
    temp_province: Yup.string().required('Temporary Province is required'),
    department_id: Yup.string().required('Department ID is required'),
    date_of_join: Yup.date().required('Date of joining is required'),
    outsource: Yup.string(),
    reference: Yup.string().required("Reference is required."),
});

export default personalDetailStoreSchema;