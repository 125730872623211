import React, { useEffect } from "react";
import { Button, Card, Col, Image, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FaBalanceScaleLeft, FaBoxOpen, FaCalendarDay, FaUser } from "react-icons/fa";
import { MdCalendarMonth, MdFileUpload, MdLibraryBooks, MdOutlineChatBubble, MdReportProblem } from "react-icons/md";
import { BiSolidCommentError } from "react-icons/bi";
import { GiCoffeeCup } from "react-icons/gi";
import { MdDelete } from "react-icons/md";
import MiniCard from "../../components/mini-card-comp";
import { useStateContext } from "../../context/ContextProvider";
import { LuPopcorn } from "react-icons/lu";
import axiosClient from "../../axios";
import { BsFillCalendarDateFill } from "react-icons/bs";
import { CiCalendarDate } from "react-icons/ci";
import { AiFillControl, AiFillReconciliation } from "react-icons/ai";
import { TfiShiftRight } from "react-icons/tfi";
import { usePermissionContext } from "../../context/PremissionContext";

const StoreReportDashboard = () => {

    const { permissions } = usePermissionContext();

    const shiftSummaryPermission = permissions.some(permission => permission.name === 'Shift Summary');
    const reconSummaryPermission = permissions.some(permission => permission.name === 'Recon Summary');
    const daySummaryPermission = permissions.some(permission => permission.name === 'Day Summary');
    const userWisePermission = permissions.some(permission => permission.name === 'User Wise Sale');
    const mistakePermission = permissions.some(permission => permission.name === 'View Stock Mistake');
    const remarksPermission = permissions.some(permission => permission.name === 'View Short Punch');
    const popcornPermission = permissions.some(permission => permission.name === 'View Popcorn Yield');
    const monthlyPermission = permissions.some(permission => permission.name === 'Monthly Summary');
    const controlPermission = permissions.some(permission => permission.name === 'Control Summary');

    const { id } = useParams();
    const navigate = useNavigate();
    const { setPageTitle, setIsLoading, showToast } = useStateContext();

    const displayError = (error) => {
        let errArr = [];
        errArr = Object.keys(error).map((key) => {
            return error[key].join(",");
        });
        errArr.forEach((column) => showToast(column, "error"));
    };

    const getInitialData = () => {
        // axiosClient.get()
        //     .then((response) => {

        //     })
        //     .catch((error) => {
        //         setIsLoading(false);
        //         displayError(error.response.data.errors);
        //     })
    }

    useEffect(() => {
        getInitialData();
        setPageTitle("Store Report");
    }, []);

    return (
        <Row>
            <Col md="6" lg="3">
                <MiniCard
                    icons={<FaBoxOpen style={{ fontSize: "30px" }} />}
                    name="Inventory"
                    href={`/app/store/report/inventory`}
                />
            </Col>
            {/* {reconSummaryPermission && (
                <Col md="6" lg="3">
                    <MiniCard
                        id={id}
                        icons={<AiFillReconciliation style={{ fontSize: "30px" }} />}
                        name="Recon. Summary"
                        href={`/app/store/report/recon_summary`}
                    />
                </Col>
            )}
            {daySummaryPermission && (
                <Col md="6" lg="3">
                    <MiniCard
                        icons={<FaCalendarDay style={{ fontSize: "30px" }} />}
                        id={id}
                        name="Day Summary"
                        href={`/app/store/report/day_summary`}
                    />
                </Col>
            )}
            {userWisePermission && (
                <Col md="6" lg="3">
                    <MiniCard
                        icons={<FaUser style={{ fontSize: "30px" }} />}
                        id={id}
                        name="User-wise Sales"
                        href={`/app/store/report/user_sales`}
                    />
                </Col>
            )}
            {mistakePermission && (
                <Col md="6" lg="3">
                    <MiniCard
                        icons={<MdReportProblem style={{ fontSize: "30px" }} />}
                        id={id}
                        name="Mistake"
                        href={`/app/store/report/mistake_summary`}
                    />
                </Col>
            )}
            {remarksPermission && (
                <Col md="6" lg="3">
                    <MiniCard
                        icons={<MdOutlineChatBubble style={{ fontSize: "30px" }} />}
                        id={id}
                        name="Remarks"
                        href={`/app/store/report/short_punch_summary`}
                    />
                </Col>
            )}
            {popcornPermission && (
                <Col md="6" lg="3">
                    <MiniCard
                        icons={<LuPopcorn style={{ fontSize: "30px" }} />}
                        id={id}
                        name="Popcorn Yield"
                        href={`/app/store/report/popcorn_yield_summary`}
                    />
                </Col>
            )}
            {monthlyPermission && (
                <Col md="6" lg="3">
                    <MiniCard
                        icons={<MdCalendarMonth style={{ fontSize: "30px" }} />}
                        id={id}
                        name="Monthly Summary"
                        href={`/app/store/report/monthly_summary`}
                    />
                </Col>
            )}
            {controlPermission && (
                <Col md="6" lg="3">
                    <MiniCard
                        icons={<AiFillControl style={{ fontSize: "30px" }} />}
                        id={id}
                        name="Control Summary"
                        href={`/app/store/report/control_summary`}
                    />
                </Col>
            )} */}
        </Row>
    );
};

export default StoreReportDashboard;
