import * as Yup from 'yup';

const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'];
const MAX_FILE_SIZE = 255 * 1024; // 255KB

const fileValidationSchema = Yup.mixed()
    .required('This field is required')
    .test(
        'fileSize',
        'The file must not be greater than 255 kilobytes.',
        value => value && value.size <= MAX_FILE_SIZE
    )
    .test(
        'fileFormat',
        'Unsupported file format',
        value => value && SUPPORTED_FORMATS.includes(value.type)
    );

const employeeDocsStoreSchema = Yup.object({
    passport_photo: fileValidationSchema,
    highest_education: Yup.string().required('Highest Education is required'),
    completed_year: Yup.date().required('Completed Year is required'),
    education_file_path: fileValidationSchema,
    bank_name: Yup.string().required('Bank Name is required'),
    account_number: Yup.string().required('Account Number is required'),
    lincense_number: Yup.string().required('License Number is required'),
    expiry_date: Yup.date().required('Expiry Date is required'),
    driving_lincense_file_path: fileValidationSchema,
    vehicle_number: Yup.string().required('Vehicle Number is required'),
    vehicle_type: Yup.string().required('Vehicle Type is required'),
    citizenship_number: Yup.string().required('Citizenship Number is required'),
    citizenship_issue_date: Yup.date().required('Citizenship Issue Date is required'),
    citizenship_file_path: fileValidationSchema,
    pan_number: Yup.string().required('PAN Number is required'),
    pan_file_path: fileValidationSchema,
    passport_number: Yup.string().required('Passport Number is required'),
    passport_issue_date: Yup.date().required('Passport Issue Date is required'),
    passport_file_path: fileValidationSchema,
    police_clearance_file_path: fileValidationSchema,
    term_and_conditions: Yup.boolean()
        .oneOf([true], 'You must accept the terms and conditions')
        .required('Terms and Conditions is required'),
});

export default employeeDocsStoreSchema;
