import { useFormik } from "formik";
import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Offcanvas,
  Row,
} from "react-bootstrap";
import Select from 'react-select';
import { useNavigate } from "react-router-dom";
import { useStateContext } from "../../../context/ContextProvider";
import itemChallanDetailService from "../../../services/item-challan-detail.service";
import itemChallanService from "../../../services/item-challan.service";
import warehouseService from "../../../services/warehouse.service";
import challanDetailOutStoreSchema from "./item-challan-detail-out-store-schema";
import challanDetailOutUpdateSchema from "./item-challan-detail-out-update-schema";
import rawMaterialService from "../../../services/raw-material.service";

const AddItemChallanDetailOut = ({ setShowAdd, showAdd, id, transaction_id, challan_id, challan_type, sout_date, sout_type, to_id, to_name, shift }) => {

  const navigate = useNavigate();

  const currentURL = window.location.href;
  const [challanType, setChallanType] = useState('');

  const setChallanTpyeFromURL = () => {
    if (currentURL.includes("/app/transfer/out/edit/:id")) {
      setChallanType('out');
    }
    if (currentURL.includes("/app/transfer/in/edit/:id")) {
      setChallanType('in');
    }
  }

  //To fetch the details
  const [warehouse, setWarehouse] = useState();
  const handleWarehouseChange = (event) => {
    setWarehouse(event.target.value);
  };
  const [item, setItem] = useState();
  const [selectedItem, setSelectedItem] = useState("");

  // Inside the handleItemChange function
  const handleItemChange = (selectedOption) => {
    setItem(selectedOption.value);
    setSelectedItem(selectedOption.value);
  };

  const {
    setIsLoading,
    setPageTitle,
    showToast,
    setRefereshData,
    refereshData,
  } = useStateContext();
  const data = {
    item_id: "",
    quantity: "",
    created_by: "",
    updated_by: "",
  };


  /**
   * The function `displayError` takes an error object as input and displays each error message as a
   * toast notification.
   * @param error - The `error` parameter is an object that contains error messages for different
   * columns. Each key in the `error` object represents a column, and the corresponding value is an
   * array of error messages for that column.
   */
  const displayError = (error) => {
    let errArr = [];
    errArr = Object.keys(error).map((key) => {
      return error[key].join(",");
    });
    errArr.map((column) => showToast(column, "error"));
  };


  const [challanData, setChallanData] = useState();
  const getChallanData = () => {
    itemChallanService
      .singleView(challan_id)
      .then((response) => {
        setChallanData(response.data.data);
      }).catch((error) => {
        displayError(error.response.data.errors);
      })
  }

  const store = (values) => {
    if (parseInt(values.quantity) > parseInt(quantityLimit)) {
      showToast("The quantity is more than the quantity available.", "error");
    }
    else {
      setIsLoading(true);
      let updatedValues = {
        ...values,
        warehouse_id: warehouse,
        date: challanData.challan_date,
        type: challanData.challan_type,
        to_id: challanData.to_id,
        to_name: challanData.to_name,
        challan_id: challan_id,
        shift: shift,
        item_status: "fresh",
        // Remove batch_id and update as needed
        item_id: item
      }
      itemChallanDetailService
        .storeChallanOut(updatedValues)
        .then((response) => {
          setIsLoading(false);
          setShowAdd(false);
          setRefereshData(true);
          showToast(`Item added.`, "success");
        })
        .catch((error) => {
          setIsLoading(false);
          displayError(error.response.data.errors);
        });
    }
  };

  const submitForm = (values) => {
    store(values);
  };

  const setInitialData = () => {
    formik.setValues(data);
  };

  const [itemList, setItemList] = useState([]);

  const getItemList = () => {
    rawMaterialService.lists()
      .then((response) => {
        setItemList(response.data.data);
      })
      .catch((error) => {
        displayError(error.response.data.errors);
      })
  }
  const [warehouseList, setWarehouseList] = useState();

  const getWarehouseList = () => {
    warehouseService.lists()
      .then((response) => {
        setWarehouseList(response.data.data);
        setWarehouse(response.data.data[0].id);
      })
      .catch((error) => {
        displayError(error.response.data.errors);
      })
  }

  //function to get the item remaining quantity
  const [itemRemaining, setItemRemaining] = useState();

  const itemAvailable = () => {
    setIsLoading(true);
    if (warehouse && item) {
      let values = {
        warehouse_id: warehouse,
        item_id: item
      };
      itemChallanDetailService.itemList(values)
        .then((response) => {
          setItemRemaining(response.data.data);
          formik.setFieldValue('item_id', response.data.data[0].quantity_difference);
          setIsLoading(false);
        })
        .catch((error) => {
          displayError(error.response.data.errors);
          setIsLoading(false);
        })
    } else {
      showToast("Please select both warehouse and item", "error");
      setIsLoading(false);
    }
  }

  //to set the quantity limit
  const [quantityLimit, setQuantityLimit] = useState();

  //to get the item category and related functions
  const [categoryList, setCategoryList] = useState([]);

  //get items related to the particular category
  const [itemCategory, setItemCategory] = useState();

  useEffect(() => {
    getChallanData();
    getItemList();
    getWarehouseList();
    setInitialData();
    setChallanTpyeFromURL();
  }, [setPageTitle, challan_type]);

  const formik = useFormik({
    initialValues: data,
    validationSchema:
      id !== undefined ? challanDetailOutUpdateSchema : challanDetailOutStoreSchema,
    onSubmit: submitForm,
  });

  const itemOptions = itemList.map((item) => ({
    value: item.id,
    label: item.text
  }))

  return (
    <Container>
      <Offcanvas
        show={showAdd} // Control Offcanvas visibility
        onHide={() => setShowAdd(false)} // Close Offcanvas when clicking the backdrop
        placement="end"
        tabindex="-1"
        className="overflow-auto"
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
      >
        <Offcanvas.Header class="offcanvas-header">
          <Offcanvas.Title id="offcanvasRightLabel">
            <h4 className="card-title">
              Add Item Out Detail
            </h4>
          </Offcanvas.Title>
          <button
            type="button"
            class="btn-close"
            onClick={() => {
              setShowAdd(false);
            }}
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <h5>Select the Warehouse and Item</h5>
              <hr className="my-3" />
              <Col md="12">
                <Form.Label htmlFor="validationCustom01" className="h6">
                  Warehouse
                </Form.Label>
                <Form.Select
                  type="select"
                  value={warehouse}
                  onChange={handleWarehouseChange}
                >
                  <option defaultValue="">Select the Warehouse</option>
                  {warehouseList ? (
                    <>
                      {
                        warehouseList.map((list) => (
                          <option key={list.id} value={list.id}>{list.text}</option>
                        ))
                      }
                    </>
                  ) :
                    null
                  }
                </Form.Select>
              </Col>
              <Col md="12">
                <Form.Label htmlFor="validationCustom01" className="h6">
                  Item Name
                </Form.Label>
                <Select
                  options={itemOptions}
                  value={itemOptions.find(option => option.value === item)}
                  onChange={handleItemChange}
                  onBlur={formik.handleBlur}
                  className={`form-control p-0`}
                />
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button className="btn btn-primary-outline text-align-center m-2" onClick={() => { itemAvailable() }}>Fetch Details</Button>
                </div>
              </Col>
              {
                quantityLimit !== "" || quantityLimit !== null
                  ?
                  <>
                    <h5>Item Information</h5>
                    <hr className="my-3" />
                    <Col md="12">
                      <Form.Label htmlFor="validationCustom01" className="h6">
                        Available
                      </Form.Label>
                      <Form.Select
                        type="select"
                        name="item_id"
                        value={formik.values.item_id}
                        onChange={(event) => {
                          formik.handleChange(event);
                          setQuantityLimit(event.target.value);
                        }}
                        onBlur={formik.handleBlur}
                        isInvalid={formik.touched.item_id && !!formik.errors.item_id}
                      >
                        <option defaultValue="">Select the Item</option>
                        {itemRemaining ? (
                          <>
                            {
                              itemRemaining.map((list) => (
                                <option key={list.id} value={list.quantity_difference} >Remaining: {list.quantity_difference}</option>
                              ))
                            }
                          </>
                        ) :
                          null
                        }
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.item_id}
                      </Form.Control.Feedback>
                    </Col>
                    <Col md="12">
                      <Form.Label htmlFor="validationCustom01" className="h6">
                        Quantity
                      </Form.Label>
                      <Form.Control
                        type="number"
                        name="quantity"
                        value={formik.values.quantity}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        isInvalid={formik.touched.quantity && !!formik.errors.quantity}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.quantity}
                      </Form.Control.Feedback>
                    </Col>
                  </>
                  :
                  <></>
              }
            </Row>
          </Form>
        </Offcanvas.Body>
        <Button className='m-3 mb-0' variant="outline-secondary" type="submit" onClick={formik.handleSubmit}>
          {id !== undefined ? "Update" : "Save"}
        </Button>
        <Button className='m-3 mb-0' variant="outline-warning" onClick={setInitialData}>
          Reset Data
        </Button>
        <Button
          className='m-3 mb-0' variant="outline-secondary"
          onClick={() => {
            setShowAdd(false);
          }}
        >
          Cancel
        </Button>
      </Offcanvas>
    </Container>
  );
};

export default AddItemChallanDetailOut;
