import React from 'react'
import {Route, Routes} from 'react-router-dom'

// auth
import ConfirmMail from '../views/auth/confirm-mail'
import LockScreen from '../views/auth/lock-screen'
import Recoverpw from '../views/auth/recoverpw'
import SignIn from '../views/auth/sign-in'
import SignUp from '../views/auth/sign-up'
// errors
import Error404 from '../views/errors/error404'
import Error500 from '../views/errors/error500'
import Maintenance from '../views/errors/maintenance'

const SimpleRouter = () => {
    return (
        <>
        <Routes>
            <Route index element={<SignIn />} />
            <Route path="sign-in" element={<SignIn />} />
            <Route path="sign-up" element={<SignUp />} />
            <Route path="recoverpw" element={<Recoverpw />} />
            <Route path="lock-screen" element={<LockScreen />} />
            <Route path="confirm-mail" element={<ConfirmMail />} />
            <Route path="recoverpw" element={<Recoverpw />} />
            <Route path="error404" element={ <Error404 />} />
            <Route path="error500" element={ <Error500 />} />
            <Route path="maintenance" element={ <Maintenance />} />
            <Route path="*" element={ <Error404 />} />          
        </Routes>
            { /*
            <Routes>
                <Route exact path="/auth/confirm-mail" element={<ConfirmMail />} />
                <Route exact path="/auth/lock-screen" element={<LockScreen />} />
                <Route exact path="/auth/recoverpw" element={<Recoverpw />} />
                <Route exact path="/auth/sign-in" element={<SignIn />} />
                <Route exact path="/auth/sign-up" element={<SignUp />} />
                <Route exact path="/errors/error404" element={<Error404 />} />  
                <Route exact path="/errors/error500" element={<Error500 />} />
                <Route path="/errors/maintenance" element={<Maintenance />} />
                <Route exact path="/" element={<SignIn />} />
                <Route path="hurrey" element={<SignIn />} />
    </Routes> */}
        </>    
    )
}

export default SimpleRouter
