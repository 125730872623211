import axios from "axios";

export default axios.create({
  baseURL: "http://edusysapiv3.patancollege.edu.np/api",
  mode: 'no-cors',
  headers: {
    "Content-type": "application/json"
  }
});

