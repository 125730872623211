import React, { useState } from 'react';
import { Form, Button, Card, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { useStateContext } from '../../context/ContextProvider';

const TicketStatus = () => {
    const [ticketNumber, setTicketNumber] = useState('');
    const [ticketData, setTicketData] = useState(null);
    const [error, setError] = useState(null);

    const { showToast, setIsLoading } = useStateContext();

    const displayError = (error) => {
        let errArr = [];
        errArr = Object.keys(error).map((key) => {
            return error[key].join(",");
        });
        errArr.forEach((column) => showToast(column, "error"));
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();
        setError(null);

        axios.post(`http://localhost:8000/api/employees/tickets/approval/${ticketNumber}`)
            .then(response => {
                setTicketData(response.data.data[0]);
            })
            .catch((error) => {
                showToast("Ticket not found", "error");
            });
    };

    return (
        <Card className="p-4">
            <h2 className="mb-4">Ticket Form</h2>
            <Form onSubmit={handleFormSubmit}>
                <Row className="mb-3">
                    <Form.Group as={Col} controlId="ticketNumber">
                        <Form.Label>Ticket Number</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter ticket number"
                            value={ticketNumber}
                            onChange={(e) => setTicketNumber(e.target.value)}
                            required
                        />
                    </Form.Group>
                    <Col className="d-flex align-items-end">
                        <Button variant="primary" type="submit">
                            Submit
                        </Button>
                    </Col>
                </Row>
            </Form>
            {error && <p className="text-danger mb-0">{error}</p>}
            {ticketData && (
                <Row className='mb-3'>
                    <Col md="6">
                        <div className="mt-2">
                            <h6 className="mb-1">Ticker Number:</h6>
                            <p>{ticketData.ticket_number}</p>
                        </div>
                    </Col>
                    <Col md="6">
                        <div className="mt-2">
                            <h6 className="mb-1">Remarks:</h6>
                            <p>{ticketData.remarks}</p>
                        </div>
                    </Col>
                    <Col md="6">
                        <div className="mt-2">
                            <h6 className="mb-1">Ticket Status:</h6>
                            <p>{ticketData.status}</p>
                        </div>
                    </Col>
                    <Col md="6">
                        <div className="mt-2">
                            <h6 className="mb-1">Ticket Data:</h6>
                            <p>{ticketData.ticket_date}</p>
                        </div>
                    </Col>
                </Row>
            )}
        </Card>
    );
};

export default TicketStatus;
